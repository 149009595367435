import { OverlayTrigger, Popover } from "react-bootstrap";
import { useAppDispatch } from "hooks/hooks.redux";

import StatusIndicator from "components/StatusIndicator";

import { downloadHistoryWorksheetCollaboratorsAction } from "model/actions/action.collaborators";
import ICollaboratorUploadHistory from "interfaces/interface.collaborator-upload-history";

import ActionsIcon from "assets/img/actions-icon.svg";
import ActionsIconGray from "assets/img/actions-icon-gray.svg";

import { UtilitiesDate } from "utils/utilities.date";
import DropdownTable from "layout/DropdownTable";

export default function RowUploadHistory({
  file,
}: {
  file: ICollaboratorUploadHistory;
}) {
  const dispatch = useAppDispatch();

  const [date] = UtilitiesDate.formatDateAndTimeToBrazilianStandard(file.rhhistup_datahora)

  function download() {
    dispatch(downloadHistoryWorksheetCollaboratorsAction(file.rhhistup_id));
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <span className="table-text">
          {file.rhhistup_mensagem}
        </span>
      </Popover.Body>
    </Popover>
  );

  return (
    <tr>
      <td className="table-text">{file.rhhistup_nomearquivo}</td>
      <td className="table-text">Desligamento</td>
      <td className="table-text">{date}</td>
      <td>
        <div className="d-flex flex-row align-items-center gap-3">
          <StatusIndicator
            variant={file.rhhistup_status === 0 ? "red" : "green"}
          />
          <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
            <button
              type="button"
              className="btn border-0 background-transparent table-text"
            >
              {file.rhhistup_status === 0 ? "Erro" : "Sucesso"}
            </button>
          </OverlayTrigger>
        </div>
      </td>
      <td>
        <div className="d-flex flex-row justify-content-between align-items-center">
          {file.rhhistup_status === 0 ?
            <img src={ActionsIconGray} alt="" />
            :
            <DropdownTable
              trigger={<img src={ActionsIcon} alt="" />}
              items={[
                { label: "Download", action: download },
              ]} />
          }
        </div>
      </td>
    </tr>
  );
}
