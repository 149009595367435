import { useCallback } from "react";

export const useDebounce = () => {
  let timer: any = null;

  const debounce = useCallback(function (
    action: (event: any) => void,
    event: any
  ) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      action(event);
    }, 1000);
  },
  []);

  return { debounce };
};
