import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import Body from "layout/DefaultBody";
import TableContainer from "layout/TableContainer";
import HeaderConciliationResume from "./components/CurrentConciliation/HeaderConciliationResume";
import Button from "components/Button";
import TicketDataPanel from "./components/CurrentConciliation/TicketDataPanel";
import ContestationPanel from "./components/CurrentConciliation/ContestationPanel";
import TableInstallments from "./components/Table/TableInstallments";
import ConciliationDataPanel from "./components/CurrentConciliation/ConciliationDataPanel";

import UtilitiesConciliations from "utils/utilities.conciliations";
import {
  searchConciliationPortionAction,
  searchResumeConciliationAction,
} from "model/actions/action.conciliations";
import { exportInstallmentsWorksheetConciliationAction } from "model/actions/action.conciliations-submit";

import "./style.css";

export default function ConciliationResume() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { portion, currentPage, lastPage, total } = useAppSelector(
    (state) => state.conciliationsReducer
  );
  const { isLoading } = useAppSelector((state) => state.generalReducer);

  const { resumeConciliation } = useAppSelector(
    (state) => state.conciliationsReducer
  );

  const day = String(resumeConciliation.dados_conciliacao.conci_mes)
  const date = `${day.length == 1 ? day.padStart(2,"0") : day}/${resumeConciliation.dados_conciliacao.conci_ano}`;

  const conciliationStatus = resumeConciliation.dados_conciliacao.status.valor
  const disableActions = conciliationStatus === 2 || conciliationStatus === 3 || conciliationStatus === 4

  useEffect(() => {
    if (!UtilitiesConciliations.validateURLParameters())
      return navigate("/conciliacoes");

    dispatch(searchResumeConciliationAction());
    dispatch(searchConciliationPortionAction());
  }, []);

  function downloadWorkSheet() {
    dispatch(exportInstallmentsWorksheetConciliationAction());
  }

  function paginate(page: number) {
    dispatch(searchConciliationPortionAction(page));
  }

  return (
    <Body
      titlePage="Conciliação"
      description="Consulte os vencimentos, exporte e gere o boleto tudo por aqui."
    >
      <div className="cotainer-fluid d-flex flex-column gap-4">
        <HeaderConciliationResume
          isLoading={isLoading}
          data={resumeConciliation.dados_conciliacao}
        />

        {!resumeConciliation?.dados_boleto.data_criacao ? (
          <ConciliationDataPanel
            data={resumeConciliation.dados_conciliacao}
            disableActions={disableActions}
            isLoading={isLoading}
          />
        ) : (
          <TicketDataPanel
            data={resumeConciliation.dados_boleto}
            disableActions={disableActions}
            isLoading={isLoading}
          />
        )}

        <div className="row border-top pt-4">
          <div className="col-md-9 d-flex align-items-center">
            <span className="text-roboto-fs14-lh16-fw700-blue700">
              Valor a ser repassado desta empresa no período de{" "}
              <span className="text-roboto-fs14-lh16-fw700-orange500">
                {date}
              </span>
            </span>
          </div>
          <div className="col-md-3 gy-3">
            <Button
              variant="pink"
              className="w-100 text-montserrat-fs16-lh19-fw400"
              onClick={downloadWorkSheet}
            >
              Baixar Arquivo
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TableContainer
            isLoading={isLoading}
            pagination={{
              currentPage,
              paginate,
              lastPage,
              total,
            }}
          >
            <TableInstallments
              option={resumeConciliation.dados_conciliacao.tipo.valor}
              data={portion}
            />
          </TableContainer>
        </div>
      </div>
      <ContestationPanel />
    </Body>
  );
}
