import styled from "styled-components";

type SpinnerProps = {
  size?: number;
};

export const Spinner = styled.div<SpinnerProps>`
  width: ${(props) => props.size || "7rem"};
  height: ${(props) => props.size || "7rem"};
`;
