import ISelectOptions from "interfaces/interface.select-options";
import {
  CHANGE_EMPLOYEE_STATUS,
  SUBMIT_COLLABOARATOR,
  SUBMIT_ENDORSEMENT,
  SUBMIT_SHUTDOWN_LIST,
  SUBMIT_SHUTDOWN_WORKSHEET,
  SUBMIT_UPLOAD_WORKSHEET,
} from "model/action-types/type.collaborators-submit";

export function changeEmployeeStatusAction(status: 1 | 2 | 3 | 4, id: number) {
  return { type: CHANGE_EMPLOYEE_STATUS, status, id };
}

export function uploadCollaboratorsUpdateWorksheetAction(files: File[]) {
  return {
    type: SUBMIT_UPLOAD_WORKSHEET,
    files,
  };
}

export function submitEndorsementAction(amount: string) {
  return { type: SUBMIT_ENDORSEMENT, amount };
}

export function submitShutdownListAction(list: ISelectOptions[]) {
  return { type: SUBMIT_SHUTDOWN_LIST, list };
}

export function submitShutdownWorksheetAction(worksheet: File) {
  return { type: SUBMIT_SHUTDOWN_WORKSHEET, worksheet };
}

export function submitCollaboratorAction() {
  return { type: SUBMIT_COLLABOARATOR };
}
