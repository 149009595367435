import IDiv from "interfaces/html/interface.div";

export default function UploadPresentation({ ...rest }: IDiv) {
  return (
    <div className="w-100 d-flex align-items-center justify-content-center gap-2 flex-wrap" {...rest}>
      <span className="text-center text-roboto-fs14-lh16-fw400-blue700">
        Solte o arquivo aqui ou
      </span>
      <span className="upload-span-select-file text-center text-roboto-fs12-lh14-fw400">
        Selecione o arquivo
      </span>
    </div>
  );
}