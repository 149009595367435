import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import { CheckboxFunction } from "./CheckboxFunction";
import { InputCompanies } from "./FormCompaniesAdministrators";
import HorizontalSeparator from "layout/HorizontalSeparator/styled";
import StatusIndicator from "components/StatusIndicator";
import Label from "components/Label";
import Input from "components/Input";
import BodyPanel from "components/BodyPanel";
import Button from "components/Button";

import { AdministratorsUtilities } from "utils/utilities.administrators";
import ISelectCompanyOptions from "interfaces/interface.select-company-options";
import IAdministrators from "interfaces/http-response/interface.administrators";
import { submitAdministratorAction } from "model/actions/action.administrators-submit";
import { closeDetailsPanelAction } from "model/actions/action.administrators-details-panel";

import "./style.css";

export default function AdministratorPanel() {
  const dispatch = useAppDispatch();
  const { detailsPanel, listOfFunctions } = useAppSelector(
    (state) => state.administratorsReducer
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState<IAdministrators["eusu_status"]>(1);
  const [companies, setCompanies] = useState<Array<ISelectCompanyOptions>>([]);
  const [functions, setFunctions] = useState<any[]>([]);

  useEffect(() => {
    if (detailsPanel.admin) {
      setName(detailsPanel.admin.eusu_nome as string);
      setEmail(detailsPanel.admin.eusu_email as string);
      setStatus(
        detailsPanel.admin.status.eusu_status as IAdministrators["eusu_status"]
      );
      setCompanies(
        detailsPanel.admin?.usuarioempresa
          ? AdministratorsUtilities.getCompaniesAsSelectionObject(detailsPanel.admin.usuarioempresa)
          : []
      );
      setFunctions(getIdFunctions(detailsPanel.admin.funcoes));
    }
  }, [detailsPanel.admin]);

  function changeSelectedFunctionsList(value: any) {
    if (functions.includes(value))
      setFunctions(functions.filter((item) => item !== value))
    else setFunctions([...functions, value]);

  }

  function getIdFunctions(functions: IAdministrators["funcoes"]) {
    let newArray: number[] = [];
    functions.map((item) => {
      newArray.push(item.status);
    });

    return newArray;
  }

  function onSubmit(event: any) {
    event.preventDefault();
    const adminId = detailsPanel.admin?.eusu_id;
    dispatch(
      submitAdministratorAction(
        {
          name,
          email,
          password,
          status,
          functions,
          companies,
          adminId
        })
    );
    onClose()
  }

  function onClose() {
    setName("");
    setEmail("");
    setPassword("");
    setStatus(1);
    setCompanies([]);
    setFunctions([]);
    dispatch(closeDetailsPanelAction());
  }

  return (
    <BodyPanel
      show={detailsPanel.show}
      title="Detalhes do Administrador"
      onClose={onClose}
    >
      <div className="container-fluid">
        <form onSubmit={onSubmit}>
          <div className="row administrator-panel-container-input">
            <div className="col-md-4 d-flex flex-column">
              <Label className="text-roboto-fs20-lh29-fw700-blue600">
                Nome Completo
              </Label>
              <Input
                value={name}
                onChange={(value) => setName(value)}
                className="administrator-panel-input text-roboto-fs20-lh29-fw400-blue600"
              />
              <HorizontalSeparator/>
            </div>
            <div className="col-md-4 d-flex flex-column">
              <Label className="text-roboto-fs20-lh29-fw700-blue600">
                E-mail
              </Label>
              <Input
                value={email}
                type="email"
                onChange={(value) => setEmail(value)}
                className="administrator-panel-input text-roboto-fs20-lh29-fw400-blue600"
              />
              <HorizontalSeparator/>
            </div>
            <div className="col-md-4 d-flex flex-column">
              <Label className="text-roboto-fs20-lh29-fw700-blue600">
                Senha
              </Label>
              <Input
                value={password}
                type="password"
                onChange={(value) => setPassword(value)}
                className="administrator-panel-input text-roboto-fs20-lh29-fw400-blue600"
              />
              <HorizontalSeparator/>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 d-flex flex-row flex-wrap gap-4">
              <div>
                <Label className="text-roboto-fs20-lh29-fw700-blue600 mt-3">
                  Funções
                </Label>
                <div className="administrator-panel-functions-container">
                  {listOfFunctions?.map((item, key) => (
                    <CheckboxFunction
                      key={key}
                      administratorFunction={item.rhfun_titulo}
                      descriptionFunction={item.rhfun_descricao}
                      selectedFunctions={functions}
                      functionId={item.rhfun_id}
                      onChange={changeSelectedFunctionsList}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-row flex-wrap gap-4">
              <div className="w-100">
                <div className="w-100">
                  <Label className="text-roboto-fs20-lh29-fw700-blue600 mt-3">
                    Status do administrador
                  </Label>
                  <div className="administrator-panel-configurenotify-container d-flex flex-column">
                    <Button
                      type="button"
                      variant="blue"
                      isActive={status === 1}
                      className="text-roboto-fs14-lh16-fw400 d-flex gap-1 align-items-center"
                      onClick={() => setStatus(1)}
                    >
                      <StatusIndicator variant="green" />
                      Ativo
                    </Button>
                    <Button
                      type="button"
                      variant="blue"
                      isActive={status === 2}
                      className="text-roboto-fs14-lh16-fw400 d-flex gap-1 align-items-center"
                      onClick={() => setStatus(2)}
                    >
                      <StatusIndicator variant="red" />
                      Inativo
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <InputCompanies
              value={companies}
              onChange={setCompanies}
            />
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <HorizontalSeparator/>
              <Button variant="blue" className="mt-3" type="submit">
                Salvar dados
              </Button>
            </div>
          </div>
        </form>
      </div>
    </BodyPanel>
  );
}