class CollaboratorHelper {
  static getTableOption() {
    return Number(sessionStorage.getItem("@table-option-colaborator") ?? 0);
  }

  static setTableOption(tableOption: number) {
    sessionStorage.setItem("@table-option-colaborator", String(tableOption));
  }
}

export default CollaboratorHelper;
