import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import Body from "layout/DefaultBody";
import AssetView from "./components/AssetView";
import Indicator from "./components/Indicator";
import Button from "components/Button";
import Input from "components/Input";
import CollapseFaq from "./components/CollapseFaq";

import { UserUtilities } from "utils/utilities.user";
import { searchDashboardAction } from "model/actions/action.dashboard";
import { submitFaqAction } from "model/actions/action.dashboard-submit";

import IconRightArrow from "assets/img/right-arrow.svg";

import "./style.css";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const { indicators, faq, pendencies } = useAppSelector(
    (state) => state.dashboardReducer
  );
  const { isLoading } = useAppSelector((state) => state.generalReducer);

  const [faqMessage, setFaqMessage] = useState("");

  const activeCollaborators = new Intl.NumberFormat("pt-BR").format(
    indicators.active_collaborators
  );
  const activeRequests = new Intl.NumberFormat("pt-BR").format(
    indicators.active_requests
  );

  const { eusu_nome } = UserUtilities.getUserAttribute();

  useEffect(() => {
    dispatch(searchDashboardAction());
  }, []);

  function submitFaq() {
    dispatch(submitFaqAction(faqMessage));
    setFaqMessage("");
  }

  return (
    <Body
      titlePage={`Olá, ${eusu_nome}`}
      description="Bem vindo ao portal do RH."
    >
      <Row className="gy-5">
        <Col md={8}>
          <div className="dashboard-banner-effect">
            <div className="dashboard-banner-content">
              <h2 className="text-roboto-fs30-lh35-fw700-white">
                Esse é o Portal do RH - InMano
              </h2>
              <span className="text-roboto-fs20-lh23-fw400-white">
                Gerencie os beneficíos corporativos em um só lugar.
              </span>
              <Button
                variant="blue"
                className="d-flex align-items-center gap-3 mt-4 text-montserrat-fs16-lh20-fw400-white"
              >
                Tutorial
                <img
                  src={IconRightArrow}
                  alt="Flecha branca apontada para o lado direito"
                />
              </Button>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <AssetView
            isLoading={isLoading}
            activeCollaborators={activeCollaborators}
            activeContracts={activeRequests}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={3} className="d-flex flex-column">
          <h2 className="text-roboto-fs30-lh30-fw400-blue700">Pendências</h2>
          <span className="text-roboto-fs12-lh14-fw400-blue700">
            Demandas pendentes no portal
          </span>
          <Indicator isLoading={isLoading} data={pendencies} />
        </Col>
        <Col md={9} className="d-flex flex-column">
          <h2 className="text-roboto-fs30-lh30-fw400-blue700">Dúvidas</h2>
          <span className="text-roboto-fs12-lh14-fw400-blue700">
            Perguntas mais comuns por outros gestores
          </span>
          <div className="mt-4 gap-3 d-flex flex-column align-items-center">
            <CollapseFaq data={faq} isLoading={isLoading} />
          </div>
          <hr />
          <div className="d-flex flex-row flex-wrap gap-3">
            <Input
              placeholder="Escreva aqui sua dúvida"
              value={faqMessage}
              onChange={setFaqMessage}
              className="dashboard-input-faq flex-grow-1"
            />
            <Button
              className="dashboard-button-submit-faq d-flex align-items-center gap-3 text-roboto-fs16-lh20-fw400-white"
              variant="blue"
              disabled={faqMessage === ""}
              onClick={submitFaq}
            >
              Enviar
              <img
                src={IconRightArrow}
                alt="Flecha branca apontada para o lado direito"
              />
            </Button>
          </div>
        </Col>
      </Row>
    </Body>
  );
}
