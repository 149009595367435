import {
  REQUEST_FILTER_BY_STATUS,
  SEARCH_REQUESTS,
  SET_REQUESTS,
  REQUEST_FILTER_BY_COMPANY,
  CHANGE_LIST_OF_SELECTED_PROPOSALS,
  CLEAR_LIST_OF_SELECTED_PROPOSALS,
  CHANGE_EXPORT_ALL_REQUESTS,
  OPEN_REQUEST_STATUS,
  CLOSE_REQUEST_STATUS,
  MOVE_ALL_REQUESTS_TO_SELECTED_LIST,
  REMOVE_ALL_REQUESTS_FROM_THE_SELECTED_LIST
} from "model/action-types/type.requests";

export function searchRequestsAction(page?: number, empId?: number) {
  return { type: SEARCH_REQUESTS, page, empId };
}

export function setRequestsAction(data: any) {
  return { type: SET_REQUESTS, data };
}

export function requestFilterByStatusAction(value: number | null) {
  return { type: REQUEST_FILTER_BY_STATUS, value };
}

export function requestFilterByCompanyAction(empId: number | null) {
  return { type: REQUEST_FILTER_BY_COMPANY, empId };
}

export function changelistOfSelectedProposalsRequestsAction(requestId: number) {
  return { type: CHANGE_LIST_OF_SELECTED_PROPOSALS, requestId };
}

export function clearListOfSelectedProposalsAction(clearAll?: boolean) {
  return { type: CLEAR_LIST_OF_SELECTED_PROPOSALS, clearAll };
}


export function openRequestStatusAction(idRequest: number) {
  return { type: OPEN_REQUEST_STATUS, idRequest };
}

export function closeRequestStatusAction() {
  return { type: CLOSE_REQUEST_STATUS };
}

// export function changeExportAllRequestsAction() {
//   return { type: CHANGE_EXPORT_ALL_REQUESTS };
// }

export function moveAllRequestsToSelectedListAction() {
  return {type: MOVE_ALL_REQUESTS_TO_SELECTED_LIST}
}

export function removeAllRequestsFromTheSelectedListAction() {
  return {type: REMOVE_ALL_REQUESTS_FROM_THE_SELECTED_LIST}
}