import styled from "styled-components";

interface ContainerRequestFeedbackProps {
  status: 0 | 1 | 6;
}
export const ContainerRequestStatus = styled.div<ContainerRequestFeedbackProps>`
  width: 60rem;
  max-width: 100%;
  max-height: 90%;
  background-color: ${(props) => props.theme.COLORS.WHITE};
  padding: 4.5rem 4rem;
  border-radius: 3rem;
  border-style: solid;
  border-width: 0.5rem;
  border-color: ${(props) =>
    props.status === 1
      ? props.theme.COLORS.GREEN_300
      : props.status === 6
      ? props.theme.COLORS.RED_300
      : props.theme.COLORS.ORANGE_500};
`;

const buttonDefaultStyle = `
  transition: all 0.5s ease-in-out;
  padding: 1rem 1.2rem;
`;

interface ButtonProps {
  isClicked: boolean;
}
export const ApprovalButton = styled.button<ButtonProps>`
  ${buttonDefaultStyle}
  background-color: ${(props) =>
    props.isClicked === true
      ? props.theme.COLORS.GREEN_300
      : props.theme.COLORS.GRAY_100};
  &:hover {
    background-color: ${(props) => props.theme.COLORS.GREEN_300};
  }
`;

export const RefusedButton = styled.button<ButtonProps>`
  ${buttonDefaultStyle}
  background-color: ${(props) =>
    props.isClicked === true
      ? props.theme.COLORS.RED_300
      : props.theme.COLORS.GRAY_100};
  &:hover {
    background-color: ${(props) => props.theme.COLORS.RED_300};
  }
`;

export const TextAreaContestationPanel = styled.textarea`
  border: 1px solid #dadada;
  border-radius: 3rem;
  width: 100%;
  min-height: 15rem;
  padding: 1.3rem;
`;
