import {
  SEARCH_DASHBOARD,
  SET_DASHBOARD,
  SET_FAQ,
} from "model/action-types/type.dashboard";

export function searchDashboardAction() {
  return { type: SEARCH_DASHBOARD };
}

export function setDashboardAction(data: any) {
  return { type: SET_DASHBOARD, data };
}

export function setFaq(data: any) {
  return { type: SET_FAQ, data };
}
