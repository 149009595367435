import { all, put, select, takeLatest } from "redux-saga/effects";
import Http from "services/httpClient";
import fileDownload from "js-file-download";
import {
  EXPORT_INSTALLMENTS_WORKSHEET_CONCILIATION,
  SUBMIT_CONCILIATION,
  SUBMIT_CONCILIATION_CONSTETATION,
  CONCILIATION_DOWNLOAD_TICKET,
} from "model/action-types/type.conciliations-submit";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";
import {
  searchResumeConciliationAction,
  searchConciliationPortionAction,
  closeConciliationContestationPanelAction,
} from "model/actions/action.conciliations";
import {
  closeModalLoading,
  openModalLoading,
} from "model/actions/action.modal-loading";
import GeneralUtilities from "utils/utilities.general";

function* submitConciliation(): any {
  yield put(openModalLoading());

  const [conciliationId] = GeneralUtilities.getIntegersFromURL();

  const http = new Http();

  const response = yield http.httpClientPost({
    url: "empresa/conciliacao",
    data: {
      conci_id: conciliationId,
    },
  });

  yield put(closeModalLoading());

  if (response.error) {
    return yield put(openFeedbackModalAction(response.message, "error"));
  }

  yield put(
    openFeedbackModalAction("Conciliação confirmada com sucesso!", "success")
  );

  yield put(searchResumeConciliationAction());
  yield put(searchConciliationPortionAction());
}

function* exportInstallmentsWorksheetConciliation(): any {
  yield put(openModalLoading());

  const [conciliationId] = GeneralUtilities.getIntegersFromURL();

  const http = new Http();

  const response = yield http.httpClientGet({
    url: `empresa/conciliacao/parcelas/${conciliationId}?export=true`,
    responseType: "arraybuffer",
  });

  yield put(closeModalLoading());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  const timestamp = new Date().getTime();

  fileDownload(
    response,
    `${timestamp}.xlsx`,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
}

function* submitConciliationContestation(action: any): any {
  yield put(closeConciliationContestationPanelAction());
  yield put(openModalLoading());

  const [conciliationId] = GeneralUtilities.getIntegersFromURL();

  const http = new Http();

  const response = yield http.httpClientPost({
    url: "empresa/solicitacoes/conciliacoes",
    data: {
      solrevcon_mensagem: action.message,
      conci_id: conciliationId,
    },
  });

  yield put(closeModalLoading());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(
    openFeedbackModalAction("Solicitação enviada com sucesso!", "success")
  );

  yield searchResumeConciliationAction();
}

function* downloadTicket(): any {
  yield put(openModalLoading());

  const store = yield select();
  const { resumeConciliation } = store.conciliationsReducer;
  const http = new Http();

  const response = yield http.httpClientGet({
    url: `empresa/faturas/iugu/boleto/${resumeConciliation.dados_boleto.boleto_id}`,
  });

  yield put(closeModalLoading());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  window.open(response.data.return_url_boleto);
}

export default all([
  takeLatest(SUBMIT_CONCILIATION, submitConciliation),
  takeLatest(
    EXPORT_INSTALLMENTS_WORKSHEET_CONCILIATION,
    exportInstallmentsWorksheetConciliation
  ),
  takeLatest(SUBMIT_CONCILIATION_CONSTETATION, submitConciliationContestation),
  takeLatest(CONCILIATION_DOWNLOAD_TICKET, downloadTicket),
]);
