import {
  SET_AUTHENTICATE_RESPONSE,
  GET_AUTHENTICATE_CODE,
  SET_PASSWORD_RECOVERY_STEP,
  SET_USER_ID_RECOVERY_PASSWORD,
} from "model/action-types/type.authenticate";

export function setAuthenticateResponseAction(data: any) {
  return { type: SET_AUTHENTICATE_RESPONSE, data };
}

export function getAuthenticationCodeAction(userName: string) {
  return { type: GET_AUTHENTICATE_CODE, userName };
}

export function setUserIdRecoveryPasswordAction(data: any) {
  return { type: SET_USER_ID_RECOVERY_PASSWORD, data };
}

export function setRecoveryPasswordActionStep(
  step: "get-authentication-code" | "check-code" | "change-password"
) {
  return { type: SET_PASSWORD_RECOVERY_STEP, step };
}
 