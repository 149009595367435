import { Col } from "react-bootstrap";
import Label from "components/Label";
import { DisplayItemContainerItem } from "./styled";

export default function ListDataCollaborator({
  label,
  value,
}: {
  label?: string;
  value?: string;
}) {
  return (
    <DisplayItemContainerItem className="mt-3">
      <Col md={12} className="d-flex flex-column">
        <Label className="text-roboto-fs14-lh29-fw700-blue600">
          {label || ""}
        </Label>
        <span className="text-roboto-fs14-lh29-fw400-blue600">
          {value || ""}
        </span>
      </Col>
    </DisplayItemContainerItem>
  );
}
