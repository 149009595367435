import React from "react";
import { InputSelectProps } from "./interface.select";
import IDiv from "interfaces/html/interface.div";
import ISpan from "interfaces/html/interface.span";

import { SelectContainer, SelectInput, SelectOption } from "./styled";

export default function Select({ ...props }: IDiv) {
  return <div {...props} className="position-relative w-100" />;
}

// function Input({ ...props }: InputSelectProps) {
//   const inputRef = useRef<HTMLInputElement>(null)
//   let ref;

//   if(props.ref === undefined){
//     ref = inputRef;
//   }else{
//     ref = props.ref
//   }

//   return <SelectInput ref={ref} {...props}/>;
// }

const Input = React.forwardRef<HTMLInputElement, InputSelectProps>(
  function Input(props, ref) {
      props = { ...props, ref };
   
      return <SelectInput {...props}/>;
  }
);

function Menu({ ...props }: IDiv) {
  return <SelectContainer {...props} />;
}

function Option({ ...props }: ISpan) {
  return <SelectOption {...props} />;
}

Select.Input = Input;
Select.Menu = Menu;
Select.Option = Option;
