import { useAppDispatch } from "hooks/hooks.redux";

import Animated from "./Animated";
import Button from "components/Button";
import DataCard from "components/DataCard";

import { openConciliationContestationPanelAction } from "model/actions/action.conciliations";
import { submitConciliationAction } from "model/actions/action.conciliations-submit";
import IResumeConciliation from "interfaces/interface.conciliation-resume";
import CurrencyUtilities from "utils/currencyFormatting";

type IProps = {
  data: IResumeConciliation["dados_conciliacao"];
  isLoading: boolean;
  disableActions: boolean;
};

export default function ConciliationDataPanel({
  data,
  isLoading,
  disableActions,
}: IProps) {
  const dispatch = useAppDispatch();

  if (isLoading) return <Animated />;

  const conciliationValue = CurrencyUtilities.formatCurrencyToBrazilianStandard(
    data.valor_total as number
  );
  const numbeOfInstallments = data.total_parcelas;

  function submitConciliation() {
    dispatch(submitConciliationAction());
  }

  function openContestationPanel() {
    dispatch(openConciliationContestationPanelAction());
  }

  return (
    <div className="row conciliations-conciliation-panel-container">
      <div className="col-12 d-flex flex-row align-items-center flex-wrap gap-3">
        <DataCard
          label="Valor"
          value={`R$ ${conciliationValue}`}
          valueClass="text-roboto-fs20-lh23-fw400-orange500"
        />
        <DataCard
          label="Nº de parcelas"
          value={numbeOfInstallments}
          valueClass="text-roboto-fs20-lh23-fw400-orange500"
        />
        {!disableActions && (
          <div className="ms-auto d-flex flex-wrap gap-3">
            <Button
              variant="green"
              className="text-montserrat-fs16-lh19-fw400"
              onClick={submitConciliation}
            >
              Confirmar
            </Button>
            <Button
              variant="red"
              className="text-montserrat-fs16-lh19-fw400"
              onClick={openContestationPanel}
            >
              Solicitar alteração
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
