import { useState } from "react";
import { useAppDispatch } from "hooks/hooks.redux";

import Button from "components/Button";
import Upload from "components/Upload";
import { uploadCollaboratorsUpdateWorksheetAction } from "model/actions/action.collaborators-submit";
import { getWorksheetUpdateModelAction } from "model/actions/action.collaborators";

import "./style.css";

export default function UpdateWorksheet() {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File[]>([]);

  function updateTableCollaborator() {
    dispatch(uploadCollaboratorsUpdateWorksheetAction(files));
    setFiles([])
  }

  function defineFiles(files: File[]) {
    setFiles(files);
  }

  function getWorksheetUpdateModel() {
    dispatch(getWorksheetUpdateModelAction());
  }

  return (
    <>
      <div className="container-fluid collaborator-actions-container flex-grow-1">
        <div className="row mt-3">
          <div className="col-12">
            <Upload onChange={defineFiles} />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex flex-wrap gap-3 justify-content-between">
            <Button
              variant="green"
              disabled={!(files.length !== 0)}
              className="collaborator-updatespreadsheet-button text-roboto-fs12-lh14-fw400-white"
              onClick={updateTableCollaborator}
            >
              Enviar
            </Button>
            <Button
              variant="pink"
              className="collaborator-updatespreadsheet-button text-roboto-fs12-lh14-fw400-white"
              onClick={getWorksheetUpdateModel}
            >
              Baixar modelo
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
