import styled from "styled-components";

export const Overflow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(57, 67, 96, 0.65);
  z-index: 19;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  max-height: 90%;
  max-width: 90%;
  width: fit-content;
`;