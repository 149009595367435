import Http from "services/httpClient";
import { SEARCH_ADMINISTRATORS } from "model/action-types/type.administrators";
import { setNotificationsAction } from "model/actions/action.administrators-notifications";
import { all, put, takeLatest } from "redux-saga/effects";

function* searchNotifications(): any {
  const http = new Http();
  const response = yield http.httpClientGet({
    url: "empresa/notificacao",
  });
  yield put(setNotificationsAction(response));
}


export default all([takeLatest(SEARCH_ADMINISTRATORS, searchNotifications)])