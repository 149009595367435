import { all } from "redux-saga/effects";
import authenticateSubmit from "./sagas.authenticate-submit";
import requests from "model/sagas/sagas.requests";
import requestsSubmit from "model/sagas/sagas.requests-submit";
import administrators from "model/sagas/sagas.administrators";
import administratorsSubmit from "model/sagas/sagas.administrators-submit";
import administratorsNotifications from "model/sagas/sagas.administrators-notifications";
import administratorsFunctions from "model/sagas/sagas.administrators-functions";
import collaborators from "model/sagas/sagas.collaborators";
import collaboratorsSubmit from "model/sagas/sagas.collaborators-submit";
import conciliations from "model/sagas/sagas.conciliations";
import conciliationSubmit from "model/sagas/sagas.conciliations-submit";
import dashboard from "model/sagas/sagas.dashboard";
import profile from "model/sagas/sagas.profile";
import dashboardSubmit from "model/sagas/sagas.dashboard-submit";
import contracts from "model/sagas/sagas.contracts";

export default function* combinedSagas(): any {
  return yield all([
    authenticateSubmit,
    requests,
    requestsSubmit,
    administrators,
    administratorsSubmit,
    administratorsNotifications,
    administratorsFunctions,
    collaborators,
    collaboratorsSubmit,
    conciliations,
    dashboard,
    conciliationSubmit,
    profile,
    dashboardSubmit,
    contracts
  ]);
}
