import { useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { BsFilter } from 'react-icons/bs';

import './style.css'

import { DropdownMenuCheckboxItem, DropdownMenuTrigger } from './styled';

type IProps = {
    onChange: (value: number) => void
}

export default ({ onChange }: IProps) => {

    const [filter, setFilter] = useState(0)

    function onClick(type: number) {
        onChange(type)
        setFilter(type)
    }

    return <DropdownMenu.Root>
        <DropdownMenuTrigger>
            Filtros <BsFilter size={20} color="#fff" />
        </DropdownMenuTrigger>
        <DropdownMenu.Portal>
            <DropdownMenu.Content className='conciliation-dropdonw-menu-content'>
                <DropdownMenuCheckboxItem checked={filter === 0} onClick={() => onClick(0)}>
                    Nenhum
                </DropdownMenuCheckboxItem >
                <DropdownMenuCheckboxItem checked={filter === 1} onClick={() => onClick(1)}>
                    Vencimento
                </DropdownMenuCheckboxItem >
                <DropdownMenuCheckboxItem checked={filter === 2} onClick={() => onClick(2)}>
                    Verba Recisória
                </DropdownMenuCheckboxItem >
            </DropdownMenu.Content>
        </DropdownMenu.Portal>
    </DropdownMenu.Root>
}