import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import LoginForm from "./LoginForm";
import LoginFeedback from "./LoginFeedback";
import PasswordRecovery from "./PasswordRecovery";

import { clearStateAction } from "model/actions/action.general";

export default function ContentBox() {
  const dispatch = useAppDispatch();
  const { requisitionResponse } = useAppSelector(
    (state) => state.authenticateReducer
  );

  const [contentType, setContentType] = useState<
    "authenticate" | "password-recovery" | "feedback"
  >("authenticate");

  useEffect(() => {
    // if (contentType === "authenticate") dispatch(clearStateAction());

    if (requisitionResponse)
      setContentType("feedback");
  }, [contentType, JSON.stringify(requisitionResponse)]);

  function resetContentType() {
    setContentType("authenticate");
  }

  function changeContentType(
    type: "authenticate" | "password-recovery" | "feedback"
  ) {
    setContentType(type);
  }

  switch (contentType) {
    case "authenticate":
      return <LoginForm changeContentType={changeContentType} />;
    case "password-recovery":
      return <PasswordRecovery resetContentType={resetContentType} />;
    default:
      return <LoginFeedback resetContentType={resetContentType} />;
  }
}
