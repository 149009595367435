import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import InmanoCircleWhiteIcon from "assets/img/inmano-circle-white-icon.svg";

import OverviewIconOrange from "assets/img/overview-icon-orange.svg";
import OverviewIcon from "assets/img/overview-icon.svg";
import CollaboratorsIcon from "assets/img/collaborator-icon.svg";
import CollaboratorsIconOrange from "assets/img/collaborator-icon-orange.svg";
import RequestsIcon from "assets/img/requests-icon.svg";
import RequestsIconOrange from "assets/img/requests-icon-orange.svg";
import ConciliationIcon from "assets/img/conciliation-icon.svg";
import ConciliationIconOrange from "assets/img/conciliation-icon-orange.svg";
import AdministratorsIcon from "assets/img/administrators-icon.svg";
import AdministratorsIconOrange from "assets/img/administrators-icon-orange.svg";
import LogOutIcon from "assets/img/logout-icon.svg";
import WalletIconWhite from "assets/img/wallet-white.svg";
import WalletIconOrange from "assets/img/wallet-orange.svg";

import "./style.css";
import "./desktop-sidebar-style.css";
import App from "utils/utilities.app";

export default function DesktopSidebar() {
  const [mouseEnter, setMouseEnter] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  function MenuOption(props: {
    path: string;
    label: string;
    icon: string;
    iconActive: string;
  }) {
    return (
      <Link
        to={props.path}
        className="sidebar-link d-flex flex-row align-items-center gap-4"
        style={{
          color:
            location.pathname === props.path
              ? "var(--orange-500)"
              : "var(--white)",
          fontSize: "1.7rem",
        }}
      >
        {mouseEnter ? props.label : ""}
        <img
          src={location.pathname === props.path ? props.iconActive : props.icon}
          alt=""
          className="sidebar-link-selected"
        />
      </Link>
    );
  }

  return (
    <div className="container-sidebar">
      <aside
        className="sidebar"
        onMouseOver={() => setMouseEnter(true)}
        onMouseOut={() => setMouseEnter(false)}
      >
        <img
          src={InmanoCircleWhiteIcon}
          alt="Icone colorido da InMano com fundo branco."
          onClick={() => navigate("/home")}
        />
        <nav
          className={`sidebar-nav d-flex flex-column align-items-end ${
            mouseEnter ? "sidebar-open-nav" : "sidebar-close-nav"
          }`}
        >
          <MenuOption
            path="/home"
            label="Visão geral"
            icon={OverviewIcon}
            iconActive={OverviewIconOrange}
          />
          <MenuOption
            path="/colaboradores"
            label="Colaboradores"
            icon={CollaboratorsIcon}
            iconActive={CollaboratorsIconOrange}
          />
          <MenuOption
            path="/solicitacoes"
            label="Solicitações"
            icon={RequestsIcon}
            iconActive={RequestsIconOrange}
          />
          <MenuOption
            path="/contratos"
            label="Contratos"
            icon={WalletIconWhite}
            iconActive={WalletIconOrange}
          />
          <MenuOption
            path="/conciliacoes"
            label="Conciliação"
            icon={ConciliationIcon}
            iconActive={ConciliationIconOrange}
          />
          <MenuOption
            path="/administradores"
            label="Administradores"
            icon={AdministratorsIcon}
            iconActive={AdministratorsIconOrange}
          />
          <button
            className="sidebar-link bg-transparent d-flex flex-row align-items-center gap-4"
            onClick={App.exit}
          >
            {mouseEnter ? "Sair" : ""}
            <img src={LogOutIcon} alt="" />
          </button>
        </nav>
      </aside>
    </div>
  );
}
