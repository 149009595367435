// import Input from "components/Input";
import "./style.css";

// import IconSearch from "assets/img/search.svg";
// import IconRemoveValue from "assets/img/remove-value-icon.svg";
import IconNotify from "assets/img/notify-icon.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/hooks.redux";
import { openModalProfileAction } from "model/actions/action.profile";
import { UserUtilities } from "utils/utilities.user";

interface IProps {
  titlePage: string;
  description?: string;
}

export default function Header({ titlePage, description }: IProps) {
  const dispatch = useAppDispatch();
  // const value = "";

  const personIcon = creatPersonIcon();

  const navigate = useNavigate();

  // function nextToRequests() {
  //   navigate("/solicitacoes");
  // }

  function openModalProfile() {
    dispatch(openModalProfileAction());
  }

  function creatPersonIcon() {
    const { eusu_nome } = UserUtilities.getUserAttribute();
    const [name, lastName] = eusu_nome.split(" ");
    let initials = "";
    if (lastName) {
      initials = `${name.substring(0, 1)}${lastName.substring(
        0,
        1
      )}`.toUpperCase();
    } else {
      initials = `${name.substring(0, 1)}`.toUpperCase();
    }

    return initials;
  }

  return (
    <header className="row header mb-5">
      <div className="col-12 d-flex flex-wrap flex-row gap-3 mt-4">
        <div className="flex-grow-1">
          <h1 className="text-roboto-fs30-lh30-fw400-blue700">
            {titlePage || "Teste"}
          </h1>
          <span className="text-roboto-fs12-lh14-fw400-blue700">
            {description || ""}
          </span>
        </div>
        {/* <div className="header-input-container d-flex align-items-center flex-grow-1 justify-content-between gap-3">
          <div className="d-flex align-items-center gap-3 w-100 ">
            {value && (
              <button className="bg-transparent">
                <img src={IconRemoveValue} alt="Icone de remove na cor cinza" />
              </button>
            )}
            <Input
              value={value}
              onChange={(value) => console.log(value)}
              placeholder="Procurar por nome ou CPF ou CNPJ"
              className="header-input"
            />
          </div>
          <button className="header-input-button  rounded-circle">
            <img src={IconSearch} alt="Icone de busca na cor branca" />
          </button>
        </div> */}

        {/* <button
          className="header-button color-pink-500 rounded-circle"
          onClick={nextToRequests}
        >
          <img src={IconNotify} alt="Auto-falante branco sem preechimento" />
        </button> */}

        <button
          className="header-button bg-primary rounded-circle"
          onClick={openModalProfile}
        >
          <span className="text-white fs-3">{personIcon}</span>
        </button>
      </div>
    </header>
  );
}
