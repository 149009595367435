import { Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import "./style.css";

export default function Animated() {
    return (
        <button
            className="row dashboard-collapse-faq w-100"
        >
            <Col md={10} className="d-flex">
                <span className="text-roboto-fs16-lh18-fw700-blue700">
                    <Skeleton width={"25rem"} height="1.8rem" />
                </span>
            </Col>
        </button>
    );
}
