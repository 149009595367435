import { IFeedbackModal } from "interfaces/interface.feedback-modal";
import styled, { css } from "styled-components";

interface ParagraphyFeedbackModalProps {
  status: IFeedbackModal["status"];
}

const paragraphySuccess = css`
  color: ${(props) => props.theme.COLORS.GREEN_300};
`;
const paragraphyWarning = css`
  color: ${(props) => props.theme.COLORS.ORANGE_400};
`;

const paragraphyError = css`
  color: ${(props) => props.theme.COLORS.RED_300};
`;

export const ParagraphyFeedbackModal = styled.li<ParagraphyFeedbackModalProps>`
  font-size: 1.6rem;
  font-familly: "Roboto", sans-serif;
  ${(props) => props.status === "success" && paragraphySuccess};
  ${(props) => props.status === "warning" && paragraphyWarning};
  ${(props) => props.status === "error" && paragraphyError};
  /* text-align: center; */
`;
