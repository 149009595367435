import { useEffect, useState } from "react";
import { useAppSelector } from "hooks/hooks.redux";

import Modal from "components/Modal";
import PersonalDataForm from "./PersonalDataForm";
import InternalDataForm from "./InternalDataForm";

import "./style.css";

export default function CollaboratorPanel() {
  const {
    dataPanel: { show },
  } = useAppSelector((state) => state.collaboratorsReducer);

  const [stage, setStage] = useState<number>(0);

  useEffect(() => {
    if (!show) setStage(0);
  }, [show]);

  function next() {
    stage + 1 > 1 ? setStage(1) : setStage(stage + 1);
  }

  function previous() {
    stage - 1 < 0 ? setStage(0) : setStage(stage - 1);
  }

  const components = [
    <PersonalDataForm next={next} stage={stage} />,
    <InternalDataForm previous={previous} stage={stage} />,
  ];

  function Component() {
    return components[stage];
  }

  return (
    <Modal show={show}>
      <Component />
    </Modal>
  );
}
