import { useNavigate } from "react-router-dom";

import { PropsConciliationReducer } from "model/reducers-types/type.conciliationReducer";

import Button from "components/Button";
import Animated from "./Animated";
import { Col, Row } from "react-bootstrap";

type IProps = {
  data: PropsConciliationReducer["resumeConciliation"]["dados_conciliacao"];
  isLoading: boolean;
};

export default ({ data, isLoading }: IProps) => {
  const navigate = useNavigate();

  if (isLoading) return <Animated />;

  return (
    <Row className="row">
      <Col md={10} className="gy-3 d-flex flex-row gap-5">
        <div className="d-flex flex-column gap-2">
          <span className="text-roboto-fs14-lh16-fw700-blue700">
            Nome Fantasia
          </span>
          <span className="text-roboto-fs20-lh29-fw400-blue600">
            {data.emp_nomefantasia}
          </span>
        </div>
        <div className="d-flex flex-column gap-2">
          <span className="text-roboto-fs14-lh16-fw700-blue700">CNPJ</span>
          <span className="text-roboto-fs20-lh29-fw400-blue600">
            {data.emp_cnpj}
          </span>
        </div>
        <div className="d-flex flex-column gap-2">
          <span className="text-roboto-fs14-lh16-fw700-blue700">
            Tipo de conciliação
          </span>
          <span className="text-roboto-fs20-lh29-fw400-blue600">
            {data.tipo.label}
          </span>
        </div>
        <div className="d-flex flex-column gap-2">
          <span className="text-roboto-fs14-lh16-fw700-blue700">Status da Conciliação</span>
          <span className="text-roboto-fs20-lh29-fw400-blue600">
            {data.status.label}
          </span>
        </div>
      </Col>
      <Col md={2} className="gy-3 d-flex justify-content-end alig-items-center">
        <Button
          variant="blue"
          className="text-montserrat-fs16-lh19-fw400-white w-100"
          onClick={() => navigate("/conciliacoes")}
        >
          Lista geral
        </Button>
      </Col>
    </Row>
  );
};
