export const SEARCH_COLLABORATORS = "SEARCH_COLLABORATORS";
export const SEARCH_COLLABORATORS_UPLOAD_HISTORY =
  "SEARCH_COLLABORATORS_UPLOAD_HISTORY";
export const SEARCH_COLLABORATORS_ENDORSEMENT =
  "SEARCH_COLLABORATORS_ENDORSEMENT";

export const GET_WORKSHEET_UPDATE_MODEL = "GET_WORKSHEET_UPDATE_MODEL";
export const GET_WORKSHEET_SHUTDOWN_MODEL = "GET_WORKSHEET_SHUTDOWN_MODEL";

export const SET_FILTER_BY_COMPANY_COLLABORATORS_ACTION =
  "SET_FILTER_BY_COMPANY_COLLABORATORS_ACTION";
export const FILTER_COLLABORATORS_BY_COLLABORATOR =
  "FILTER_COLLABORATORS_BY_COLLABORATOR";
export const CHANGE_DATA_FORMCOLLABORATOR = "CHANGE_DATA_FORMCOLLABORATOR";

export const DOWNLOAD_HISTORY_WORKSHEET_COLLABORATORS =
  "DOWNLOAD_HISTORY_WORKSHEET_COLLABORATORS";

export const REFRESH_COLLABORATORS_DATA = "REFRESH_COLLABORATORS_DATA";

export const OPEN_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET =
  "OPEN_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET";
export const CLOSE_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET =
  "CLOSE_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET";

export const OPEN_DETAILS_PANEL_COLLABORATOR =
  "OPEN_DETAILS_PANEL_COLLABORATOR";
export const CLOSE_DETAILS_PANEL_COLLABORATOR =
  "CLOSE_DETAILS_PANEL_COLLABORATOR";

export const OPEN_COLLABORATOR_DATA_PANEL = "OPEN_COLLABORATOR_DATA_PANEL";
export const CLOSE_COLLABORATOR_DATA_PANEL = "CLOSE_COLLABORATOR_DATA_PANEL";

export const CHANGE_SELECTED_COLLABORATORS = "CHANGE_SELECTED_COLLABORATORS";

export const SET_TABLE_DATA_COLLABORATORS = "SET_TABLE_DATA_COLLABORATORS";

export const EXPORT_COLLABORATORS = "EXPORT_COLLABORATORS";
