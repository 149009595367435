import styled, { css, keyframes } from "styled-components";

interface ContainerStateProps {
  state: "error" | "success" | "warning";
}

const stateSuccess = css`
  border-color: ${(props) => props.theme.COLORS.GREEN_300};
`;

const stateWarning = css`
  border-color: ${(props) => props.theme.COLORS.ORANGE_500};
`;

const stateError = css`
  border-color: ${(props) => props.theme.COLORS.RED_300};
`;

const borderColorKeyframes = keyframes`
  from {
    border-color: #fff;
  }
`;

export const ContainerState = styled.div<ContainerStateProps>`
  max-width: 90%;
  max-height: 90%;
  /* width: fit-content; */
  background-color: ${(props) => props.theme.COLORS.WHITE};
  padding: 4.5rem 4rem;
  border-radius: 3rem;
  border-style: solid;
  border-width: 0.7rem;

  ${(props) => props.state === "error" && stateError}
  ${(props) => props.state === "warning" && stateWarning}
  ${(props) => props.state === "success" && stateSuccess}

  animation: ${borderColorKeyframes} 1s linear backwards;
`;
