import { combineReducers } from "redux";
import { authenticateReducer } from "./reducer.authenticate";
import { requestsReducer } from "./reducer.requests";
import { administratorsReducer } from "./reducer.admnistrators";
import { feedbackModalReducer } from "./reducer.feedback-modal";
import { collaboratorsReducer } from "./reducer.collaborator";
import { conciliationsReducer } from "./reducer.conciliations";
import { dashboardReducer } from "./reducer.dashboard";
import { modalNotificationReducer } from "./reducer.modal-notification";
import { profileReducer } from "./reducer.profile";
import { modalLoadingReducer } from "./reducer.modal-loading";
import { generalReducer } from "./reducer.general";
import { dialogModalReducer } from "./reducer.dialog-modal";
import { contractsReducer } from "./reducer.contracts";

export const combinedReducers = combineReducers({
  authenticateReducer,
  requestsReducer,
  administratorsReducer,
  feedbackModalReducer,
  collaboratorsReducer,
  conciliationsReducer,
  dashboardReducer,
  modalNotificationReducer,
  profileReducer,
  modalLoadingReducer,
  generalReducer,
  dialogModalReducer,
  contractsReducer,
});
