import { Table } from "layout/Table/styled";
import IConciliationHistory from "interfaces/interface.conciliation-history";
import RowConciliationHistory from "./Rows/RowConciliationHistory";

export default function TableConciliationHistory({
  data: conciliation,
}: {
  data: IConciliationHistory[];
}) {
  return (
      <Table responsive hover>
        <thead>
          <tr>
            <th className="table-text">Nome Fantasia</th>
            <th className="table-text">CNPJ</th>
            <th className="table-text">Valor total</th>
            <th className="table-text">N° de parcelas</th>
            <th className="table-text">Data de vencimento</th>
            <th className="table-text">Tipo de conciliação</th>
            <th className="table-text">Status</th>
          </tr>
        </thead>
        <tbody>
          {conciliation.map((item, key) => (
            <RowConciliationHistory key={key} conciliation={item} />
          ))}
        </tbody>
      </Table>
  );
}
