import { useNavigate } from "react-router-dom";
import Badge from "components/Badge";
import StatusIndicator from "components/StatusIndicator";
import IConciliation from "interfaces/http-response/interface.conciliation";
import CurrencyUtilities from "utils/currencyFormatting";
import { UserUtilities } from "utils/utilities.user";
import IColorsVariants from "interfaces/interface.colors-variants";

export default function RowConciliationGroups({
  conciliation,
}: {
  conciliation: IConciliation;
}) {
  const navigate = useNavigate();

  const amount = CurrencyUtilities.formatCurrencyToBrazilianStandard(conciliation.valor_total);

  const variants: Array<IColorsVariants["colorsVariant"]> = ["orange", "green", "red", "silver"];

  function nextToResume() {
    const isAuthorizedToReconcile = UserUtilities.isAuthorized([1, 3]);

    if (!isAuthorizedToReconcile) return;

    navigate(`/conciliacoes/resumo?conciliacao=${conciliation.conci_id}`);
  }

  return (
    <tr className="cursor-pointer" onClick={nextToResume}>
      <td>
        <span className="table-text">{conciliation.emp_nomefantasia}</span>
      </td>
      <td>
        <span className="table-text">{conciliation.emp_cnpj}</span>
      </td>
      <td>
        <span className="table-text">R$ {amount}</span>
      </td>
      <td>
        <span className="table-text">{conciliation.total_parcelas}</span>
      </td>
      <td>
        <div className="d-flex flex-row align-items-center gap-3">
          <StatusIndicator
            variant={
              conciliation.tipo.label === "Vencimento" ? "orange" : "pink"
            }
          />
          <span className="table-text">{conciliation.tipo.label}</span>
        </div>
      </td>
      <td>
        <Badge
          variant={variants[conciliation.situacao.concistatus_id - 1]}//obtém a variant de acordo com o status da conciliação
          className="text-roboto-fs14-lh16-fw400-white"
        >
          {conciliation.situacao.label}
        </Badge>
      </td>
    </tr>
  );
}
