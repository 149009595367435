import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import Body from "layout/DefaultBody";
import TableContainer from "layout/TableContainer";
import Button from "components/Button";
import TableRequests from "./components/Table";
import ReactSelect from "components/ReactSelect";
import StatusIndicator from "components/StatusIndicator";
import AlerApproveInBatch from "./components/AlertApproveInBatch";

import { Companies } from "utils/company";
import { UserUtilities } from "utils/utilities.user";
import {
  requestFilterByStatusAction,
  requestFilterByCompanyAction,
  searchRequestsAction,
} from "model/actions/action.request";
import { exportRequestsAction } from "model/actions/action.request-submit";

import { LabelAmount, TextAmount } from "./styled";

export default function Requests() {
  const dispatch = useAppDispatch();
  const {
    requestStatusFilter,
    currentPage,
    lastPage,
    isAllChecked,
    requests,
    listOfSelectedProposals,
    total,
    requestedQuantity,
  } = useAppSelector((state) => state.requestsReducer);
  const { isLoading } = useAppSelector((state) => state.generalReducer);

  const [showAlertApproveInBatch, setShowAlertApproveInBatch] = useState(false);

  const numberOfLinesSelected =
    listOfSelectedProposals[currentPage]?.length || 0;
  const buttonDisabled = !(isAllChecked || numberOfLinesSelected !== 0);
  const batchApproveDisabled = buttonDisabled || requestStatusFilter !== 0;
  const buttonTableOptiooDisabled = isLoading;

  const label =
    numberOfLinesSelected > 1
      ? "Colaboradores selecionados"
      : "Colaborador selecionado";

  const { getLisCompanies } = new Companies();
  const companies = getLisCompanies();

  const isAuthorizedToApprove = UserUtilities.isAuthorized([1, 2]);

  useEffect(() => {
    paginate();
  }, []);

  function paginate(page?: number) {
    dispatch(searchRequestsAction(page));
  }

  function changeRequestStatus(value: number | null) {
    dispatch(requestFilterByStatusAction(value));
  }

  function filterRequestsByCompany(event: any) {
    dispatch(requestFilterByCompanyAction(event.value));
  }

  function exportRequests() {
    dispatch(exportRequestsAction());
  }

  function openAlertApproveInBatch() {
    setShowAlertApproveInBatch(true);
  }

  function closeAlertApproveInBatch() {
    setShowAlertApproveInBatch(false);
  }

  return (
    <Body
      titlePage="Solicitações"
      description="Abaixo listamos as solicitações realizadas pelos colaboradores da empresa."
    >
      <Row>
        <Col md={12} className="d-flex flex-wrap gap-4 mb-5">
          <span className="text-roboto-fs14-lh16-fw700-blue700 me-3">
            Tipo de solicitação:
          </span>

          <div className="d-flex flex-row gap-4">
            <StatusIndicator variant="green" />
            <span className="text-roboto-fs14-lh16-fw400-blue700">
              Empréstimo
            </span>
          </div>
          <div className="d-flex flex-row gap-4">
            <StatusIndicator variant="blue" />
            <span className="text-roboto-fs14-lh16-fw400-blue700">
              Refinanciamento
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={3} className="gy-3">
          <ReactSelect options={companies} onChange={filterRequestsByCompany} />
        </Col>
        <Col md={5} className="gy-3 d-flex flex-row flex-wrap gap-4">
          <Button
            variant="orange"
            isActive={requestStatusFilter === 0}
            className="text-roboto-fs14-lh16-fw400"
            onClick={() => {
              changeRequestStatus(0);
            }}
            disabled={buttonTableOptiooDisabled}
          >
            Pendente
          </Button>
          <Button
            variant="green"
            isActive={requestStatusFilter === 1}
            className="text-roboto-fs14-lh16-fw400"
            onClick={() => {
              changeRequestStatus(1);
            }}
            disabled={buttonTableOptiooDisabled}
          >
            Aprovada
          </Button>
          <Button
            variant="red"
            isActive={requestStatusFilter === 6}
            className="text-roboto-fs14-lh16-fw400"
            onClick={() => {
              changeRequestStatus(6);
            }}
            disabled={buttonTableOptiooDisabled}
          >
            Recusada
          </Button>
          <Button
            variant="blue"
            isActive={requestStatusFilter === null}
            className="text-roboto-fs14-lh16-fw400"
            onClick={() => {
              changeRequestStatus(null);
            }}
            disabled={buttonTableOptiooDisabled}
          >
            Todas
          </Button>
        </Col>

        <Col
          md={4}
          className="gy-3 d-flex align-items-center justify-content-end gap-4"
        >
          {numberOfLinesSelected > 0 && (
            <p className="text-roboto-fs14-lh16-fw400-blue700 mb-0">
              <span className="text-roboto-fs14-lh16-fw700-pink500 me-1">
                {numberOfLinesSelected}
              </span>
              {label}
            </p>
          )}
          <Button
            variant="pink"
            disabled={buttonDisabled}
            className="text-roboto-fs12-lh14-fw400 "
            onClick={exportRequests}
          >
            Baixar Arquivo
          </Button>

          {isAuthorizedToApprove && (
            <Button
              variant="green"
              disabled={batchApproveDisabled}
              className="text-roboto-fs12-lh14-fw400 "
              onClick={openAlertApproveInBatch}
            >
              Aprovar em Lote
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TableContainer
            isLoading={isLoading}
            pagination={{
              currentPage,
              paginate,
              lastPage,
              total,
            }}
          >
            <TableRequests data={requests} isAllChecked={isAllChecked} />
          </TableContainer>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="gy-3 d-flex justify-content-end">
          {!isLoading && requestedQuantity !== 0 && (
            <LabelAmount>
              Valor total solicitado:{" "}
              <TextAmount>
                R$ {new Intl.NumberFormat().format(requestedQuantity)}
              </TextAmount>
            </LabelAmount>
          )}
        </Col>
      </Row>
      <AlerApproveInBatch
        show={showAlertApproveInBatch}
        close={closeAlertApproveInBatch}
      />
    </Body>
  );
}
