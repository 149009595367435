import {
  SEARCH_ADMINISTRATORS,
  SET_ADMINISTRATORS,
} from "model/action-types/type.administrators";

export function searchAdministratorsAction(page?: number) {
  return { type: SEARCH_ADMINISTRATORS, page };
}

export function setAdministratorsAction(data: any) {
  return { type: SET_ADMINISTRATORS, data };
}