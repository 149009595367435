import { useEffect, useState } from "react";
import { Search } from "react-bootstrap-icons";
import { Col, Row } from "react-bootstrap";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import { Companies } from "utils/company";
import {
  setFilterByCompanyCollaboratorsAction,
  searchCollaboratorsAction,
  searchCollaboratorsEndorsementAction,
  searchCollaboratorsUploadoHistoryAction,
  openCollaboratorDataPanelAction,
  filterCollaboratorsByCollaboratorAction,
  exportCollaboratorsAction,
} from "model/actions/action.collaborators";

import Body from "layout/DefaultBody";
import ReactSelect from "components/ReactSelect";
import TableContainer from "layout/TableContainer";
import Table from "./components/Table";
import Input from "components/Input";
import Button from "components/Button";
import UpdateSpreadsheet from "./components/UpdateWorksheet";
import CreateShutdownList from "./components/CreateShutdownList";
import CollapseFeaturesCollaborators from "./layout/Collapse/indext";
import ShutdownWorksheet from "./components/ShutdownWorksheet";

import "./style.css";
import { getQueryParam } from "services/router";
import CollaboratorHelper from "./helper.collaborators";

export default function Collaborators() {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const {
    filterByCollaborator,
    currentPage,
    data,
    lastPage,
    total,
    selectedCollaborators,
  } = useAppSelector((state) => state.collaboratorsReducer);

  const { isLoading } = useAppSelector((state) => state.generalReducer);

  const initialStateOfSelect = {
    label: "Todas as opções",
    value: null,
  };

  const [isOpenShutdownList, setIsOpenShutdownList] = useState(false);
  // const [isOpenUpdateSpreadsheet, setIsOpenUpdateSpreadsheet] = useState(false);
  const [selectValue, setSelectValue] = useState(initialStateOfSelect);

  const [collboratorInputValue, setCollaboratorInputValue] =
    useState(filterByCollaborator);

  const { getLisCompanies } = new Companies();
  const options = getLisCompanies();

  const buttonDisabled = isLoading;

  const numberOfLinesSelected = selectedCollaborators[currentPage]?.length || 0;
  const buttonDownloadFileDisabled = isLoading || numberOfLinesSelected === 0;

  const [tableOption, setTableOption] = useState(
    CollaboratorHelper.getTableOption()
  );

  useEffect(() => {
    const tableId = Number(getQueryParam("table") ?? 0);

    CollaboratorHelper.setTableOption(tableId);
    setTableOption(tableId);
  }, []);

  useEffect(() => {
    setTableOption(CollaboratorHelper.getTableOption());
  }, [CollaboratorHelper.getTableOption()]);

  useEffect(() => {
    setCollaboratorInputValue(filterByCollaborator);
  }, [filterByCollaborator]);

  useEffect(() => {
    paginate();
  }, [tableOption]);

  function filterCollaboratorsByCompany(event: any) {
    dispatch(setFilterByCompanyCollaboratorsAction(event.value));
    setSelectValue(event);
  }

  // function setValueCollaborator(value: string) {
  //   dispatch(filterCollaboratorsByCollaboratorAction(value));
  // }

  // function filterCollaboratorsByCollaborator() {
  //   dispatch(searchCollaboratorsAction());
  // }

  function selectTableOfAllCollaborators() {
    CollaboratorHelper.setTableOption(0);

    dispatch(setFilterByCompanyCollaboratorsAction(null));
    setSelectValue(initialStateOfSelect);
  }

  function selectTableEndorsement() {
    CollaboratorHelper.setTableOption(1);

    dispatch(setFilterByCompanyCollaboratorsAction(null));
    setSelectValue(initialStateOfSelect);
  }

  // function selectUploadHistoryTable() {
  //   dispatch(setTableOptionCollaboratorAction(2));
  //   setSelectValue(initialStateOfSelect);
  // }

  function isThisTableOptionSelected(option: 0 | 1 | 2) {
    return tableOption === option;
  }

  function paginate(page?: number) {
    switch (tableOption) {
      case 1:
        dispatch(searchCollaboratorsEndorsementAction(page));
        break;
      case 2:
        dispatch(searchCollaboratorsUploadoHistoryAction(page));
        break;
      default:
        dispatch(searchCollaboratorsAction(page));
        break;
    }
  }

  function openDataPanel() {
    dispatch(openCollaboratorDataPanelAction());
  }

  // function openPanelUpdateSpreadsheet(value: boolean) {
  //   setIsOpenUpdateSpreadsheet(value);

  //   if (value) setIsOpenShutdownList(false);
  // }

  function openPanelShutdownList(value: boolean) {
    setIsOpenShutdownList(value);

    // if (value) setIsOpenUpdateSpreadsheet(false);
  }

  return (
    <Body
      titlePage="Colaboradores"
      description="Consulte, cadastre, desligue e atualize os colaboradores por aqui."
    >
      <div className="row">
        <div className="col-md-6 gy-3">
          <CollapseFeaturesCollaborators
            label="Desligar colaboradores"
            isOpen={isOpenShutdownList}
            setIsOpen={openPanelShutdownList}
          >
            <CreateShutdownList />
          </CollapseFeaturesCollaborators>
        </div>
        <div className="col-md-6 gy-3">
          <CollapseFeaturesCollaborators
            label="Atualizar Base de Colaboradores"
            // isOpen={isOpenUpdateSpreadsheet}
            isOpen={false}
            // setIsOpen={openPanelUpdateSpreadsheet}
            setIsOpen={() => {}}
            disabled={true}
          >
            <UpdateSpreadsheet />
          </CollapseFeaturesCollaborators>
        </div>
      </div>
      <Row className="mt-5">
        <Col xl={3} className="gy-3 d-flex flex-row">
          <div className="container-input w-100">
            <Input
              value={collboratorInputValue}
              maskType={"name-cpf"}
              onChange={(value) => setCollaboratorInputValue(value)}
              className="collaborators-input"
              placeholder="Nome ou CPF"
            />
            <button
              onClick={() =>
                dispatch(
                  filterCollaboratorsByCollaboratorAction(collboratorInputValue)
                )
              }
            >
              <Search size={20} color={theme.COLORS.WHITE} />
            </button>
          </div>
        </Col>

        <Col xl={2} className="gy-3">
          <ReactSelect
            width="100%"
            options={options}
            onChange={filterCollaboratorsByCompany}
            isDisabled={tableOption === 2}
            value={selectValue}
          />
        </Col>
        <Col
          xl={4}
          className="gy-3 d-flex gap-3 collaborators-buttonfilter-container"
        >
          <Button
            variant="blue"
            isActive={isThisTableOptionSelected(0)}
            className="text-roboto-fs12-lh14-fw400"
            onClick={selectTableOfAllCollaborators}
            disabled={buttonDisabled}
          >
            Colaboradores
          </Button>
          <Button
            variant="orange"
            isActive={isThisTableOptionSelected(1)}
            className="text-roboto-fs12-lh14-fw400"
            onClick={selectTableEndorsement}
            disabled={buttonDisabled}
          >
            Informar averbação
          </Button>
          {/* <Button
            variant="pink"
            isActive={isThisTableOptionSelected(2)}
            className="text-roboto-fs12-lh14-fw400"
            onClick={selectUploadHistoryTable}
            disabled={buttonDisabled}
          >
            Histórico de upload
          </Button> */}
        </Col>
        <Col xl={3} className="gy-3 d-flex flex-row justify-content-end gap-3">
          {tableOption === 1 && (
            <Button
              variant="pink"
              className="text-roboto-fs12-lh14-fw400"
              disabled={buttonDownloadFileDisabled}
              onClick={() => dispatch(exportCollaboratorsAction())}
            >
              Baixar arquivo
            </Button>
          )}
          <Button
            variant="gray"
            className="text-roboto-fs12-lh14-fw400"
            onClick={openDataPanel}
          >
            Adicionar coraborador(a) um a um
          </Button>
        </Col>
      </Row>
      <Row className="row">
        <Col md={12}>
          <TableContainer
            isLoading={isLoading}
            pagination={{
              currentPage,
              paginate,
              lastPage,
              total,
            }}
          >
            <Table option={tableOption} data={data} />
          </TableContainer>
        </Col>
      </Row>
      <ShutdownWorksheet />
    </Body>
  );
}
