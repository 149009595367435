import { useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import ReactSelect from "components/ReactSelect";
import Button from "components/Button";
import FormContainer from "./FormContainer";
import InputLabel from "./ElementsForm/InputLabel";

import { schemaValidate } from "schemas/schema.collaborator-internal-data";
import { submitCollaboratorAction } from "model/actions/action.collaborators-submit";
import { changeDataFormCollaboratorAction } from "model/actions/action.collaborators";
import { Companies } from "utils/company";
import CurrencyUtilities from "utils/currencyFormatting";
import { UtilitiesDate } from "utils/utilities.date";
import { IFormCollaboratorInternalData } from "interfaces/interface.form-collaborators";

interface IProps {
  previous: () => void;
  stage: number;
}

export default function InternalDataForm({ previous, stage }: IProps) {
  const dispatch = useAppDispatch();
  const {
    dataPanel: { data },
  } = useAppSelector((state) => state.collaboratorsReducer);

  const { getLisCompanies } = new Companies();

  const initialStateOfForm: IFormCollaboratorInternalData = {
    emp_id: data.emp_id ? data.emp_id : 0,
    func_dtadmissao: data.func_dtadmissao
      ? UtilitiesDate.formatStringDateToDefaultBrazilian(data.func_dtadmissao)
      : "",
    func_salariobruto: CurrencyUtilities.formatCurrencyToBrazilianStandard(
      data.func_salariobruto || 0
    ),
    func_salarioliquido: CurrencyUtilities.formatCurrencyToBrazilianStandard(
      data.func_salarioliquido || 0
    ),
    func_dtfimcontrato: data.func_dtfimcontrato
      ? UtilitiesDate.formatStringDateToDefaultBrazilian(
          data.func_dtfimcontrato
        )
      : "",
    func_margem: CurrencyUtilities.formatCurrencyToBrazilianStandard(
      data.func_margem || 0
    ),
    func_matricula: data ? data.func_matricula : "",
  };

  const [form, setForm] = useState(initialStateOfForm);

  const options = getLisCompanies();

  let schemaValidationOptions = options.find(
    (item) => item.value === form.emp_id
  );

  const enableEndOfContractField = Boolean(
    schemaValidationOptions?.hasAnEndDateOfTheContract
  );

  const enableAssignableMarginField = Boolean(
    schemaValidationOptions?.hasMargin
  );

  const formIsValid = schemaValidate(form, {
    hasAnEndDateOfTheContract:
      schemaValidationOptions?.hasAnEndDateOfTheContract,
    hasMargin: schemaValidationOptions?.hasMargin,
  });

  const currentCompany = getLisCompanies().find(
    (item) => item.value === form?.emp_id
  );

  function changeDataForm(field: string, value: string) {
    let newDataForm: any = form;
    // console.log([field, value, schemaValidationOptions?.hasMargin]);
    // if (field === "emp_id") {
    //   dispatch(
    //     setCollaboratorHasMargimAction(schemaValidationOptions?.hasMargin)
    //   );
    // }
    newDataForm = {
      ...newDataForm,
      [field]: value,
    };
    setForm(newDataForm);
  }

  function previousStage() {
    dispatch(
      changeDataFormCollaboratorAction({
        form: form,
        hasMargin: schemaValidationOptions?.hasMargin,
        hasAnEndDateOfTheContract:
          schemaValidationOptions?.hasAnEndDateOfTheContract,
      })
    );
    previous();
  }

  function submit() {
    dispatch(
      changeDataFormCollaboratorAction({
        form: form,
        hasMargin: schemaValidationOptions?.hasMargin,
        hasAnEndDateOfTheContract:
          schemaValidationOptions?.hasAnEndDateOfTheContract,
      })
    );
    dispatch(submitCollaboratorAction());
  }

  return (
    <FormContainer
      title="Dados Internos"
      stage={stage}
      formContainer={
        <>
          <div className="row mb-3">
            <div className="col-md-12 gy-3">
              <ReactSelect
                options={options}
                placeholder="Empresa"
                borderColor="var(--gray-500)"
                height={"5rem"}
                forceChoice
                value={currentCompany}
                onChange={(object: any) =>
                  changeDataForm("emp_id", object.value)
                }
              />
            </div>
          </div>
          <div className="collaborator-grid-internal-data">
            <InputLabel
              placeholder="Data de admissão"
              label="Data de admissão"
              maskType="custom"
              mask="99/99/9999"
              value={form.func_dtadmissao}
              onChange={(value) => changeDataForm("func_dtadmissao", value)}
            />
            <InputLabel
              placeholder="Salário bruto"
              label="Salário bruto"
              value={form.func_salariobruto}
              maskType="currency"
              onChange={(value) => changeDataForm("func_salariobruto", value)}
            />
            <InputLabel
              placeholder="Salário liquído"
              label="Salário liquído"
              value={form.func_salarioliquido}
              maskType="currency"
              onChange={(value) => changeDataForm("func_salarioliquido", value)}
            />
            {enableAssignableMarginField && (
              <InputLabel
                placeholder="Margem consignável %"
                label="Margem consignável"
                value={form.func_margem || ""}
                maskType="currency"
                onChange={(value) => changeDataForm("func_margem", value)}
              />
            )}
            {enableEndOfContractField && (
              <InputLabel
                placeholder="Fim de contrato"
                label="Fim de contrato"
                value={form.func_dtfimcontrato}
                maskType="custom"
                mask="99/99/9999"
                onChange={(value) =>
                  changeDataForm("func_dtfimcontrato", value)
                }
              />
            )}
            <InputLabel
              placeholder="Matrícula"
              label="Matrícula"
              value={form.func_matricula}
              onChange={(value) => changeDataForm("func_matricula", value)}
            />
          </div>
        </>
      }
      buttonContainer={
        <>
          <Button
            variant="blue"
            isActive={false}
            className="text-montserrat-fs16-fw400 d-flex gap-3"
            onClick={previousStage}
          >
            Anterior
          </Button>

          <Button
            variant="blue"
            className="text-montserrat-fs16-fw400"
            disabled={!formIsValid}
            onClick={submit}
          >
            Salvar
          </Button>
        </>
      }
    />
  );
}
