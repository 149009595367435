import { createContext, ReactNode, useState } from "react";

type Context = {
  isOpen: boolean;
  onClose: () => void,
  onOpen: () => void,
};

export const ContexMobileSidebar = createContext({
  isOpen: false,
  onClose: () => null,
  onOpen: () => null,
} as Context);

type Provider = {
  children: ReactNode;
};

export function ProviderMobileSidebar({ children }: Provider) {
  const [isOpen, setIsOpen] = useState(false);

  function onOpen() {
    setIsOpen(true)
  }

  function onClose() {
    setIsOpen(false)
  }

  return (
    <ContexMobileSidebar.Provider value={{isOpen, onClose, onOpen}}>
      {children}
    </ContexMobileSidebar.Provider>
  );
}
