import { useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import Button from "components/Button";
import FormContainer from "./FormContainer";
import InputLabel from "./ElementsForm/InputLabel";

import { personalDataSchema } from "schemas/schema.collaborator-personal-data";
import { changeDataFormCollaboratorAction } from "model/actions/action.collaborators";
import { UtilitiesDate } from "utils/utilities.date";
import { IFormCollaboratorPersonalData } from "interfaces/interface.form-collaborators";

interface IProps {
  next: () => void;
  stage: number;
}

export default function PersonalDataForm({ next, stage }: IProps) {
  const dispatch = useAppDispatch();
  const {
    dataPanel: { data },
  } = useAppSelector((state) => state.collaboratorsReducer);

  const initialStateOfForm: IFormCollaboratorPersonalData = {
    func_nome: data ? data.func_nome : "",
    func_cpf: data ? data.func_cpf : "",
    func_dtnascimento: data
      ? UtilitiesDate.formatStringDateToDefaultBrazilian(data.func_dtnascimento)
      : "",
  };

  const [form, setForm] = useState(initialStateOfForm);

  const formIsValid = personalDataSchema.isValidSync(form);

  function changeDataForm(field: string, value: string) {
    let newDataForm: any = form;

    newDataForm = {
      ...newDataForm,
      [field]: value,
    };
    setForm(newDataForm);
  }

  function nextStage() {
    dispatch(changeDataFormCollaboratorAction({ form }));
    next();
  }

  return (
    <FormContainer
      title="Dados Pessoais"
      stage={stage}
      formContainer={
        <>
          <div className="row">
            <div className="col-md-12 gy-3">
              <InputLabel
                placeholder="Nome completo"
                label="Nome completo"
                value={form.func_nome}
                onChange={(value) => changeDataForm("func_nome", value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 gy-3">
              <InputLabel
                placeholder="CPF"
                label="CPF"
                value={form.func_cpf}
                maskType="cpf"
                onChange={(value) => changeDataForm("func_cpf", value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 gy-3">
              <InputLabel
                placeholder="Data de nascimento"
                label="Data de nascimento"
                value={form.func_dtnascimento}
                maskType="custom"
                mask="99/99/9999"
                onChange={(value) => changeDataForm("func_dtnascimento", value)}
              />
            </div>
            {/* <div className="col-md-6 gy-3">
              <InputLabel
                placeholder="Matrícula"
                label="Matrícula"
                value={form.func_matricula}
                onChange={(value) => changeDataForm("func_matricula", value)}
              />
            </div> */}
          </div>
        </>
      }
      buttonContainer={
        <Button
          variant="blue"
          className="text-montserrat-fs16-fw400"
          onClick={nextStage}
          disabled={!formIsValid}
        >
          Próximo
        </Button>
      }
    />
  );
}
