import { all, put, takeLatest } from "redux-saga/effects";
import Http from "services/httpClient";
import { SEARCH_DASHBOARD } from "model/action-types/type.dashboard";
import { setDashboardAction, setFaq } from "model/actions/action.dashboard";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";
import {
  finishLoadingAction,
  startLoadingAction,
} from "model/actions/action.general";

function* searchDashboard(): any {
  yield put(startLoadingAction());

  const http = new Http();

  const response = yield http.httpClientGet({
    url: "empresa/dashboard",
  });

  yield put(finishLoadingAction());

  if (response.error) {
    return yield put(openFeedbackModalAction(response.message, "error"));
  }

  yield put(setDashboardAction(response));
}

function* searchFaq(): any {
  yield put(startLoadingAction());
  
  const http = new Http();

  const response = yield http.httpClientGet({
    url: "empresa/faqrh",
  });

  yield put(finishLoadingAction());

  if (response.error) {
    return yield put(openFeedbackModalAction(response.message, "error"));
  }

  yield put(setFaq(response));
}

export default all([
  takeLatest(SEARCH_DASHBOARD, searchDashboard),
  takeLatest(SEARCH_DASHBOARD, searchFaq),
]);
