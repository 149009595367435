import produce from "immer";
import {
  CLEAR_STATE_MODAL_PROFILE,
  OPEN_MODAL_PROFILE,
  SET_ERROR_PASSWORD_PROFILE,
} from "model/action-types/type.profile";
import { CHANGE_NOTIFY_SETTING_PROFILE, CHANGE_PASSWORD_PROFILE } from "model/action-types/type.profile-submit";
import { AnyAction } from "redux";

const INITIAL_STATE = {
  show: false as boolean,
  passwordError: false as boolean,
};

export function profileReducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case CHANGE_PASSWORD_PROFILE:
      return state;
    case CHANGE_NOTIFY_SETTING_PROFILE:
      return state;
    case OPEN_MODAL_PROFILE:
      return produce(state, (draft) => {
        draft.show = true;
      });
    case SET_ERROR_PASSWORD_PROFILE:
      return produce(state, (draft) => {
        draft.passwordError = action.state;
      });
    case CLEAR_STATE_MODAL_PROFILE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
}
