import IAdministrators from "interfaces/http-response/interface.administrators";
import {
  CLOSE_DETAILS_PANEL,
  OPEN_DETAILS_PANEL,
  SET_COMPANY_DETAILS_PANEL,
} from "model/action-types/type.administrators-details-panel";

export function openDetailsPanelAction(administrator?: IAdministrators) {
  return { type: OPEN_DETAILS_PANEL, administrator };
}

export function closeDetailsPanelAction() {
  return { type: CLOSE_DETAILS_PANEL };
}

export function setCompanyDetailsPanelAction(company: any) {
  return { type: SET_COMPANY_DETAILS_PANEL, company };
}
