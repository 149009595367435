import produce from "immer";
import { AnyAction } from "redux";
import {
  REQUEST_FILTER_BY_STATUS,
  SEARCH_REQUESTS,
  SET_REQUESTS,
  REQUEST_FILTER_BY_COMPANY,
  CHANGE_LIST_OF_SELECTED_PROPOSALS,
  CHANGE_EXPORT_ALL_REQUESTS,
  CLEAR_LIST_OF_SELECTED_PROPOSALS,
  OPEN_REQUEST_STATUS,
  CLOSE_REQUEST_STATUS,
  REMOVE_ALL_REQUESTS_FROM_THE_SELECTED_LIST,
  MOVE_ALL_REQUESTS_TO_SELECTED_LIST,
} from "model/action-types/type.requests";
import {
  EXPORT_REQUESTS,
  APPROVE_A_REQUEST,
  REFUSE_A_REQUEST,
  APPROVE_A_REQUEST_LIST,
} from "model/action-types/type.requests-submit";
import { PropsRequestReducer } from "model/reducers-types/type.requestReducers";
import IRequests from "interfaces/http-response/interface.requests";
import { CLEAR_STATE } from "model/action-types/type.general";
import ListById from "utils/utilities.list-by-id";

const INITIAL_STATE: PropsRequestReducer = {
  lastPage: 0,
  total: 0,
  currentPage: 0,
  listOfSelectedProposals: [],
  isAllChecked: false,
  requestStatus: null,
  requestStatusFilter: 0,
  requests: [],
  companyFilter: null,
  requestedQuantity: 0,
};

export function requestsReducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case SEARCH_REQUESTS:
      return state;
    case SET_REQUESTS:
      return produce(state, (draft) => {
        draft.lastPage = action.data.last_page;
        draft.currentPage = action.data.current_page;
        draft.requests = action.data.data;
        draft.total = action.data.total;

        draft.requestedQuantity = action.data.total_solicitado;

        draft.isAllChecked = ListById.checkIfAIndexIsFullyPopulated(
          action.data.current_page,
          state.listOfSelectedProposals,
          action.data.data.length
        );
      });
    case REQUEST_FILTER_BY_COMPANY:
      return produce(state, (draft) => {
        draft.companyFilter = action.empId;
      });
    case REQUEST_FILTER_BY_STATUS:
      return produce(state, (draft) => {
        draft.requestStatusFilter = action.value;
      });
    case CHANGE_LIST_OF_SELECTED_PROPOSALS:
      return produce(state, (draft) => {
        const newList = ListById.changeList(
          action.requestId,
          state.currentPage,
          state.listOfSelectedProposals
        );

        draft.listOfSelectedProposals = newList;
        draft.isAllChecked = ListById.checkIfAIndexIsFullyPopulated(
          state.currentPage,
          newList,
          state.total
        );
      });
    case MOVE_ALL_REQUESTS_TO_SELECTED_LIST:
      return produce(state, (draft) => {
        const newList = ListById.addAllIds(
          state.requests,
          state.currentPage,
          state.listOfSelectedProposals,
          "ppst_id"
        );
        draft.listOfSelectedProposals = newList;
        draft.isAllChecked = ListById.checkIfAIndexIsFullyPopulated(
          state.currentPage,
          newList,
          state.requests.length
        );
      });
    case REMOVE_ALL_REQUESTS_FROM_THE_SELECTED_LIST:
      return produce(state, (draft) => {
        draft.listOfSelectedProposals = ListById.removeAllIds(
          state.currentPage,
          state.listOfSelectedProposals
        );
        draft.isAllChecked = false;
      });
    case CLEAR_LIST_OF_SELECTED_PROPOSALS:
      return produce(state, (draft) => {
        if (action.clearAll) draft.isAllChecked = false;
        else {
          draft.listOfSelectedProposals = [];
        }
      });
    case EXPORT_REQUESTS:
      return state;
    case OPEN_REQUEST_STATUS:
      return produce(state, (draft) => {
        draft.requestStatus = state.requests?.filter(
          (element: any) => element.ppst_id === action.idRequest
        )[0] as IRequests;
      });
    case CLOSE_REQUEST_STATUS:
      return produce(state, (draft) => {
        draft.requestStatus = null;
      });
    case APPROVE_A_REQUEST:
      return state;
    case REFUSE_A_REQUEST:
      return state;
    case APPROVE_A_REQUEST_LIST:
      return state;
    case CLEAR_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
}
