import { all, put, select, takeLatest } from "redux-saga/effects";
import fileDownload from "js-file-download";
import Http from "services/httpClient";

import CurrencyUtilities from "utils/currencyFormatting";
import GeneralUtilities from "utils/utilities.general";

import {
  refreshCollaboratorsDataAction,
  closeEndorsementPanelAction,
  closeCollaboratorDataPanelAction,
  closeCollaboratorModalShutdowWorksheet,
} from "model/actions/action.collaborators";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";

import {
  CHANGE_EMPLOYEE_STATUS,
  SUBMIT_COLLABOARATOR,
  SUBMIT_ENDORSEMENT,
  SUBMIT_SHUTDOWN_LIST,
  SUBMIT_SHUTDOWN_WORKSHEET,
  SUBMIT_UPLOAD_WORKSHEET,
} from "model/action-types/type.collaborators-submit";
import { UtilitiesDate } from "utils/utilities.date";
import {
  closeModalLoading,
  openModalLoading,
} from "model/actions/action.modal-loading";

function* changeEmployeeStatus(action: any): any {
  const http = new Http();
  const response = yield http.httpClientPatch({
    url: `empresa/funcionario/${action.id}`,
    data: {
      func_situacao: action.status,
    },
  });
  if (response.error) {
    yield put(openFeedbackModalAction(response.message, "error"));
    return false;
  }
  yield put(refreshCollaboratorsDataAction());
}

function* uploadCollaboratorsUpdateWorksheet(action: any): any {
  const http = new Http();

  var formData = new FormData();
  formData.append("arquivo", action.files[0]);

  // const response = yield http.httpClientPost({
  //   url: "empresa/funcionario/atualizacolaboradores",
  //   data: formData,
  // });

  // if (response.error) {
  //   yield put(openFeedbackModalAction(response.message, "error"));
  //   return false;
  // }

  yield put(openFeedbackModalAction("Arquivo enviado com sucesso", "success"));
  yield put(refreshCollaboratorsDataAction());
}

function* submitEndorsement(action: any): any {
  const store = yield select();
  const collaboratorId = store.collaboratorsReducer.endorsementPanel.id;

  yield put(closeEndorsementPanelAction());
  yield put(openModalLoading());

  const http = new Http();
  const response = yield http.httpClientPost({
    url: `empresa/emprestimopessoal/informaraverbacao`,
    data: {
      func_id: collaboratorId,
      averbacao: CurrencyUtilities.removeCurrencyFormatting(action.amount),
    },
  });

  yield put(closeModalLoading());

  if (response.error) {
    yield put(openFeedbackModalAction(response.message, "error"));
    return false;
  }

  yield put(
    openFeedbackModalAction("Averbação informada com sucesso!", "success")
  );

  yield put(refreshCollaboratorsDataAction());
}

function* uploadShutdownWorksheet(action: any): any {
  yield put(closeCollaboratorModalShutdowWorksheet());
  yield put(openModalLoading());

  const http = new Http();

  var formData = new FormData();
  formData.append("arquivo", action.worksheet);

  const response = yield http.httpClientPost({
    url: "empresa/funcionario/desligalote",
    data: formData,
    responseType: "arraybuffer",
  });

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  const date = new Date().toLocaleDateString("en-CA");
  const time = new Date().toLocaleTimeString("en-CA");

  fileDownload(
    response,
    `Desligamento InMano (output) - ${date} ${time}.xlsx`,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );

  yield put(closeModalLoading());
  yield put(
    openFeedbackModalAction(
      "Documento enviado! Confira a alteração na listagem de colaboradores",
      "success"
    )
  );
  yield put(refreshCollaboratorsDataAction());
}

function* submitShutdownList(action: any): any {
  yield put(openModalLoading());

  const newArray: any = [];
  action.list.map((item: any) => {
    newArray.push(item.value);
  });

  const http = new Http();

  const response = yield http.httpClientPost({
    url: `empresa/funcionario/desligalista`,
    data: {
      funcionarios: GeneralUtilities.getAListFromAnArrayOfNumbers(newArray),
    },
  });

  yield put(closeModalLoading());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  if (response.tem_emprestimo)
    return yield put(openFeedbackModalAction(response.mensagem, "warning"));

  yield put(
    openFeedbackModalAction("Colaboradores desligados com sucesso!", "success")
  );

  yield put(refreshCollaboratorsDataAction());
}

function* submitCollaborator(): any {
  const store = yield select();
  const data = store.collaboratorsReducer.dataPanel.data;
  const hasMargin = store.collaboratorsReducer.hasMargin;
  const hasAnEndDateOfTheContract =
    store.collaboratorsReducer.hasAnEndDateOfTheContract;

  yield put(closeCollaboratorDataPanelAction());
  yield put(openModalLoading());

  let formData: any = {
    ...data,
    func_dtnascimento: UtilitiesDate.formatStringDateToDefaultCanadian(
      data.func_dtnascimento
    ),
    func_dtadmissao: UtilitiesDate.formatStringDateToDefaultCanadian(
      data.func_dtadmissao
    ),
    func_dtfimcontrato: hasAnEndDateOfTheContract
      ? UtilitiesDate.formatStringDateToDefaultCanadian(data.func_dtfimcontrato)
      : null,
    func_salariobruto: CurrencyUtilities.removeCurrencyFormatting(
      data.func_salariobruto
    ),
    func_salarioliquido: CurrencyUtilities.removeCurrencyFormatting(
      data.func_salarioliquido
    ),
    func_margem: hasMargin
      ? CurrencyUtilities.removeCurrencyFormatting(data.func_margem)
      : null,
  };

  if (data.func_dtfimcontrato === "") {
    delete formData.func_dtfimcontrato;
  }

  const http = new Http();

  if (!data.func_id) {
    var response = yield http.httpClientPost({
      url: "empresa/funcionario",
      data: formData,
    });
  } else {
    response = yield http.httpClientPut({
      url: `empresa/funcionario/${data.func_id}`,
      data: formData,
    });
  }

  yield put(closeModalLoading());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(
    openFeedbackModalAction(
      `Colaborador ${!data.func_id ? "criado" : "editado"} com sucesso!`,
      "success"
    )
  );

  yield put(refreshCollaboratorsDataAction());
}

export default all([
  takeLatest(CHANGE_EMPLOYEE_STATUS, changeEmployeeStatus),
  takeLatest(SUBMIT_UPLOAD_WORKSHEET, uploadCollaboratorsUpdateWorksheet),
  takeLatest(SUBMIT_ENDORSEMENT, submitEndorsement),
  takeLatest(SUBMIT_SHUTDOWN_LIST, submitShutdownList),
  takeLatest(SUBMIT_SHUTDOWN_WORKSHEET, uploadShutdownWorksheet),
  takeLatest(SUBMIT_COLLABOARATOR, submitCollaborator),
]);
