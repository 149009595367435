import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Login from "pages/Login";
import Dashboard from "pages/Dashboard";
import Collaborators from "pages/Collaborators";
import Requests from "pages/Requests";
import Conciliations from "pages/Conciliations";
import Administrators from "pages/Administrators";
import ConciliationResume from "pages/Conciliations/ConciliationResume";
import Contracts from "pages/Contracts";

export default function Router() {
  const PrivateRouter = () => {
    const data = sessionStorage.getItem("@userData");
    return data ? <Outlet /> : <Navigate to="/" />;
  };
  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route element={<PrivateRouter />}>
        <Route path="/home" element={<Dashboard />}></Route>
        <Route path="/colaboradores" element={<Collaborators />}></Route>
        <Route path="/solicitacoes" element={<Requests />}></Route>
        <Route path="/contratos" element={<Contracts />}></Route>
        <Route path="/conciliacoes" element={<Conciliations />}></Route>
        <Route path="/conciliacoes/resumo" element={<ConciliationResume />}></Route>
        <Route path="/administradores" element={<Administrators />}></Route>
      </Route>
    </Routes>
  );
}
