import { Table } from "layout/Table/styled";
import IShutdownRow from "interfaces/interface.shutdown-row";
import RowShutdownCollaborator from "./RowShutdownCollaborator";

export default function TableShutdownResume({
  data: disconects,
}: {
  data: IShutdownRow[];
}) {
  return (
    <div
      style={{
        maxHeight: "30rem",
        overflowY: "scroll"
      }}
    >
      <Table hover responsive>
        <thead>
          <tr>
            <th className="table-text">Nome do colaborador(a)</th>
            <th className="table-text">CPF</th>
            <th className="table-text">Status</th>
          </tr>
        </thead>
        <tbody>
          {disconects.map((item, key) => (
            <RowShutdownCollaborator key={key} portion={item} />
          ))}
        </tbody>
      </Table>
    </div>
  );
}
