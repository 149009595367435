import { useAppSelector } from "hooks/hooks.redux";

import Presentation from "./Presentation";
import Dialog from "./Dialog";

export default function RequestStatus() {
  const { requestStatus } = useAppSelector((state) => state.requestsReducer);

  return (
    <>
      {requestStatus?.status.ppst_status === 0 ? <Dialog /> : <Presentation />}
    </>
  );
}
