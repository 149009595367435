import { ReactNode } from "react";
import "./style.css";
import Footer from "components/Footer";
import Header from "components/Header";
import Navbar from "components/Navbar";
import DesktopSidebar from "components/Sidebar/DesktopSidebar";
import MobileSidebar from "components/Sidebar/MobileSidebar";
import Help from "./Components/Help";

interface IProps {
  children: ReactNode;
  titlePage: string;
  description?: string;
}

export default function DefaultBody({
  children,
  description,
  titlePage,
}: IProps) {
  return (
    <div className="container-fluid vh-100">
      <Navbar />
      <div className="row h-100">
        <div className="col-12 body-content position-relative d-flex flex-row">
          <DesktopSidebar />
          <MobileSidebar />
          <div className="row body-main">
            <main className="col-12 body-main-container">
              <Header description={description} titlePage={titlePage} />
              {children}
            </main>
            <Footer />
          </div>
          <Help />
        </div>
      </div>
    </div>
  );
}
