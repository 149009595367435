import Skeleton from 'react-loading-skeleton';
import './style.css'

export default function Animated() {
  return (
    <button className="dashboard-indicator-container w-100">
      <Skeleton width={"15rem"} height="5rem" />
      <div className='dashboard-indicator-separator'/>
      <Skeleton width={"10rem"} height="1.5rem" />
    </button>
  );
}