import TableConciliationDueCollaborator from "./TableConciliationDueCollaborator";
import TableConciliationShutdownCollaborator from "./TableConciliationShutdownCollaborator";

type IProps = {
  option: number,
  data: any
}

export default function TableInstallments({ option, data }: IProps) {
  const Tables = [ <TableConciliationDueCollaborator data={data} />, <TableConciliationShutdownCollaborator data={data} />]

  return Tables[option - 1]
}