import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AppContextProvider } from "context";
import Router from "routes";

import { useAppDispatch } from "hooks/hooks.redux";
import { myTheme } from "styles/default-theme";

import "react-loading-skeleton/dist/skeleton.css";
import "styles/theme.css";
import "styles/reset.css";
import "styles/default-styles.css";
import "styles/default-style-table.css";
import "styles/utilities-text-roboto-style.css";
import "styles/utilities-text-nunito-style.css";
import "styles/utilities-text-montserrat-style.css";

import { clearStateAction } from "model/actions/action.general";
import ModalLoading from "layout/ModalLoading";
import { FeedbackModal } from "components/FeedbackModal";
import { ModalNotification } from "components/ModalNotification/ModalNotification";
import DisplayCollaborator from "pages/Collaborators/components/DisplayCollaborator";
import AdministratorPanel from "pages/Administrators/components/AdministratorPanel";
import CollaboratorPanel from "pages/Collaborators/components/CollaboratorPanel";
import EndorsementPanel from "pages/Collaborators/components/EndorsementPanel";
import RequestStatus from "pages/Requests/components/RequestStatus";
import ModalProfile from "pages/Settings";
import DisplayContract from "pages/Contracts/components/DisplayContract";

function App() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearStateAction());
  }, [location.pathname]);

  return (
    <ThemeProvider theme={myTheme}>
      <AppContextProvider>
        <Router />
        <RequestStatus />
        <AdministratorPanel />
        <FeedbackModal />
        <DisplayCollaborator />
        <CollaboratorPanel />
        <EndorsementPanel />
        <ModalProfile />
        <ModalNotification />
        <ModalLoading />
        <DisplayContract />
      </AppContextProvider>
    </ThemeProvider>
  );
}

export default App;
