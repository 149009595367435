import { useSearchCollaborator } from "hooks/hook.useSearchCollaborator";
import ISelectOptions from "interfaces/interface.select-options";
import Select from "./Select";
import {useState, useRef} from "react";

export default function CollaboratorsSelect({
  onChange,
}: {
  onChange: (event: any) => void;
}) {
  const { emitEvent, data, reset } = useSearchCollaborator();
  const [hasFocus, setHasFocus] = useState(false)
  
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  function pushItem(item: ISelectOptions) {
    onChange(item);
    reset();
  }
  
  function handleFocus(focusType: "out" | "in"){
    if(focusType === "out"){
      setHasFocus(false)
    }else{
      setHasFocus(true)
    }
  }
  
  function onBlur(){
    setTimeout(reset, 200)
    handleFocus("out")
    if(inputRef.current){
      inputRef.current.value = "";
    }
    
  }
  
  return (
    <Select>
      <div 
        ref={ref}         
        onBlur={onBlur}
      >
      <Select.Input
        ref={inputRef}
        placeholder="Nome ou CPF (000.000.000-00)"
        onFocus={() => handleFocus("in")}
        onChange={emitEvent}
      />
      <Select.Menu>
      
        {
          data.length === 0 && hasFocus ?

            <Select.Option>
              Nenhum resultado encontrado
            </Select.Option>
          :
            data.map((item, key) => (
              <Select.Option key={key} onClick={() => pushItem(item)}>
                {item.label}
              </Select.Option>
            ))            
        }
      </Select.Menu>

      </div>
          </Select>
  );
}
