import { Children } from "react";
import styled from "styled-components";

export const SelectInput = styled.input`
  height: 4rem;
  width: 100%;

  padding: 0.4rem 1.2rem;
  margin-bottom: 1rem;

  background: ${({ theme }) => theme.COLORS.WHITE};
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY_200};
  border-radius: 3rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 8.4rem;

  color: ${({ theme }) => theme.COLORS.BLUE_700};

  &::placeholder {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 3.7rem;

    color: ${({ theme }) => theme.COLORS.GRAY_500};
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  max-height: 40rem;

  position: absolute;
  z-index: 99;
  overflow-y: scroll;

  display: ${(props) => (Children.count(props.children) > 0 ? "flex" : "none")};
  flex-direction: column;
  gap: 1rem;

  background: ${({ theme }) => theme.COLORS.WHITE};
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY_200};
  border-radius: 2rem;
`;

export const SelectOption = styled.span`
  padding: 0.4rem 1.2rem;

  cursor: pointer;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.8rem;
  color: ${({ theme }) => theme.COLORS.BLUE_700};

  transition: all ease-in-out 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.BLUE_700};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;
