export default class ListById {
  //Método para descongelar o array
  static unfreeze(list: any[]) {
    return JSON.parse(JSON.stringify(list));
  }

  //Método para remover um item do array
  private static removeIdFromList(index: number, list: any[], id: number) {
    return list[index].filter((item: any) => item !== id);
  }

  //Método para manipular o array
  static changeList(id: number, currentPage: number, currentList: any[]) {
    let list = ListById.unfreeze(currentList);

    //Verifica se o id está no sub-array referente a página atual
    if (list[currentPage] && list[currentPage].includes(id)) {
      //Caso esteja incluso na lista, o id é removido
      list[currentPage] = ListById.removeIdFromList(currentPage, list, id);
    } else {
      //Caso nãocesteja incluso na lista, é verificado se ja existe uma lista na página atual
      if (list[currentPage] && list[currentPage].length)
        //Caso ja exista lista, o id adicionado ao fim do sub-array
        list[currentPage].push(id);
      else list[currentPage] = [id]; //Se não um sub-array é criado e o id adicionado
    }

    return list;
  }

  static addAllIds(
    data: any[],
    currentPage: number,
    currentList: any[],
    key: string
  ) {
    let list = ListById.unfreeze(currentList);

    const idsList = data.map(function (item) {
      return item[key];
    });

    list[currentPage] = idsList;

    return list;
  }

  //Método para remover todos os items de um indicie
  static removeAllIds(currentPage: number, currentList: any[]) {
    let list = ListById.unfreeze(currentList);
    list[currentPage] = null;

    return list;
  }

  //Método para verificar se um indicie está totalmente populado
  static checkIfAIndexIsFullyPopulated(
    currentPage: number,
    currentList: any[],
    total: number
  ) {
    let list = ListById.unfreeze(currentList);

    if (!list[currentPage]) return false;

    return list[currentPage].length === total;
  }

  //Método para obter um array linear de ids
  static getLinearListOfIds(list: any[]) {
    let lits = list.filter(function (item) {
      if (item) return item;
    });

    let idLits: any[] = [];
    lits.forEach((element) => {
      idLits = [...idLits, ...element];
    });

    return idLits;
  }
}
