import { ReactNode } from "react";
import { Container } from "./styled";
import InmanoColorfulIcon from "assets/img/inmano-colorful-icon.svg";
import "./style.css";

interface Iprops {
  title?: string;
  message?: string;
  children: ReactNode;
  error?: boolean;
}

export default function Box({ message, title, children, error }: Iprops) {
  return (
    <Container error={error} className="containter-fluid d-flex flex-column">
      <img
        src={InmanoColorfulIcon}
        alt="Logo colorida da Inmano"
        className="login-form-inmano-icon"
      />
      {title  && (
        <h1 className="login-form-title text-roboto-fs30-lh35-fw400-blue700">
          {title}
        </h1>
      )}
      <span className="login-form-message text-roboto-fs16-lh18-fw400-blue700">
        {message}
      </span>
      {children}
    </Container>
  );
}
