import produce from "immer";
import {
  OPEN_MODAL_LOADING,
  CLOSE_MODAL_LOADING,
} from "model/action-types/type.modal-loading";
import { AnyAction } from "redux";

const INITIAL_STATE = {
  isOpen: false as boolean,
};

export function modalLoadingReducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case OPEN_MODAL_LOADING:
      return produce(state, (draft) => {
        draft.isOpen = true;
      });
    case CLOSE_MODAL_LOADING:
      return produce(state, (draft) => {
        draft.isOpen = false;
      });
    default:
      return state;
  }
}
