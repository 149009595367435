import { Table } from "layout/Table/styled";
import RowCollaborator from "./Rows/RowCollaborator";
import ICollaborators from "interfaces/http-response/interface.collaborators";

type IProps = {
  data: ICollaborators[];
};

export default function TableCollaborator({ data }: IProps) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th className="table-text">Nome do colaborador(a)</th>
          <th className="table-text">CPF</th>
          <th className="table-text">CNPJ</th>
          <th className="table-text">Status</th>
          <th className="table-text">Possui empréstimo</th>
          <th className="table-text">Ações</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, key) => (
          <RowCollaborator key={key} collaborator={item} />
        ))}
      </tbody>
    </Table>
  );
}
