const {
  REACT_APP_INMANO_API_VERSION,
  REACT_APP_INMANO_API_TYPE,
  REACT_APP_INMANO_API_URL_PRD,
  REACT_APP_INMANO_API_URL_DEV,
} = process.env;

export const apiVersion = REACT_APP_INMANO_API_VERSION;

export const url =
  REACT_APP_INMANO_API_TYPE === "production"
    ? `${REACT_APP_INMANO_API_URL_PRD}/api/v${apiVersion}/`
    : `${REACT_APP_INMANO_API_URL_DEV}/api/v${apiVersion}/`;
