import styled, { css, keyframes } from "styled-components";
import Button from "components/Button";

const opacityKeyframes = keyframes`
  from {
    opacity: 0;
  }
`;

export const ContianerIconFeedbackModal = styled.div`
  animation: ${opacityKeyframes} 1s linear backwards;
`;

export const ParagraphyFeedbackModal = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: ${(props) => props.theme.COLORS.BLUE_700};
  text-align: center;
  opacity: 1;
  animation: ${opacityKeyframes} 1s linear backwards;
`;

export const ButtonFeedbackModal = styled(Button)`
  opacity: 1;
  animation: ${opacityKeyframes} 1s linear backwards;
  margin-top: 2.5rem;
`;
