import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import Button from "components/Button";
import { Col, Row } from "react-bootstrap";

export default () => {
  const navigate = useNavigate();

  return (
    <Row className="row">
      <Col md={10} className="gy-3 d-flex flex-row gap-5">
        <div className="d-flex flex-column gap-2">
          <span className="text-roboto-fs14-lh16-fw700-blue700">
            <Skeleton height={15} />
          </span>
          <span className="text-roboto-fs20-lh29-fw400-blue600">
            <Skeleton height={25} />
          </span>
        </div>
        <div className="d-flex flex-column gap-2">
          <span className="text-roboto-fs14-lh16-fw700-blue700">
            <Skeleton height={15} />
          </span>
          <span className="text-roboto-fs20-lh29-fw400-blue600">
            <Skeleton height={25} />
          </span>
        </div>
        <div className="d-flex flex-column gap-2">
          <span className="text-roboto-fs14-lh16-fw700-blue700">
            <Skeleton height={15} />
          </span>
          <span className="text-roboto-fs20-lh29-fw400-blue600">
            <Skeleton height={25} />
          </span>
        </div>
        <div className="d-flex flex-column gap-2">
          <span className="text-roboto-fs14-lh16-fw700-blue700">
            <Skeleton height={15} />
          </span>
          <span className="text-roboto-fs20-lh29-fw400-blue600">
            <Skeleton height={25} />
          </span>
        </div>
      </Col>
      <Col md={2} className="gy-3 d-flex justify-content-end alig-items-center">
        <Button
          variant="blue"
          className="text-montserrat-fs16-lh19-fw400-white w-100"
          onClick={() => navigate("/conciliacoes")}
        >
          Lista geral
        </Button>
      </Col>
    </Row>
    // <div className="row">
    //     <div className="col-md-2 gy-3">
    //         <div className="d-flex flex-column gap-2">
    //             <span className="text-roboto-fs14-lh16-fw700-blue700">
    //                 <Skeleton height={15} />
    //             </span>
    //             <span className="text-roboto-fs20-lh29-fw400-blue600">
    //                 <Skeleton height={25} />
    //             </span>
    //         </div>
    //     </div>
    //     <div className="col-md-3 gy-3">
    //         <div className="d-flex flex-column gap-2">
    //             <span className="text-roboto-fs14-lh16-fw700-blue700">
    //                 <Skeleton height={15} />
    //             </span>
    //             <span className="text-roboto-fs20-lh29-fw400-blue600">
    //                 <Skeleton height={25} />
    //             </span>
    //         </div>
    //     </div>
    //     <div className="col-md-5 gy-3">
    //         <div className="d-flex flex-column gap-2">
    //             <span className="text-roboto-fs14-lh16-fw700-blue700">
    //                 <Skeleton height={15} />
    //             </span>
    //             <span className="text-roboto-fs20-lh29-fw400-blue600">
    //                 <Skeleton height={25} />
    //             </span>
    //         </div>
    //     </div>
    //     <div className="col-md-2 gy-3 d-flex justify-content-end alig-items-center">
    //         <Button
    //             variant="blue"
    //             className="text-montserrat-fs16-lh19-fw400-white w-100"
    //             onClick={() => navigate("/conciliacoes")}
    //         >
    //             Lista geral
    //         </Button>
    //     </div>
    // </div>
  );
};
