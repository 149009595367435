import { Table } from "layout/Table/styled";
import IConciliation from "interfaces/http-response/interface.conciliation";
import RowConciliationGroups from "./Rows/RowConciliationGroups";

export default function TableConciliationGroup({
  data: conciliations,
}: {
  data: IConciliation[];
}) {
  return (
      <Table responsive hover>
        <thead>
          <tr>
            <th className="table-text">Nome Fantasia</th>
            <th className="table-text">CNPJ</th>
            <th className="table-text">Valor total</th>
            <th className="table-text">N° de parcelas</th>
            <th className="table-text">Tipo de conciliação</th>
            <th className="table-text">Status</th>
          </tr>
        </thead>
        <tbody>
          {conciliations.map((item, key) => (
            <RowConciliationGroups key={key} conciliation={item} />
          ))}
        </tbody>
      </Table>
  );
}
