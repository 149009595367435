import { AnyAction } from "redux";
import produce from "immer";
import {
  SEARCH_CONCILIATION_HISTORY,
  SEARCH_CONCILIATION_PORTION,
  SEARCH_CURRENT_CONCILIATIONS,
  SEARCH_RESUME_CONCILIATION,
  SET_CONCILIATION_PORTION,
  SET_CONCILIATIONS,
  SET_RESUME_CONCILIATION,
  SET_SEARCH_TYPE_CONCILIATION,
  OPEN_CONCILIATION_CONTESTATION_PANEL,
  CLOSE_CONCILIATION_CONTESTATION_PANEL,
} from "model/action-types/type.conciliations";
import {
  CONCILIATION_DOWNLOAD_TICKET,
  EXPORT_INSTALLMENTS_WORKSHEET_CONCILIATION,
  SUBMIT_CONCILIATION,
  SUBMIT_CONCILIATION_CONSTETATION,
} from "model/action-types/type.conciliations-submit";
import { CLEAR_STATE } from "model/action-types/type.general";
import { PropsConciliationReducer } from "model/reducers-types/type.conciliationReducer";

const INITIAL_STATE: PropsConciliationReducer = {
  lastPage: 0,
  currentPage: 0,
  total: 0,
  companyId: 0,
  conciliation: [],
  resumeConciliation: {
    dados_conciliacao: {
      emp_nomefantasia: "",
      emp_cnpj: "",
      valor_total: 0,
      total_parcelas: 0,
      conci_ano: 0,
      conci_id: 0,
      conci_mes: 0,
      status: { valor: 0, label: "" },
      tipo: { valor: 0, label: "" },
    },
    dados_boleto: {
      valor: "",
      data_criacao: "",
      data_vencimento: "",
      status: { valor: 0, label: "" },
    },
  },
  portion: [],
  showConstestationPanel: false,
  searchType: 0,
};

export function conciliationsReducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case SEARCH_CURRENT_CONCILIATIONS:
      return state;
    case SET_CONCILIATIONS:
      return produce(state, (draft) => {
        draft.conciliation = action.data.data;
        draft.currentPage = action.data.current_page;
        draft.lastPage = action.data.last_page;
        draft.total = action.data.total;
      });
    case SEARCH_RESUME_CONCILIATION:
      return state;
    case SET_RESUME_CONCILIATION:
      return produce(state, (draft) => {
        draft.resumeConciliation = action.data;
      });
    case SEARCH_CONCILIATION_PORTION:
      return state;
    case SET_CONCILIATION_PORTION:
      return produce(state, (draft) => {
        draft.portion = action.data.data;
        draft.currentPage = action.data.current_page;
        draft.lastPage = action.data.last_page;
        draft.total = action.data.total;
      });
    case SUBMIT_CONCILIATION:
      return state;
    case EXPORT_INSTALLMENTS_WORKSHEET_CONCILIATION:
      return state;
    case SEARCH_CONCILIATION_HISTORY:
      return state;
    case OPEN_CONCILIATION_CONTESTATION_PANEL:
      return produce(state, (draft) => {
        draft.showConstestationPanel = true;
      });
    case CLOSE_CONCILIATION_CONTESTATION_PANEL:
      return produce(state, (draft) => {
        draft.showConstestationPanel = false;
      });
    case SUBMIT_CONCILIATION_CONSTETATION:
      return state;
    case CONCILIATION_DOWNLOAD_TICKET:
      return state;
    case SET_SEARCH_TYPE_CONCILIATION:
      return produce(state, (draft) => {
        draft.searchType = action.searchType;
      });
    case CLEAR_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
}
