import { ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";

import HorizontalSeparator from "layout/HorizontalSeparator/styled";
import Modal from "components/Modal";

import CloseIcon from "assets/img/close-icon.svg";
import "./style.css";

interface IProps {
  children: ReactNode;
  title: string;
  show: boolean;
  onClose: () => void;
}

export default function BodyPanel({ children, title, show, onClose }: IProps) {
  return (
    <Modal show={show}>
      <Container fluid className="body-panel">
        <Row>
          <Col md={12} className="d-flex flex-row justify-content-between align-items-center gap-2">
            <h1 className="text-roboto-fs30-lh30-fw400-blue700">{title}</h1>
            <button className="bg-transparent" onClick={onClose}>
              <img src={CloseIcon} alt="" />
            </button>
          </Col>
          <HorizontalSeparator />
        </Row>
        <Row>
          <Col md={12}>{children}</Col>
        </Row>
      </Container>
    </Modal>
  );
}
