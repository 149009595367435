export class UserUtilities {
  static isAuthorized(
    permission: [1, 2, 3] | 1 | 2 | 3 | [1, 2] | [2, 3] | [1, 3]
  ): boolean | Array<boolean> {
    const data = JSON.parse(sessionStorage.getItem("@userData") as string);

    const userPermissions = data.funcoes;

    let userPermissionsList: number[] = [];
    userPermissions.map((item: any) => {
      userPermissionsList.push(item.rhfun_id);
    });

    if (Array.isArray(permission)) {
      let authorization = false;
      permission.forEach((element) => {
        if (userPermissionsList.includes(element))
          return (authorization = true);
      });
      return authorization;
    }

    return userPermissionsList.includes(permission);
  }

  static getNotifySetting() {
    const data = JSON.parse(sessionStorage.getItem("@userData") as string);

    const { rhnot_id } = data.usuario;

    return rhnot_id;
  }

  static getUserAttribute() {
    const data = JSON.parse(sessionStorage.getItem("@userData") as string);
    return data.usuario;
  }
}
