import FileIcon from "assets/img/file-icon.svg";
import RemoveValueIcon from "assets/img/remove-value-icon.svg";
import IDiv from "interfaces/html/interface.div";

type IProps = IDiv & { files: File[], onRemove: () => void }

export default function ListFiles({ files, onRemove, ...rest }: IProps) {
  return (
    <div className="d-flex flex-column gap-2 h-100 w-100" {...rest}>
      {files?.map((item, key) => (
        <div
          key={key}
          className="d-flex flex-row align-items-center gap-2 px-4"
        >
          <img src={FileIcon} alt="" className="upload-file-icon" />
          <span className="text-roboto-fs14-lh16-fw400-blue700">
            {item.name}
          </span>
          <button className="bg-transparent ms-auto" onClick={onRemove}>
            <img src={RemoveValueIcon} alt="" />
          </button>
        </div>
      ))}
    </div>
  );
}
