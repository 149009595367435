import { DialogModal } from "components/DialogModal";
import { useAppDispatch } from "hooks/hooks.redux";
import { approveARequestListAction } from "model/actions/action.request-submit";

type IProps = {
    close: () => void;
    show: boolean;
}

export default function AlerApproveInBatch({ show, close }: IProps) {
    const dispatch = useAppDispatch()


    function approveRequests() {
        close()
        dispatch(approveARequestListAction());
    }

    return <DialogModal action={approveRequests} close={close} message="Você confirma a ação de aprovar em lote?" show={show} />
}