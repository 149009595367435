import Animated from './Animated';
import './style.css'

interface IProps {
  activeCollaborators: number | string;
  activeContracts: number | string;
  isLoading: boolean
}

export default function AssetView({ activeCollaborators, activeContracts, isLoading }: IProps) {
  if (isLoading) return <Animated />

  return (
    <div className="dashboard-managed-values-container d-flex flex-column">
      <span className="text-roboto-fs50-lh30-fw400-orange500">
        <br />
        {activeCollaborators}
      </span>
      <span className="text-roboto-fs16-lh19-fw700-blue600 mt-4">
        Colaboradores ativos
      </span>
      <span className="text-roboto-fs12-lh14-fw400-blue600 mt-1">
        Todos os colaboradores ativos no momento
      </span>
      <hr />
      <span className="text-roboto-fs30-lh30-fw400-pink400" style={{
        fontSize: 38
      }}>
        <br />
        {activeContracts}
      </span>
      <span className="text-roboto-fs16-lh19-fw700-blue600 mt-2">
        {/* Solicitações ativ */}
        Contratos em aberto
      </span>
      <span className="text-roboto-fs12-lh14-fw400-blue600 mt-1">
        Número de contratos em aberto no momento
      </span>
    </div>
  );
}
