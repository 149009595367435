import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";
import TableShutdownResume from "./TableShutdownResume";
import IShutdownRow from "interfaces/interface.shutdown-row";
import IShutdownSheetResponse from "interfaces/interface.shutdown-sheet-response";

import Button from "components/Button";
import Modal from "components/Modal";
import Upload from "components/Upload";
import Text from "components/new/Text";

import { closeCollaboratorModalShutdowWorksheet, getWorksheetShutdownModelAction } from "model/actions/action.collaborators";
import { submitShutdownWorksheetAction } from "model/actions/action.collaborators-submit";

import HelperShutdownSheet from "helpers/helper.shutdown-sheet";

import CloseIcon from "assets/img/close-icon.svg";
import "./style.css";

export default function ShutdownWorksheet() {
  const dispatch = useAppDispatch();
  const {
    shudownModal: { show },
  } = useAppSelector((state) => state.collaboratorsReducer);

  const [files, setFiles] = useState<File[]>([]);
  const [shutDowList, setShutDowList] = useState<IShutdownRow[]>([]);
  const [canSend, setCanSend] = useState(false);
  const [errorCounts, setErrorCounts] = useState(0);
  const [warningMessage, setWarningMessage] = useState("");
  const [warningMessageColor, setWarningMessageColor] = useState("");

  function defineFiles(files: File[]) {
    if(files.length === 0 ){
      setCanSend(false);
      setFiles(files);
      setShutDowList([]);
      setErrorCounts(0);
      setWarningMessage("");
      setWarningMessageColor("");
      return ;
    } 
    let reader = new FileReader();

    reader.onload = function(e) {
      const data = e.target?.result
      if (data instanceof ArrayBuffer) {
        let shutDowSheetResponse: IShutdownSheetResponse = HelperShutdownSheet.extractDataFromSheet(data)
        let errors = shutDowSheetResponse.shutdowArr.length
        setShutDowList(shutDowSheetResponse.shutdowArr);
        setErrorCounts(errors)
        setCanSend(shutDowSheetResponse.canShutdown)
        setWarningMessage(shutDowSheetResponse.warningMessage.value);
        setWarningMessageColor(shutDowSheetResponse.warningMessage.color);
      }else{
        setCanSend(false);
        setShutDowList([]);
        setErrorCounts(0)
      } 
    };

    reader.onerror = function(ex) {
      console.log(ex);
      return;
    };

    reader.readAsArrayBuffer(files[0])
    setFiles(files);
  }

  function onClose() {
    dispatch(closeCollaboratorModalShutdowWorksheet());
  }

  function submitShutdownWorksheet() {
    dispatch(submitShutdownWorksheetAction(files[0]));
    onClose()
  }

  function getWorksheetShutdownModel() {
    dispatch(getWorksheetShutdownModelAction());
  }

  return (
    <Modal show={show}>
      <Container  className="container-modal-lg d-block p-5">
        <Row className="row">
          <Col md={10} className="d-flex align-items-center">
            <h1 className="text-roboto-fs20-lh23-fw400-blue700">
              Desligar em Lote
            </h1>
          </Col>
          <Col md={2} className=" d-flex align-items-center justify-content-end">
            <button className="bg-transparent close-button-shutdown-worksheet" onClick={onClose}>
              <img src={CloseIcon} alt="" />
            </button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Upload
              onChange={(e) => defineFiles(e)}
              dragDropAreaProps={{
                className: "drag-drop-shutdown-worksheet"
              }}
              fileListingAreaProps={{
                className: "drag-drop-shutdown-worksheet"
              }}
            />
          </Col>
        </Row>
        {
         errorCounts > 0 ?
          <TableShutdownResume data={shutDowList}/>
          :
          null
        }
        
        <Text color={warningMessageColor} style={{marginTop: "1.8rem"}}>
        {warningMessage}
        </Text>
        <Row className="mt-3">
          <Col md={12} className="d-flex flex-wrap gap-3 justify-content-between">
            <Button
              variant="green"
              disabled={!(canSend)}
              className="text-roboto-fs12-lh14-fw400-white px-5"
              onClick={submitShutdownWorksheet}
            >
              Enviar
            </Button>
            <Button
              variant="pink"
              className="text-roboto-fs12-lh14-fw400-white px-5"
              onClick={getWorksheetShutdownModel}
            >
              Baixar modelo
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
