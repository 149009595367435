import { object, string, number, array } from "yup";

export const administratorCreateSchema = object({
  name: string()
    .required("É necessário informar o nome do administador.")
    .min(1, "Informe o nome completo do administrador."),
  email: string()
    .email("Informe um endereço de e-mail váldio.")
    .required("É necessário informar o email do administador.")
    .min(1, "Informe um endereço de e-mail váldio."),
  password: string()
    .required("É necessário cadastrar uma senha para o administador.")
    .min(6, "A senha deve conter no mínimo 6 caracteres"),
  functions: array()
    .required("É necessário atribuir uma função ao administador.")
    .min(1, "É necessário atribuir pelo menos uma função ao administador."),
  // notify: number().required(),
  status: number().required(),
  companies: array()
    .required("É necessário vincular o administador a alguma empresa.")
    .min(1, "É necessário vincular o administador a alguma empresa."),
});

/**
 * name = /[a-z]{1,}/gi
 * email = /\w+\@[a-z]+\.[a-z]+/g
 * password = ~/[\D\d]{6,}/gi
 * functions = /[\[\d\,\]]/g ou /\d{1,}/
 * status = /\d+/
 * companies = /\d{1,}/
 */
