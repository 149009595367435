import { Table } from "layout/Table/styled";
import IConciliationDueInstallments from "interfaces/interface.conciliation-due-installments";
import RowConciliationDueCollaborator from "./Rows/RowConciliationDueCollaborator";

export default function TableConciliationDueCollaborator({
  data: installments,
}: {
  data: IConciliationDueInstallments[];
}) {
  return (
      <Table responsive hover>
        <thead>
          <tr>
            <th className="table-text">Nº do contrato</th>
            <th className="table-text">Nome do colaborador(a)</th>
            <th className="table-text">CPF</th>
            <th className="table-text">N° Parcela</th>
            <th className="table-text">Prazo</th>
            <th className="table-text">Valor da parcela</th>
          </tr>
        </thead>
        <tbody>
          {installments.map((item, key) => (
            <RowConciliationDueCollaborator key={key} portion={item} />
          ))}
        </tbody>
      </Table>
  );
}
