import { all, put, takeLatest } from "redux-saga/effects";
import Http from "services/httpClient";
import { SUBMIT_FAQ } from "model/action-types/type.dashboard";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";
import {
  closeModalLoading,
  openModalLoading,
} from "model/actions/action.modal-loading";

function* submitFaq(action: any): any {
  yield put(openModalLoading());

  const http = new Http();

  const response = yield http.httpClientPost({
    url: "empresa/faqsugestaorh",
    data: {
      fsrh_pergunta: action.message,
    },
  });

  yield put(closeModalLoading()); 

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(
    openFeedbackModalAction("Sua dúvida foi enviada com sucesso!", "success")
  );
}

export default all([takeLatest(SUBMIT_FAQ, submitFaq)]);
