import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import List from "layout/DisplayItemTable/List";
import BodyPanel from "components/BodyPanel";
import DataCard from "components/DataCard";

import { UtilitiesDate } from "utils/utilities.date";
import CurrencyUtilities from "utils/currencyFormatting";
import ICollaborators from "interfaces/http-response/interface.collaborators";

import { closeCollaboratorsDisplayPanelAction } from "model/actions/action.collaborators";

export default function DisplayCollaborator() {
  const dispatch = useAppDispatch();
  const { detailPanelCollaborator } = useAppSelector(
    (state) => state.collaboratorsReducer
  );

  const collaborator = detailPanelCollaborator.data as ICollaborators;

  if (!collaborator.func_id) return <></>;

  const grossSalary = CurrencyUtilities.formatCurrencyToBrazilianStandard(
    collaborator.func_salariobruto as number
  );
  const netSalary = CurrencyUtilities.formatCurrencyToBrazilianStandard(
    collaborator.func_salarioliquido as number
  );

  const commitmentLimit = CurrencyUtilities.formatCurrencyToBrazilianStandard(
    collaborator.func_limitecomprometimento as number
  );

  const maximumAmountOfInstallment =
    CurrencyUtilities.formatCurrencyToBrazilianStandard(
      collaborator.vlr_maximo_parcela as number
    );

  const [birthDate, admissionDate, shutdownDate] =
    UtilitiesDate.formatListOfStringDateToDefaultBrazilian([
      collaborator.func_dtnascimento as string,
      collaborator.func_dtadmissao as string,
      collaborator.func_dtdesligamento || "",
    ]);

  function onClose() {
    dispatch(closeCollaboratorsDisplayPanelAction());
  }

  return (
    <BodyPanel
      show={detailPanelCollaborator.show}
      title="Detalhes do colaborador"
      onClose={onClose}
    >
      <Container fluid>
        <Row className="row">
          <Col md={5} className="d-flex flex-row flex-wrap ">
            <div className="flex-grow-1">
              <List label="Nome Completo" value={collaborator.func_nome} />
              <List
                label="E-mail"
                value={collaborator.func_email || "Sem informação"}
              />
              <List label="CPF" value={collaborator.func_cpf} />
              <List label="Data de Nascimento" value={birthDate} />
              <List
                label="Matrícula"
                value={collaborator.func_matricula || "Sem informação"}
              />
              <List
                label="Situação"
                value={collaborator.situacao.label || "Sem informação"}
              />
            </div>
          </Col>
          <Col md={7}>
            {/* <Row>
              <Col md={6} className="g-3">
                <DataCard label="Salário Bruto" value={grossSalary} />
              </Col>
              <Col md={6} className="g-3">
                <DataCard label="Salário Líquido" value={netSalary} />
              </Col>
            </Row> */}
            <Row>
              <Col
                md={collaborator.func_dtdesligamento ? 6 : 12}
                className="g-3"
              >
                <DataCard label="Data de admissão" value={admissionDate} />
              </Col>
              {collaborator.func_dtdesligamento && (
                <Col md={6} className="g-3">
                  <DataCard label="Data de desligamento" value={shutdownDate} />
                </Col>
              )}
            </Row>

            <Row>
              {/* <Col md={6} className="g-3">
                <DataCard
                  label="Situação"
                  value={collaborator.situacao.label}
                />
              </Col> */}
              <Col md={6} className="g-3">
                <DataCard
                  label="Max. Parcelas"
                  value={`${collaborator.func_parcelamaximo}`}
                />
              </Col>

              <Col md={6} className="g-3">
                <DataCard
                  label="Taxa de juros"
                  value={`${collaborator.func_taxajuros}%`}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="g-3">
                <DataCard label="Salário Bruto" value={grossSalary} />
              </Col>
              <Col md={6} className="g-3">
                <DataCard label="Salário Líquido" value={netSalary} />
              </Col>
            </Row>
            {/* <Col
                md={collaborator.vlr_maximo_parcela ? 6 : 12}
                className="g-3"
                >
                <DataCard
                label="Max. Parcelas"
                value={`${collaborator.func_parcelamaximo}`}
                />
              </Col> */}
            {/* {collaborator.vlr_maximo_parcela && ( */}
            <Row>
              <Col md={6} className="g-3">
                <DataCard
                  label="Limite de comprometimento"
                  value={`${commitmentLimit}%`}
                />
              </Col>
              <Col md={6} className="g-3">
                <DataCard
                  label="Valor máximo da parcela"
                  value={maximumAmountOfInstallment || "Sem informação"}
                />
              </Col>
            </Row>
            {/* // )} */}
          </Col>
        </Row>
      </Container>
    </BodyPanel>
  );
}
