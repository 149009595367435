import {
  CLEAR_STATE,
  FINISH_LOADING,
  START_LOADING,
} from "model/action-types/type.general";

export function clearStateAction() {
  return { type: CLEAR_STATE };
}

export function startLoadingAction() {
  return { type: START_LOADING };
}

export function finishLoadingAction() {
  return { type: FINISH_LOADING };
}
