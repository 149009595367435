import CurrencyUtilities from "utils/currencyFormatting";

type IProps = {
  debitBalance: number;
};

export default function NegativeBalance({ debitBalance }: IProps) {
  const valueFormatted = CurrencyUtilities.formatCurrencyToBrazilianStandard(debitBalance);
  return (
    <div className="d-flex flex-column gap-1">
      <span className="text-roboto-fs14-lh16-fw400-red300">Saldo Devedor</span>
      <span className="text-roboto-fs14-lh16-fw400-blue700">
        R$ {valueFormatted}
      </span>
    </div>
  );
}
