import Skeleton from 'react-loading-skeleton';

import './style.css'

export default function Animated() {
  return (
    <div className="dashboard-managed-values-container d-flex flex-column">
      <div>
        <Skeleton width={"25rem"} height="5rem" />
      </div>
      <span className="mt-4">
        <Skeleton width={"10rem"} height="1.5rem" />
      </span>
      <span className="mt-1">
        <Skeleton width={"16rem"} height="1.5rem" />
      </span>
      <hr />
      <div >
        <Skeleton width={"20rem"} height="3rem" />
      </div>
      <div className="mt-2">
        <Skeleton width={"10rem"} height="1.5rem" />
      </div>
      <div className="mt-1">
        <Skeleton width={"16rem"} height="1.5rem" />
      </div>
    </div>
  );
}