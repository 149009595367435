import IHttpClient from "interfaces/interface.http-client";
import { axiosClient } from "services/axios";
import { apiVersion, url } from "config/config.http";
import { globalNavigate } from "./router";

const controller = new AbortController();

class Http {
  private token;
  public headers;

  constructor() {
    this.token = JSON.parse(sessionStorage.getItem("@userData") as string)
      ? JSON.parse(sessionStorage.getItem("@userData") as string).token
      : null;
    this.headers = {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: this.token ? `Bearer ${this.token}` : "",
      "X-Api-Version": apiVersion as string,
    };
  }

  private defineResponse(response: any) {
    if (response.status !== 200) {
      if (response.status === 401) {
        globalNavigate("/");
      }

      if (response.data.mensagem)
        return { error: true, message: response.data.mensagem };
      else
        return {
          error: true,
          message: "Um erro inesperado foi obtido. Tente novamente mais tarde!",
        };
    } else return response.data;
  }

  private async httpClient(
    method: "GET" | "POST" | "DELETE" | "PUT" | "PATCH",
    params: IHttpClient
  ) {
    try {
      const response = await axiosClient({
        ...params,
        method: method,
        baseURL: url,
        headers: this.headers,
        signal: controller.signal,
      });

      return this.defineResponse(response);
    } catch (error: any) {
      console.log(error);
      return this.defineResponse(error.response);
    }
  }

  abortRequest() {
    controller.abort();
  }

  async httpClientGet(params: IHttpClient) {
    return await this.httpClient("GET", params);
  }

  async httpClientPost(params: IHttpClient) {
    return await this.httpClient("POST", params);
  }

  async httpClientPut(params: IHttpClient) {
    return await this.httpClient("PUT", params);
  }

  async httpClientPatch(params: IHttpClient) {
    return await this.httpClient("PATCH", params);
  }

  async httpClientDelete(params: IHttpClient) {
    return await this.httpClient("DELETE", params);
  }
}

export default Http;
