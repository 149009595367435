import { useState } from "react";
import { Col, Row } from "react-bootstrap";

// import { UserUtilities } from "utils/utilities.user";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";
import {
  clearSatateModalProfileAction,
  setErrorPasswordProfileAction,
} from "model/actions/action.profile";
import { changePasswordProfileAction } from "model/actions/action.profile-submit";

import Modal from "components/Modal";
import Button from "components/Button";
// import NotifySelect from "components/NotifySelect";

import CloseIcon from "assets/img/close-icon.svg";

import { InputProfile, LabelProfile } from "./styled";
import "./style.css";

export default function ModalProfile() {
  const dispatch = useAppDispatch();
  const { show, passwordError } = useAppSelector(
    (state) => state.profileReducer
  );

  // const [notify, setNotify] = useState(UserUtilities.getNotifySetting());

  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmationNewPassword, setConfirmationNewPassword] = useState("");

  function defineCurrentPassword(event: any) {
    if (passwordError) dispatch(setErrorPasswordProfileAction(false));
    setCurrentPassword(event.target.value);
  }

  function defineNewPassword(event: any) {
    setNewPassword(event.target.value);
  }

  function defineConfirmationNewPassword(event: any) {
    setConfirmationNewPassword(event.target.value);
  }

  // function onChage(rhNotId: number) {
  //   setNotify(rhNotId);
  //   changeNotifySetting(rhNotId);
  // }

  // function changeNotifySetting(rhNotId: number) {
  //   dispatch(changeNotifySettingProfileAction(rhNotId));
  // }

  function cleanForm() {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmationNewPassword("");
  }

  function onClose() {
    cleanForm();
    dispatch(clearSatateModalProfileAction());
  }

  function changePassword() {
    dispatch(
      changePasswordProfileAction(
        currentPassword,
        newPassword,
        confirmationNewPassword
      )
    );

    cleanForm();
  }

  return (
    <Modal show={show}>
      <div className="modal-profile">
        <Row>
          <Col md={10} className="d-flex align-items-center">
            <h1 className="text-roboto-fs30-lh30-fw400-blue700">
              Informações do Usuário
            </h1>
          </Col>
          <Col md={2} className="d-flex align-items-center justify-content-end">
            <button className="bg-transparent close-button" onClick={onClose}>
              <img src={CloseIcon} alt="" />
            </button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={12} className="d-flex gap-4 flex-column gy-4">
            <h2>Senha</h2>
            <div className="d-flex gap-3 flex-column">
              <LabelProfile status={!passwordError}>Senha atual</LabelProfile>
              <InputProfile
                status={!passwordError}
                value={currentPassword}
                onChange={defineCurrentPassword}
                type="password"
              />

              <LabelProfile status>Nova senha</LabelProfile>
              <InputProfile
                status
                value={newPassword}
                onChange={defineNewPassword}
                type="password"
              />

              <LabelProfile status={confirmationNewPassword === newPassword}>
                Confirmar nova senha
              </LabelProfile>
              <InputProfile
                status={confirmationNewPassword === newPassword}
                value={confirmationNewPassword}
                onChange={defineConfirmationNewPassword}
                type="password"
              />
            </div>
            <Button variant="green" onClick={changePassword}>
              Alterar senha
            </Button>
          </Col>
        </Row>
        {/* <Row className="section-profile">
          <Col md={12} className="gy-3 d-flex gap-4 flex-column">
            <h2>Notificações</h2>
            <div className="d-flex gap-3">
              <NotifySelect value={notify} onChange={onChage} />
            </div>
          </Col>
        </Row> */}
      </div>
    </Modal>
  );
}
