import { all, put, select, takeEvery } from "redux-saga/effects";
import Http from "services/httpClient";
import { GET_AUTHENTICATE_CODE } from "model/action-types/type.authenticate";
import {
  AUTHENTICATE,
  AUTHENTICATE_CHANGE_PASSWORD,
  SUBMIT_AUTHENTICATE_CODE,
} from "model/action-types/type.authenticate-submit";
import {
  setAuthenticateResponseAction,
  setRecoveryPasswordActionStep,
  setUserIdRecoveryPasswordAction,
} from "model/actions/action.authenticate";
import { openModalNotificationAction } from "model/actions/action.modal-notification";
import { globalNavigate } from "services/router";

function* authenticate(action: any): any {
  const { password, userName } = action;
  const http = new Http();
  const response = yield http.httpClientPost({
    url: "empresa/login",
    data: {
      eusu_email: userName,
      eusu_senha: password,
    },
  });

  if (!response.error) {
    sessionStorage.setItem("@userData", JSON.stringify(response.data));
    globalNavigate("/home");
    if (response.data.pendencias && response.data.pendencias.length)
      yield put(openModalNotificationAction());
    return;
  }

  yield put(setAuthenticateResponseAction(response));
}

function* getAuthenticationCode(action: any): any {
  const http = new Http();
  const response = yield http.httpClientPost({
    url: "empresa/codigo",
    data: {
      eusu_email: action.userName,
    },
  });

  if (response.error) {
    yield put(setAuthenticateResponseAction(response));
    return false;
  }

  yield put(setUserIdRecoveryPasswordAction(response));
  yield put(setRecoveryPasswordActionStep("check-code"));
}

function* submitAuthenticateCode(action: any): any {
  const store = yield select();
  const userId = store.authenticateReducer.userId;

  const http = new Http();

  const response = yield http.httpClientPost({
    url: `empresa/codigo/validar/${userId}`,
    data: {
      codigo: action.code,
    },
  });

  if (response.error) {
    yield put(setAuthenticateResponseAction(response));
    return false;
  }

  if (!response.valido) {
    yield put(
      setAuthenticateResponseAction({
        error: true,
        message: "Código inválido.",
      })
    );
    return false;
  }

  sessionStorage.setItem(
    "@userData",
    JSON.stringify({ token: response.token })
  );

  yield put(setRecoveryPasswordActionStep("change-password"));
}

function* authenticateChangePassword(action: any): any {
  const store = yield select();
  const userId = store.authenticateReducer.userId;

  const http = new Http();

  const response = yield http.httpClientPut({
    url: `empresa/senha/${userId}`,
    data: {
      eusu_senha: action.password,
    },
  });

  if (response.error) return yield put(setAuthenticateResponseAction(response));

  yield put(
    setAuthenticateResponseAction({
      error: false,
      message: "Senha alterada com sucesso!",
    })
  );
  yield put(setRecoveryPasswordActionStep("get-authentication-code"));
}

export default all([
  takeEvery(AUTHENTICATE, authenticate),
  takeEvery(GET_AUTHENTICATE_CODE, getAuthenticationCode),
  takeEvery(SUBMIT_AUTHENTICATE_CODE, submitAuthenticateCode),
  takeEvery(AUTHENTICATE_CHANGE_PASSWORD, authenticateChangePassword),
]);
