import { useState } from "react";

import Button from "components/Button";
import Modal from "components/Modal";
import { BsQuestion } from "react-icons/bs";
import {
  BaseHelpButton,
  BaseHelpModalContainer,
  BaseHelpTextArea,
} from "./styled";
import { submitFaqAction } from "model/actions/action.dashboard-submit";
import { useAppDispatch } from "hooks/hooks.redux";

export default function Help() {
  const dispatch = useAppDispatch();

  const [showHelp, setShowHelp] = useState(false);
  const [faqMessage, setFaqMessage] = useState("");

  function openHelp() {
    setShowHelp(true);
  }

  function closeHelp() {
    setShowHelp(false);
  }

  function submitFaq() {
    dispatch(submitFaqAction(faqMessage));
    closeHelp();
  }

  return (
    <>
      <Modal show={showHelp}>
        <BaseHelpModalContainer>
          <h1 className="text-roboto-fs16-lh18-fw700-blue700">
            Informe sua dúvida
          </h1>
          <BaseHelpTextArea
            autoFocus
            className="mt-3 text-roboto-fs14-lh16-fw400-blue700"
            onChange={(e) => setFaqMessage(e.target.value)}
          />
          <div className="d-flex flex-row mt-5 gap-2">
            <Button variant="blue" className="w-100" onClick={submitFaq}>
              Enviar
            </Button>
            <Button
              variant="blue"
              isActive={false}
              className="w-100"
              onClick={closeHelp}
            >
              Fechar
            </Button>
          </div>
        </BaseHelpModalContainer>
      </Modal>
      <BaseHelpButton onClick={openHelp}>
        <BsQuestion color="white" size={52} />
      </BaseHelpButton>
    </>
  );
}
