import { AnyAction } from "redux";
import produce from "immer";
import {
  SET_AUTHENTICATE_RESPONSE,
  GET_AUTHENTICATE_CODE,
  SET_PASSWORD_RECOVERY_STEP,
  SET_USER_ID_RECOVERY_PASSWORD,
} from "model/action-types/type.authenticate";
import {
  AUTHENTICATE,
  AUTHENTICATE_CHANGE_PASSWORD,
  SUBMIT_AUTHENTICATE_CODE,
} from "model/action-types/type.authenticate-submit";
import { CLEAR_STATE } from "model/action-types/type.general";

interface IState {
  successfulAuthentication: boolean | null;
  successfulRecoverPassword: boolean | null;
  requisitionResponse: {
    error: boolean;
    message?: string;
  } | null;
  userId: null | number;
  passwordRecoveryStep:
    | "get-authentication-code"
    | "check-code"
    | "change-password";
}

const INITIAL_STATE: IState = {
  successfulAuthentication: null,
  successfulRecoverPassword: null,
  requisitionResponse: null,
  userId: null,
  passwordRecoveryStep: "get-authentication-code",
};

export function authenticateReducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case AUTHENTICATE:
      return state;
    case SET_AUTHENTICATE_RESPONSE:
      return produce(state, (draft) => {
        // if (action.data.error) {
          draft.requisitionResponse = action.data;
        // }
      });
    case GET_AUTHENTICATE_CODE:
      return state;
    case SUBMIT_AUTHENTICATE_CODE:
      return state;
    case AUTHENTICATE_CHANGE_PASSWORD:
      return state;
    case SET_USER_ID_RECOVERY_PASSWORD:
      return produce(state, (draft) => {
        draft.userId = action.data.data.eusu_id;
      });
    case SET_PASSWORD_RECOVERY_STEP:
      return produce(state, (draft) => {
        draft.passwordRecoveryStep = action.step;
      });
    case CLEAR_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
}
