import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import Body from "layout/DefaultBody";
import TableContainer from "layout/TableContainer";
import Button from "components/Button";
import Table from "./components/Table";
import ReactSelect from "components/ReactSelect";
import Filters from "./components/Filters";
import InputCalendar from "./components/InputCalendar";

import {
  searchCurrentConciliationsActions,
  searchConciliationHistoryAction,
  setSearchTypeConciliationAction,
} from "model/actions/action.conciliations";
import { clearStateAction } from "model/actions/action.general";
import { Companies } from "utils/company";

import "./style.css";

export default function Conciliations() {
  const dispatch = useAppDispatch();
  const { conciliation, lastPage, currentPage, searchType, total } =
    useAppSelector((state) => state.conciliationsReducer);
  const { isLoading } = useAppSelector((state) => state.generalReducer);

  const { getLisCompanies } = new Companies();
  const options = getLisCompanies();

  const buttonDisabled = isLoading;

  const initialPeriod = new Date();
  initialPeriod.setDate(0);
  const [period, setPeriod] = useState(initialPeriod);

  useEffect(() => {
    if (!searchType) dispatch(searchCurrentConciliationsActions());
    if (searchType) {
      dispatch(searchConciliationHistoryAction({ period: initialPeriod }));
      setPeriod(initialPeriod);
    }
  }, [searchType]);

  function selectConciliationSearchType(type: number) {
    dispatch(clearStateAction());
    dispatch(setSearchTypeConciliationAction(type));
  }

  function isThisTableOptionSelected(option: 0 | 1) {
    return searchType === option;
  }

  function searchConciliationByType(conciliationType: number) {
    if (!searchType)
      dispatch(searchCurrentConciliationsActions({ conciliationType }));
    if (searchType)
      dispatch(searchConciliationHistoryAction({ conciliationType, period }));
  }

  function searchConciliationByCompany(data: any) {
    const idCompany = data.value;
    if (!searchType) dispatch(searchCurrentConciliationsActions({ idCompany }));
    if (searchType)
      dispatch(searchConciliationHistoryAction({ idCompany, period }));
  }

  function searchConciliationByPeriod(value: Date) {
    const period = value;
    dispatch(searchConciliationHistoryAction({ period }));
    setPeriod(period);
  }

  function paginate(page: number) {
    if (!searchType) dispatch(searchCurrentConciliationsActions({ page }));
    if (searchType) dispatch(searchConciliationHistoryAction({ page, period }));
  }

  return (
    <Body
      titlePage="Conciliação"
      description="Consulte os vencimentos, exporte e gere os boletos tudo por aqui."
    >
      <div className="row">
        <div className="col-md-3 gy-3">
          <ReactSelect
            options={options}
            onChange={searchConciliationByCompany}
          />
        </div>
        <div className="col-md-6 gy-3 d-flex gap-3 collaborators-buttonfilter-container">
          <Button
            variant="blue"
            isActive={isThisTableOptionSelected(0)}
            className="text-roboto-fs12-lh14-fw400"
            onClick={() => selectConciliationSearchType(0)}
            disabled={buttonDisabled}
          >
            Cociliação mensal
          </Button>
          <Button
            variant="pink"
            isActive={isThisTableOptionSelected(1)}
            className="text-roboto-fs12-lh14-fw400"
            onClick={() => selectConciliationSearchType(1)}
            disabled={buttonDisabled}
          >
            Histórico de conciliações
          </Button>
        </div>
        <div className="col-md-3 gy-3 d-flex flex-row align-items-center justify-content-end gap-4">
          <Filters onChange={searchConciliationByType} />
          {searchType === 1 && (
            <InputCalendar
              value={period}
              onChage={searchConciliationByPeriod}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <TableContainer
            isLoading={isLoading}
            pagination={{
              currentPage,
              paginate,
              lastPage,
              total,
            }}
          >
            <Table option={searchType} data={conciliation} />
          </TableContainer>
        </div>
      </div>
    </Body>
  );
}
