import { Table } from "layout/Table/styled";
import IConciliationShutdownInstallments from "interfaces/interface.conciliation-shutdown-installments";
import RowConciliationShutdownCollaborator from "./Rows/RowConciliationShutdownCollaborator";

export default function TableConciliationShutdownCollaborator({
  data: installments,
}: {
  data: IConciliationShutdownInstallments[];
}) {
  return (
      <Table responsive hover>
        <thead>
          <tr>
            <th className="table-text">Nº do contrato</th>
            <th className="table-text">Nome do colaborador(a)</th>
            <th className="table-text">CPF</th>
            <th className="table-text">Saldo devedor</th>
            <th className="table-text">Saldo retido</th>
          </tr>
        </thead>
        <tbody>
          {installments.map((item, key) => (
            <RowConciliationShutdownCollaborator key={key} portion={item} />
          ))}
        </tbody>
      </Table>
  );
}
