import produce from "immer";
import {
  FINISH_LOADING,
  START_LOADING,
} from "model/action-types/type.general";
import { AnyAction } from "redux";

const INITIAL_STATE = {
  isLoading: false as boolean,
};

export function generalReducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case START_LOADING:
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    case FINISH_LOADING:
      return produce(state, (draft) => {
        draft.isLoading = false;
      });
    default:
      return state;
  }
}
