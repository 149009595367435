import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";
import { closeModalNotificationAction } from "model/actions/action.modal-notification";

import Modal from "components/Modal";
import Button from "components/Button";
import FeedbackContainer from "components/FeedbackContainer";

import { ParagraphyFeedbackModal } from "./styled";
import "./style.css";

export function ModalNotification() {
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.modalNotificationReducer);


  const pendencies = sessionStorage.getItem("@userData")
    ? JSON.parse(sessionStorage.getItem("@userData") as string).pendencias
    : [];

  function onClose() {
    dispatch(closeModalNotificationAction());
  }

  const variant = "success"

  return (
    <Modal show={isOpen}>
      <FeedbackContainer state={variant}>
        <div className="container-fluid feedback-modal">
          <div className="row">
            <div className="col-md-12 d-flex flex-column align-items-center gap-4 gy-3">
              <div className="d-flex flex-column align-items-center gap-4 gy-3">
                <h2 className="title-modal-notification">Você tem {pendencies.length} pendências</h2>
                <ul>
                {pendencies.map((item: any, key: number) => (
                  <ParagraphyFeedbackModal key={key} status={variant}>
                    {item.quantidade} {item.label}
                  </ParagraphyFeedbackModal>
                ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex flex-column align-items-center gy-4">
              <Button
                variant="green"
                className="w-100 text-montserrat-fs16-fw400"
                onClick={onClose}
              >
                Fechar
              </Button>
            </div>
          </div>
        </div>
      </FeedbackContainer>
    </Modal>
  );
}
