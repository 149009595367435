import { useAppSelector } from "hooks/hooks.redux";

import ChangePassword from "./ChangePassword";
import ConfirmationCode from "./ConfirmationCode";
import PasswordRecoveryForm from "./PasswordRecoveryForm";

interface IProps {
  resetContentType: () => void;
}

export default function PasswordRecovery({ resetContentType }: IProps) {
  const { passwordRecoveryStep } = useAppSelector(
    (state) => state.authenticateReducer
  );

  switch (passwordRecoveryStep) {
    case "get-authentication-code":
      return <PasswordRecoveryForm resetContentType={resetContentType} />;
    case "check-code":
      return <ConfirmationCode resetContentType={resetContentType} />;
    default:
      return <ChangePassword resetContentType={resetContentType} />;
  }
}
