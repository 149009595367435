export default class UtilitiesMaskValidations {
  static cpfValidation(value: string) {
    return /(\d{3}).(\d{3}).(\d{3})-(\d{2})/g.test(value);
  }

  static numericOrMaskedCpfValidation(value: string){
    return /^(\d{3}\.\d{3}\.\d{3}-\d{2}|\d{11})$/g.test(value);
  }
  
}
