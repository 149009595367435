export default class GeneralUtilities {
  isIncludedEconomicGroup(emp_id: number, gpec_id: number) {
    const { empresas } = JSON.parse(
      sessionStorage.getItem("@userData") as string
    );
    let company = empresas.find((item: any) => item.emp_id === emp_id);

    if (!company) return false;

    return company.gpec_id === gpec_id;
  }

  static getAListFromAnArrayOfNumbers(array: number[]) {
    return JSON.stringify(array).replace(/[\[\]]/g, "");
  }

  static getIntegersFromURL() {
    const urlQuery: any = window.location.search;
    let parameterList: Array<number> = [];

    [...urlQuery.matchAll(/\d+/g)].forEach((element) =>
      parameterList.push(Number(element[0]))
    );

    return parameterList;
  }
}
