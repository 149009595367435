import { all, put, takeLatest } from "redux-saga/effects";
import Http from "services/httpClient";
import {
  SEARCH_CONCILIATION_PORTION,
  SEARCH_CURRENT_CONCILIATIONS,
  SEARCH_RESUME_CONCILIATION,
  SEARCH_CONCILIATION_HISTORY,
} from "model/action-types/type.conciliations";
import {
  setConciliationPortionAction,
  setConciliationsAction,
  setResumeConciliationAction,
} from "model/actions/action.conciliations";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";
import {
  finishLoadingAction,
  startLoadingAction,
} from "model/actions/action.general";
import GeneralUtilities from "utils/utilities.general";
import { globalNavigate } from "services/router";

function* searchCurrentConciliations(action: any): any {
  yield put(startLoadingAction());

  const formattedPeriod = new Date()
    .toLocaleDateString("en-CA")
    .substring(0, 7);

  const queryStandard = `periodo=${formattedPeriod}&page=${action.page || 1}`;

  const queryByCompany = `${
    action.idCompany ? `&emp_id=${action.idCompany}` : ""
  }`;

  const queryByType = `${
    action.conciliationType ? `&tipo=${action.conciliationType}` : ""
  }`;

  const query = queryStandard + queryByCompany + queryByType;

  const http = new Http();

  const response = yield http.httpClientGet({
    url: `empresa/conciliacao?${query}`,
  });

  yield put(finishLoadingAction());

  if (response.error) {
    return yield put(openFeedbackModalAction(response.message, "error"));
  }

  yield put(setConciliationsAction(response));
}

function* searchResumeConciliation(): any {
  const [conciliationId] = GeneralUtilities.getIntegersFromURL();

  const http = new Http();

  const response = yield http.httpClientGet({
    url: `empresa/conciliacao/${conciliationId}`,
  });

  if (response.error) {
    globalNavigate("/conciliacoes");
    return yield put(openFeedbackModalAction(response.message, "error"));
  }
  yield put(setResumeConciliationAction(response));
}

function* searchConciliationPortion(action: any): any {
  yield put(startLoadingAction());

  const [conciliationId] = GeneralUtilities.getIntegersFromURL();

  const http = new Http();

  const response = yield http.httpClientGet({
    url: `empresa/conciliacao/parcelas/${conciliationId}?page=${
      action.page || 1
    }`,
  });

  yield put(finishLoadingAction());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(setConciliationPortionAction(response));
}

function* searchConciliationHistory(action: any): any {
  yield put(startLoadingAction());

  const formattedPeriod = action.period
    .toLocaleDateString("en-CA")
    .substring(0, 7);

  const queryStandard = `periodo=${formattedPeriod}&page=${action.page || 1}`;

  const queryByCompany = `${
    action.idCompany ? `&emp_id=${action.idCompany}` : ""
  }`;

  const queryByType = `${
    action.conciliationType ? `&tipo=${action.conciliationType}` : ""
  }`;

  const query = queryStandard + queryByCompany + queryByType;

  const http = new Http();

  const response = yield http.httpClientGet({
    url: `empresa/conciliacao?${query}`,
  });

  yield put(finishLoadingAction());

  if (response.error) {
    return yield put(openFeedbackModalAction(response.message, "error"));
  }

  yield put(setConciliationsAction(response));
}

export default all([
  takeLatest(SEARCH_CURRENT_CONCILIATIONS, searchCurrentConciliations),
  takeLatest(SEARCH_CONCILIATION_HISTORY, searchConciliationHistory),
  takeLatest(SEARCH_RESUME_CONCILIATION, searchResumeConciliation),
  takeLatest(SEARCH_CONCILIATION_PORTION, searchConciliationPortion),
]);
