import styled, { css } from "styled-components";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";

export const DropdownMenuTrigger = styled(DropdownMenuPrimitive.Trigger)`
  background-color: ${({ theme }) => theme.COLORS.BLUE_700};
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  border-radius: 2rem;
  padding: 0rem 1.5rem;
`;

type DropdownMenuCheckboxItemProps = {
  checked: boolean;
};

const hoverDropdownMenuCheckboxItem = css`
  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.COLORS.BLUE_700};
  }
`;

const checkedDropdownMenuCheckboxItem = css`
  color: #fff;
  background-color: ${({ theme }) => theme.COLORS.BLUE_700};
`;

export const DropdownMenuCheckboxItem = styled(
  DropdownMenuPrimitive.CheckboxItem
)<DropdownMenuCheckboxItemProps>`
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  color: ${(props) => props.theme.COLORS.GRAY_600};
  cursor: pointer;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  outline: 0px;
  ${(props) => !props.checked && hoverDropdownMenuCheckboxItem}
  ${(props) => props.checked && checkedDropdownMenuCheckboxItem}
`;
