import TableRowAdministrator from "./TableRowAdministrator";

import IAdministrators from "interfaces/http-response/interface.administrators";
import { UserUtilities } from "utils/utilities.user";
import { Table } from "layout/Table/styled";

type IProps = {
  data: IAdministrators[];
};

export default function TableAdministrators({ data }: IProps) {

  const isAuthorized = UserUtilities.isAuthorized(1);

  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>Nome</th>
          <th>E-mail</th>
          <th>Tipo</th>
          {/* <th>Notificação de empréstimos</th> */}
          <th>Status</th>
          {isAuthorized &&
            <th>Acões</th>
          }
        </tr>
      </thead>
      <tbody>
        {data.map((item: any, key: number) => (
          <TableRowAdministrator key={key} administrator={item} />
        ))}
      </tbody>
    </Table >
  );
}

