import { Table } from "layout/Table/styled";

import RowEndorsement from "./Rows/RowEndorsement";

import ICollaboratorEndorsement from "interfaces/interface.collaborator-endorsement";

import "./style.css";
import ListById from "utils/utilities.list-by-id";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";
import { changeSelectedCollaboratorsAction } from "model/actions/action.collaborators";

type IProps = {
  data: ICollaboratorEndorsement[];
};

export default function TableEndorsement({ data }: IProps) {
  const dispatch = useAppDispatch();

  const { currentPage, selectedCollaborators } = useAppSelector(
    (state) => state.collaboratorsReducer
  );

  const numberOfItemsSelected = selectedCollaborators[currentPage]?.length || 0;
  // const buttonDownloadFileDisabled = isLoading || numberOfLinesSelected === 0;

  const isAllChecked = numberOfItemsSelected === 20;

  function changeList() {
    let newList = [];
    if (!isAllChecked) {
      newList = ListById.addAllIds(
        data,
        currentPage,
        selectedCollaborators,
        "func_id"
      );
      console.table(["add all", newList]);
    } else {
      newList = ListById.removeAllIds(currentPage, selectedCollaborators);
      console.table(["remove all", newList]);
    }

    dispatch(changeSelectedCollaboratorsAction(newList));
  }

  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th className="table-text">
            <div className="d-flex flex-row align-items-center gap-3">
              <input
                type="checkbox"
                onChange={changeList}
                checked={isAllChecked}
              />
              <span className="table-text">Nome do colaborador(a)</span>
            </div>
          </th>
          <th className="table-text">CPF</th>
          <th className="table-text">CNPJ</th>
          {/* <th className="table-text">Status</th> */}
          <th className="table-text">Ações</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, key) => (
          <RowEndorsement key={key} collaborator={item} />
        ))}
      </tbody>
    </Table>
  );
}
