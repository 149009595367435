import UtilitiesMaskValidations from "utils/utilities.mask-validation";
import {read} from "xlsx";
import IShutdownSheetResponse from "interfaces/interface.shutdown-sheet-response";

export default class HelperShutdownSheet {
    
    static extractDataFromSheet(file: ArrayBuffer): IShutdownSheetResponse {
        let shutDowListData: any[] = [];
        const workbook = read(file, {type: "array"})
        let sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName]
        let sheetFormat: string | undefined =  worksheet['!ref'];

        console.log(worksheet)
        if(worksheet['!ref'] === undefined){
          return {
            warningMessage: {
              value: `Modelo de planilha incorreto. Parece que sua planilha está vazia.`,
              color: "RED_300"
            },            
            canShutdown: false,
            shutdowArr: []
          };
        }
        if( !/A1:B\d+/g.test(worksheet['!ref'])){
          return {
            warningMessage: {
              value: `Modelo de planilha incorreto. Foi enviado um formato ${sheetFormat} . Mas esperava um A1:B*`,
              color: "RED_300"
            },            
            canShutdown: false,
            shutdowArr: []
          };
          
        }
        let nameColumn = "A";
        let cpfColumn = "B";
        let indexes = worksheet['!ref']?.split(':')
        let lastRow = indexes ? parseInt(indexes[1].replace(/[A-Za-z]*/gi,'')) : 0
        for (let row = 2; row <= lastRow ; row++) {
          let cpfValue, nameValue;
          cpfValue = worksheet[`${cpfColumn}${row}`] ?  worksheet[`${cpfColumn}${row}`]['v'] : "" 
          nameValue = worksheet[`${nameColumn}${row}`] ? worksheet[`${nameColumn}${row}`]['v'] : ""
          let cpfIsValid: boolean = UtilitiesMaskValidations.numericOrMaskedCpfValidation(cpfValue);
          if(!cpfIsValid){
              let shutdowElement = {
                  name: nameValue,
                  cpf: cpfValue,
                  status: {label: "Cpf inválido", value: false}
              }
              shutDowListData.push(shutdowElement);
          }          
        }
      
        if(shutDowListData.length === 0){
          return {
            warningMessage: {
              value: "* Planilha sem erros de formatação.",
              color: "GREEN_300"
            },
            canShutdown: true,
            shutdowArr: shutDowListData
          };
        }
   
        return {
          warningMessage: {
            value: `* Não é possível realizar o desligamento, foram encontrados: ${shutDowListData.length} erros.`,
            color: "RED_300"
          },
          canShutdown: false,
          shutdowArr: shutDowListData
        };

    }
    
  }
  