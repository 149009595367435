import styled from "styled-components";

interface RequestTypeIndicatorProps {
  typeId: number;
}
export const RequestTypeIndicator = styled.div<RequestTypeIndicatorProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.typeId === 1
      ? props.theme.COLORS.GREEN_300
      : props.theme.COLORS.BLUE_600};
`;
