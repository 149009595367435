import { useNavigate, NavigateFunction } from "react-router-dom";

export let globalNavigate: NavigateFunction;

export const GlobalHistory = () => {
  globalNavigate = useNavigate();

  return null;
};

export function getQueryParam(key: string): string | null {
  let params = new URLSearchParams(document.location.search);
  return params.get(key);
}
