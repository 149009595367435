import { all, put, takeLatest } from "redux-saga/effects";
import Http from "services/httpClient";
import {
  CHANGE_NOTIFY_SETTING_PROFILE,
  CHANGE_PASSWORD_PROFILE,
} from "model/action-types/type.profile-submit";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";
import {
  clearSatateModalProfileAction,
  setErrorPasswordProfileAction,
} from "model/actions/action.profile";
import { UserUtilities } from "utils/utilities.user";
import {
  closeModalLoading,
  openModalLoading,
} from "model/actions/action.modal-loading";

function* changePassword(action: any): any {
  const isCurrentPassword = yield checkCurrentPassword(action.currentPassword);
  if (!isCurrentPassword) return yield put(setErrorPasswordProfileAction(true));

  yield put(clearSatateModalProfileAction());
  yield put(openModalLoading());

  const { eusu_id: userId } = UserUtilities.getUserAttribute();
  const http = new Http();

  const response = yield http.httpClientPut({
    url: `empresa/alterardados/${userId}`,
    data: {
      eusu_senha: action.newPassword,
    },
  });

  yield put(closeModalLoading());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));
    
  yield put(openFeedbackModalAction("Senha alterada com sucesso!", "success"));
}

function* checkCurrentPassword(password: string): any {
  const { eusu_email } = UserUtilities.getUserAttribute();

  const http = new Http();
  const response = yield http.httpClientPost({
    url: "empresa/login",
    data: {
      eusu_email,
      eusu_senha: password,
    },
  });

  if (response.error) return false;
  else return true;
}

function* changeNotifySetting(action: any): any {
  const http = new Http();
  const { eusu_id } = UserUtilities.getUserAttribute();

  const response = yield http.httpClientPut({
    url: `empresa/empresausuario/${eusu_id}`,
    data: {
      rhnot_id: action.rhNotId,
    },
  });

  if (response.error) {
    put(clearSatateModalProfileAction());
    return yield put(openFeedbackModalAction(response.message, "error"));
  }
}

export default all([
  takeLatest(CHANGE_PASSWORD_PROFILE, changePassword),
  takeLatest(CHANGE_NOTIFY_SETTING_PROFILE, changeNotifySetting),
]);
