import {
  SEARCH_CONCILIATION_PORTION,
  SEARCH_CURRENT_CONCILIATIONS,
  SEARCH_RESUME_CONCILIATION,
  SET_CONCILIATION_PORTION,
  SET_CONCILIATIONS,
  SET_RESUME_CONCILIATION,
  SEARCH_CONCILIATION_HISTORY,
  SET_SEARCH_TYPE_CONCILIATION,
  OPEN_CONCILIATION_CONTESTATION_PANEL,
  CLOSE_CONCILIATION_CONTESTATION_PANEL,
} from "model/action-types/type.conciliations";

export function searchCurrentConciliationsActions(params?: {
  page?: number;
  idCompany?: number;
  conciliationType?: number;
}) {
  return { type: SEARCH_CURRENT_CONCILIATIONS, ...params };
}

export function setConciliationsAction(data: any) {
  return { type: SET_CONCILIATIONS, data };
}

export function searchResumeConciliationAction() {
  return { type: SEARCH_RESUME_CONCILIATION };
}

export function setResumeConciliationAction(data: any) {
  return { type: SET_RESUME_CONCILIATION, data };
}

export function searchConciliationPortionAction(page?: number) {
  return { type: SEARCH_CONCILIATION_PORTION, page };
}

export function setConciliationPortionAction(data: any) {
  return { type: SET_CONCILIATION_PORTION, data };
}

export function searchConciliationHistoryAction(params: {
  page?: number;
  idCompany?: number;
  period: Date;
  conciliationType?: number;
}) {
  return { type: SEARCH_CONCILIATION_HISTORY, ...params };
}

export function setSearchTypeConciliationAction(searchType: number) {
  return { type: SET_SEARCH_TYPE_CONCILIATION, searchType };
}

export function openConciliationContestationPanelAction() {
  return { type: OPEN_CONCILIATION_CONTESTATION_PANEL };
}

export function closeConciliationContestationPanelAction() {
  return { type: CLOSE_CONCILIATION_CONTESTATION_PANEL };
}