import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import Container from "./Container";
import HorizontalSeparator from "layout/HorizontalSeparator/styled";
import Button from "components/Button";
import Label from "components/Label";

import { UtilitiesDate } from "utils/utilities.date";
import { closeRequestStatusAction } from "model/actions/action.request";

export default function Presentation() {
  const dispatch = useAppDispatch();
  const { requestStatus } = useAppSelector(
    (state) => state.requestsReducer
  );

  function closeRequestFeedback() {
    dispatch(closeRequestStatusAction());
  }

  const nameAdministrator =
    requestStatus?.propostastatus[0].usuario.eusu_nome || "Sem Informação";

  const dateTime = requestStatus?.propostastatus[0].usuario.eusu_nome
    ? UtilitiesDate.formatDateAndTimeToBrazilianStandard(requestStatus.propostastatus[0].stat_datahora)
    : "";

  return (
    <Container status={requestStatus?.status.ppst_status || 0}>
      <div className="mt-40 d-flex flex-column gap-2">
        <HorizontalSeparator />
        <Label className="text-roboto-fs14-lh16-fw400-blue700">Usuário</Label>
        <span className="text-roboto-fs20-lh23-fw400-blue600">
          {nameAdministrator}
        </span>
        <HorizontalSeparator />
        <Label className="text-roboto-fs14-lh16-fw400-blue700">Data</Label>
        <span className="text-roboto-fs20-lh23-fw400-blue600">
          {dateTime.length > 0
            ? `${dateTime[0]} ás ${dateTime[1]}`
            : "Sem Informação"}
        </span>
        <HorizontalSeparator />
      </div>
      <div className="d-flex flex-row gap-1 ">
        <Button
          variant="blue"
          className="text-montserrat-fs16-fw400"
          onClick={closeRequestFeedback}
        >
          Fechar
        </Button>
      </div>
    </Container>
  );
}
