import { Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import List from "layout/DisplayItemTable/List";
import BodyPanel from "components/BodyPanel";
import DataCard from "components/DataCard";

import { UtilitiesDate } from "utils/utilities.date";
import CurrencyUtilities from "utils/currencyFormatting";

import { closeDisplayContractAction } from "model/actions/action.contracts";

export default function DisplayContract() {
  const dispatch = useAppDispatch();
  const { displayContract } = useAppSelector((state) => state.contractsReducer);

  const contract = displayContract.data;

  const data = {
    requestDate: contract?.ppst_dthrsolicitacao
      ? UtilitiesDate.convertFormatDateTimeApiToBrazilianWithouTime(
          contract?.ppst_dthrsolicitacao
        )
      : "",

    loanAmountn: contract?.vlr_solicitado
      ? CurrencyUtilities.formatCurrencyToBrazilianStandard(
          contract?.vlr_solicitado
        )
      : "",

    monthlyInterestRate: contract?.ppst_txjurosmensal
      ? CurrencyUtilities.formatCurrencyToBrazilianStandard(
          contract?.ppst_txjurosmensal
        )
      : "",

    annualInterestRate: contract?.ppst_txjurosanual
      ? CurrencyUtilities.formatCurrencyToBrazilianStandard(
          contract?.ppst_txjurosanual as number
        )
      : "",

    monthlyEffectiveCost: contract?.ppst_cetmensal
      ? CurrencyUtilities.formatCurrencyToBrazilianStandard(
          contract?.ppst_cetmensal as number
        )
      : "",

    annualEffectiveCost: contract?.ppst_cetmensal
      ? CurrencyUtilities.formatCurrencyToBrazilianStandard(
          contract?.ppst_cetmensal as number
        )
      : "",

    installmentValue: contract?.ppst_valorparcela
      ? CurrencyUtilities.formatCurrencyToBrazilianStandard(
          contract?.ppst_valorparcela as number
        )
      : "",
  };

  return (
    <BodyPanel
      show={displayContract.show}
      title="Detalhes do contrato"
      onClose={() => dispatch(closeDisplayContractAction())}
    >
      <Row className="row">
        <Col md={5} className="d-flex flex-row flex-wrap ">
          <div className="flex-grow-1">
            <List label="Nome Completo" value={contract?.func_nome} />
            <List label="CPF" value={contract?.func_cpf} />
            <List label="CNPJ" value={contract?.emp_cnpj} />
            <List label="Data da solicitação" value={data.requestDate} />
            <List
              label="E-mail"
              value={contract?.func_email || "Sem informação"}
            />
          </div>
        </Col>
        <Col md={7}>
          <Row>
            <Col md={6} className="g-3">
              <DataCard
                label="Tipo de contrato"
                value={`${contract?.tipo.label}`}
              />
            </Col>
            <Col md={6} className="g-3">
              <DataCard label="N° CCB" value={`${contract?.ppst_numeroccb}`} />
            </Col>
          </Row>

          <Row>
            <Col md={6} className="g-3">
              <DataCard
                label="Valor solicitado"
                value={`R$ ${data.loanAmountn}`}
              />
            </Col>
            <Col md={6} className="g-3">
              <DataCard
                label="Taxa a.m / a.a"
                value={`${data.monthlyInterestRate}% / ${data.annualInterestRate}%`}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6} className="g-3">
              <DataCard label="Prazo" value={`${contract?.ppst_prazo} Meses`} />
            </Col>

            <Col md={6} className="g-3">
              <DataCard
                label="Valor da parcela"
                value={`R$ ${data.installmentValue}`}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="g-3">
              <DataCard label="Parcelas pagas" value={`${contract?.parcelas_pagas} de ${contract?.ppst_prazo}`} />
            </Col>
          </Row>
        </Col>
      </Row>
    </BodyPanel>
  );
}
