import InputMask from "./InputMask";
import IInputMask from "interfaces/interface.input-mask";
import CurrencyUtilities from "utils/currencyFormatting";
import "./style.css";

type IProps = Omit<IInputMask, "onChange"> & { onChange?: (event: string) => void };

export default function Input({
  type,
  value,
  mask,
  maskType,
  onChange,
  ...rest
}: IProps) {
  const { addRealtimeCurrencyMask } = new CurrencyUtilities();

  const types = [
    { type: "cel-phone", mask: "(99) 99999-9999" },
    { type: "cpf", mask: "999.999.999-99" },
    { type: "zip", mask: "99999-999" },
    { type: "cnpj", mask: "99.999.999/9999-99" },
    { type: "credit-card", mask: "9999 9999 9999 9999" },
    { type: "credit-card-obfuscated", mask: "**** **** **** 9999" },
  ];

  if (types.findIndex((element) => element.type === maskType) !== -1) {
    const format = types.find((element) => element.type === maskType);
    return (
      <InputMask mask={format?.mask} value={value} onChange={onChange} {...rest} />
    );
  } else if (maskType === "custom") {
    return (
      <InputMask mask={mask} value={value} onChange={onChange} {...rest} />
    );
  } else if (maskType === "currency" || maskType === "percentage") {
    return (
      <input
        type="text"
        value={value}
        maxLength={maskType === "currency" ? 12 : 5}
        onChange={(event) =>
          onChange &&
          onChange(addRealtimeCurrencyMask(event.target.value) as string)
        }
        {...rest}
      />
    );
  } else if (maskType === "name-cpf") {
    return (
      <input
        type={type}
        value={
          isNaN(Number(`${value}`[0])) ? value : `${value}`.replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
        }
        onChange={(event) => onChange && onChange(event.target.value)}
        {...rest}
      />
    );
  } else {
    return (
      <input
        type={type}
        value={value}
        onChange={(event) => onChange && onChange(event.target.value)}
        {...rest}
      />
    );
  }
}
