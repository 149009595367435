import { object, string, number, array } from "yup";

export const administratorUpdateSchema = object({
  name: string().required(),
  email: string().required(),
  functions: array().required(),
  // notify: number().required(),
  status: number().required(),
  companies: array().required().min(1)
});
 