import { useContext } from "react";
import { Container, Navbar as BootstrapNavbar } from "react-bootstrap";

import InmanoIconTransparent from "assets/img/inmano-icon-transparent.svg";

import {
  ContexMobileSidebar,
} from "context/MobileSiderbar/ContextMobileSidebar";

import "./style.css";

export default function Navbar() {
  const { onOpen } = useContext(ContexMobileSidebar);

  return (
      <BootstrapNavbar bg="white" expand="lg" className="mobile-navbar">
        <Container className="d-flex justify-content-between">
          <BootstrapNavbar.Brand className="py-4">
            <img
              src={InmanoIconTransparent}
              alt="Icone colorido da InMano com fundo branco."
            />
          </BootstrapNavbar.Brand>
          <button
            className="bg-transparent border border-1 p-3"
            onClick={onOpen}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </Container>
      </BootstrapNavbar>
  );
}
