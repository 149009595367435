import { useState } from "react";

import Button from "components/Button";
import Label from "components/Label";
import ReactSelect from "components/ReactSelect";

import { Companies } from "utils/company";
import ISelectCompanyOptions from "interfaces/interface.select-company-options";

type IProps = {
  onChange: (value: any) => void;
  value: ISelectCompanyOptions[];
};

export function InputCompanies({
  onChange,
  value,
}: IProps) {
  const { getLisCompanies } = new Companies();

  const [selectValue, setSelectValue] = useState("");
  const [options, setOptions] = useState(getLisCompanies());

  const listSize = value.length;
  const selectIsDisabled = listSize === getLisCompanies().length;

  function changeSelectedCompaniesList(value: any) {
    onChange(value);
  }

  function addAllCompanies() {
    onChange([]);
    onChange(getLisCompanies());
  }

  function removeAllCompanies() {
    onChange([]);
  }

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-6">
          <Label className="text-roboto-fs20-lh29-fw700-blue600 mt-3 mb-4">
            Empresa(s) do administrador
          </Label>
          <ReactSelect
            isMulti
            options={options}
            forceChoice
            isDisabled={selectIsDisabled}
            onChange={changeSelectedCompaniesList}
            onInputChange={(value) =>
              setSelectValue(value)}
            inputValue={selectValue}
            value={value}
          />
        </div>
        <div className="row">
          <div className="col-md-6 gy-3">
            <p className="text-roboto-fs14-lh16-fw400-blue700 mb-0 ms-2">
              <span className="text-roboto-fs14-lh16-fw700-pink500 me-1">
                {listSize || 0}
              </span>
              empresa(s) selecionada(s)
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 d-flex align-items-center flex-wrap gap-2 gy-4">
          <Button
            type="button"
            variant="green"
            className="text-roboto-fs14-lh16-fw400"
            onClick={addAllCompanies}
            disabled={selectIsDisabled}
          >
            Selecionar todas
          </Button>
          <Button
            type="button"
            variant="pink"
            className="text-roboto-fs14-lh16-fw400"
            onClick={removeAllCompanies}
          >
            Remover todas
          </Button>
        </div>
      </div>
    </div>
  );
}
