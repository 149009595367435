import Input from "components/Input";

type IProps = {
  administratorFunction: string;
  descriptionFunction: string;
  selectedFunctions: any[];
  functionId: number;
  onChange: (functionId: number) => void;
};

export function CheckboxFunction({
  administratorFunction,
  descriptionFunction,
  selectedFunctions,
  functionId,
  onChange,
}: IProps) {
  return (
    <div className="row administrator-panel-function">
      <div className="col-1 d-flex justify-content-center">
        <Input
          type="checkbox"
          checked={selectedFunctions.includes(functionId as never)}
          onChange={() => onChange(functionId)}
        />
      </div>
      <div className="col-11 d-flex flex-column gap-1">
        <span className="text-roboto-fs20-lh20-fw400-blue600">
          {administratorFunction}
        </span>
        <span className="text-roboto-fs14-lh20-fw400-blue600">
          {descriptionFunction}
        </span>
      </div>
    </div>
  );
}
