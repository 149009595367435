import { AnyAction } from "redux";
import produce from "immer";

import {
  CHANGE_DATA_FORMCOLLABORATOR,
  SET_FILTER_BY_COMPANY_COLLABORATORS_ACTION,
  DOWNLOAD_HISTORY_WORKSHEET_COLLABORATORS,
  GET_WORKSHEET_SHUTDOWN_MODEL,
  GET_WORKSHEET_UPDATE_MODEL,
  SEARCH_COLLABORATORS,
  SEARCH_COLLABORATORS_ENDORSEMENT,
  SEARCH_COLLABORATORS_UPLOAD_HISTORY,
  OPEN_DETAILS_PANEL_COLLABORATOR,
  CLOSE_DETAILS_PANEL_COLLABORATOR,
  OPEN_COLLABORATOR_DATA_PANEL,
  CLOSE_COLLABORATOR_DATA_PANEL,
  OPEN_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET,
  CLOSE_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET,
  FILTER_COLLABORATORS_BY_COLLABORATOR,
  CHANGE_SELECTED_COLLABORATORS,
  SET_TABLE_DATA_COLLABORATORS,
  EXPORT_COLLABORATORS,
} from "model/action-types/type.collaborators";
import { CLEAR_STATE } from "model/action-types/type.general";
import {
  CHANGE_EMPLOYEE_STATUS,
  SUBMIT_COLLABOARATOR,
  SUBMIT_SHUTDOWN_WORKSHEET,
  SUBMIT_UPLOAD_WORKSHEET,
} from "model/action-types/type.collaborators-submit";
import {
  CLOSE_ENDORSEMENT_PANEL,
  OPEN_ENDORSEMENT_PANEL,
} from "model/action-types/type.collaborators-endorsement";
import { PropsCollaboratorsReducer } from "model/reducers-types/type.collaboratorsReducer";

const INITIAL_STATE: PropsCollaboratorsReducer = {
  data: [],
  currentPage: 0,
  lastPage: 0,
  total: 0,
  detailPanelCollaborator: {
    show: false,
    data: {},
  },
  endorsementPanel: {
    show: false,
    id: null,
  },
  collaboratorsOptions: null,
  modalFeedbackShutdownWorksheet: { show: false },
  dataPanel: {
    show: false,
    data: {
      func_nome: "",
      func_cpf: "",
      func_dtnascimento: "",
      func_matricula: "",
      func_dtadmissao: "",
      func_salariobruto: "",
      func_salarioliquido: "",
      emp_id: 0,
      func_dtfimcontrato: "",
      func_margem: "",
    },
  },
  filterByCompany: null,
  shudownModal: {
    show: false,
  },
  filterByCollaborator: "",
  selectedCollaborators: [],
  hasMargin: false,
  hasAnEndDateOfTheContract: false,
};

export function collaboratorsReducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case SEARCH_COLLABORATORS:
      return state;
    case CHANGE_EMPLOYEE_STATUS:
      return state;
    case OPEN_DETAILS_PANEL_COLLABORATOR:
      return produce(state, (draft) => {
        draft.detailPanelCollaborator.show = true;
        draft.detailPanelCollaborator.data = action.collaborator;
      });
    case CLOSE_DETAILS_PANEL_COLLABORATOR:
      return produce(state, (draft) => {
        draft.detailPanelCollaborator = INITIAL_STATE.detailPanelCollaborator;
      });
    case SUBMIT_UPLOAD_WORKSHEET:
      return state;
    case SUBMIT_SHUTDOWN_WORKSHEET:
      return state;
    case OPEN_ENDORSEMENT_PANEL:
      return produce(state, (draft) => {
        draft.endorsementPanel = {
          show: true,
          id: action.collaboratorId,
        };
      });
    case CLOSE_ENDORSEMENT_PANEL:
      return produce(state, (draft) => {
        draft.endorsementPanel = {
          show: false,
          id: null,
        };
      });
    case OPEN_COLLABORATOR_DATA_PANEL:
      return produce(state, (draft) => {
        draft.dataPanel.show = true;
        draft.dataPanel.data = action.data;
      });
    case CLOSE_COLLABORATOR_DATA_PANEL:
      return produce(state, (draft) => {
        draft.dataPanel = INITIAL_STATE.dataPanel;
      });
    case CHANGE_DATA_FORMCOLLABORATOR:
      return produce(state, (draft) => {
        draft.hasAnEndDateOfTheContract =
          action.value.hasAnEndDateOfTheContract;
        draft.hasMargin = Boolean(action.value.hasMargin);
        draft.dataPanel.data = {
          ...state.dataPanel.data,
          ...action.value.form,
        };
      });
    case SUBMIT_COLLABOARATOR:
      return state;
    case SEARCH_COLLABORATORS_UPLOAD_HISTORY:
      return state;
    case DOWNLOAD_HISTORY_WORKSHEET_COLLABORATORS:
      return state;
    case SEARCH_COLLABORATORS_ENDORSEMENT:
      return state;
    case SET_FILTER_BY_COMPANY_COLLABORATORS_ACTION:
      return produce(state, (draft) => {
        draft.filterByCompany = action.empId;
      });
    case GET_WORKSHEET_SHUTDOWN_MODEL:
      return state;
    case GET_WORKSHEET_UPDATE_MODEL:
      return state;
    case OPEN_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET:
      return produce(state, (draft) => {
        draft.shudownModal.show = true;
      });
    case CLOSE_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET:
      return produce(state, (draft) => {
        draft.shudownModal.show = false;
      });
    case FILTER_COLLABORATORS_BY_COLLABORATOR:
      return produce(state, (draft) => {
        draft.filterByCollaborator = action.value;
      });
    case CHANGE_SELECTED_COLLABORATORS:
      return produce(state, (draft) => {
        draft.selectedCollaborators = action.collaboratorId;
      });
    case SET_TABLE_DATA_COLLABORATORS:
      return produce(state, (draft) => {
        draft.data = action.data.data;
        draft.currentPage = action.data.current_page;
        draft.lastPage = action.data.last_page;
        draft.total = action.data.total;
      });
    case EXPORT_COLLABORATORS:
      return state;
    case CLEAR_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
}
