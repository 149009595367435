import { all, put, select, takeLatest } from "redux-saga/effects";
import Http from "services/httpClient";

import {
  REQUEST_FILTER_BY_COMPANY,
  REQUEST_FILTER_BY_STATUS,
  SEARCH_REQUESTS,
} from "model/action-types/type.requests";

import {
  clearListOfSelectedProposalsAction,
  setRequestsAction,
} from "model/actions/action.request";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";
import { finishLoadingAction, startLoadingAction } from "model/actions/action.general";

function* searchRequests(action: any): any {
  yield put(startLoadingAction());

  const store = yield select();
  const status = store.requestsReducer.requestStatusFilter;
  const empId = store.requestsReducer.companyFilter;

  const http = new Http();

  const response = yield http.httpClientGet({
    url: `empresa/proposta/solicitacoes?page=${action.page || 1}&ppst_status=${
      status !== null ? +status : "0,1,6"
    }${empId !== null ? "&emp_id=" + empId : ""}`,
  });

  yield put(finishLoadingAction());

  if (response.error) {
    yield put(openFeedbackModalAction(response.message, "error"));
    return false;
  }

  yield put(setRequestsAction(response));
  // yield put(clearListOfSelectedProposalsAction());
}

export default all([
  takeLatest(SEARCH_REQUESTS, searchRequests),
  takeLatest(REQUEST_FILTER_BY_STATUS, searchRequests),
  takeLatest(REQUEST_FILTER_BY_COMPANY, searchRequests),
]);
