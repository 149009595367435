import IDiv from "interfaces/html/interface.div";
import { ReactNode } from "react";
import { ContainerState } from "./styled";

interface IProps extends IDiv {
  state: "error" | "warning" | "success";
  children: ReactNode;
}

export default function FeedbackContainer({
  state,
  children,
  className
}: IProps) {
  return <ContainerState state={state} className={`${className ? className : ""}`} >{children}</ContainerState>;
}
