import IContract from "interfaces/http-response/interface.contracts";
import {
  CLOSE_DISPLAY_CONTRACT,
  DOWNLOAD_CCB_CONTRACT,
  FILTER_CONTRACTS_BY_COLLABORATOR,
  FILTER_CONTRACTS_BY_COMPANY,
  FILTER_CONTRACTS_BY_STATUS,
  OPEN_DISPLAY_CONTRACT,
  SEARCH_CONTRACTS,
  SET_TABLE_DATA_CONTRACTS,
} from "model/action-types/type.contracts";
import { PropsContractsReducer } from "model/reducers-types/type.contracts";

export function searchContractsAction(page?: number, typeFilter?: number) {
  return { type: SEARCH_CONTRACTS, page, typeFilter };
}

export function setTableDataContractsAction(data: any) {
  return { type: SET_TABLE_DATA_CONTRACTS, data };
}

export function filterContractsByCollaboratorAction(identification: string) {
  return { type: FILTER_CONTRACTS_BY_COLLABORATOR, identification };
}

export function filterContractsByCompanyAction(empId: number) {
  return { type: FILTER_CONTRACTS_BY_COMPANY, empId };
}

export function filterContractsByStatusAction(
  status: PropsContractsReducer["filterByStatus"]
) {
  return { type: FILTER_CONTRACTS_BY_STATUS, status };
}

export function openDisplayContractAction(data: IContract) {
  return { type: OPEN_DISPLAY_CONTRACT, data };
}

export function closeDisplayContractAction() {
  return { type: CLOSE_DISPLAY_CONTRACT };
}

export function downloadCbbContractAction(url: string) {
  return { type: DOWNLOAD_CCB_CONTRACT, url };
}
