import IButton from "interfaces/html/interface.button";
import { PropsDashboardReducer } from "model/reducers-types/type.dashboard";
import { globalNavigate } from "services/router";
import Animated from "./Animated";
import "./style.css";

interface IProps extends IButton {
  isLoading: boolean;
  data: PropsDashboardReducer["pendencies"];
}

export default function Indicator({ isLoading, data }: IProps) {
  if (isLoading)
    return (
      <div className="mt-4 d-flex flex-column gap-3">
        {[0, 1].map((_, key) => (
          <Animated key={key} />
        ))}
      </div>
    );

  return (
    <div className="mt-4 d-flex flex-column gap-3">
      {data.map((item, key) => (
        <button
          key={key}
          className="dashboard-indicator-container w-100"
          onClick={() => globalNavigate(item.link)}
        >
          <span className="text-roboto-fs16-lh19-fw700-blue700">
            {item.label}
          </span>
          <div className="dashboard-indicator-separator" />
          <span className="text-roboto-fs50-lh30-fw400-orange500">
            {item.quantidade}
          </span>
        </button>
      ))}
    </div>
  );
}
