import {
  APPROVE_A_REQUEST,
  APPROVE_A_REQUEST_LIST,
  EXPORT_REQUESTS,
  REFUSE_A_REQUEST,
} from "model/action-types/type.requests-submit";

export function exportRequestsAction() {
  return { type: EXPORT_REQUESTS };
}

export function approveRequestAction(ppstId: number) {
  return { type: APPROVE_A_REQUEST, ppstId };
}

export function refuseRequestAction(ppstId: number, reasonForRefusal: string, reason: number) {
  return { type: REFUSE_A_REQUEST, ppstId, reasonForRefusal, reason };
}

export function approveARequestListAction() {
  return { type: APPROVE_A_REQUEST_LIST };
}