import styled from "styled-components";

interface IndicatorProps {
  color: string;
}
export const Indicator = styled.div<IndicatorProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${(props) => props.color };
`;
