import { useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";
import Button from "components/Button";
import ReactSelect from "components/ReactSelect";

import { ProposalRefuse } from "utils/proposalRefuse";

import HorizontalSeparator from "layout/HorizontalSeparator/styled";
import {
  ApprovalButton,
  RefusedButton,
  TextAreaContestationPanel,
} from "pages/Requests/components/RequestStatus/styled";
import DelikeIcon from "assets/img/deslike-icon.svg";
import DeslikeWhiteIcon from "assets/img/deslike-white-icon.svg";
import LikeWhiteIcon from "assets/img/like-white-icon.svg";
import LikeIcon from "assets/img/like-icon.svg";

import {
  approveRequestAction,
  refuseRequestAction,
} from "model/actions/action.request-submit";
import Container from "./Container";
import { closeRequestStatusAction } from "model/actions/action.request";

export default function Dialog() {
  const dispatch = useAppDispatch();
  const { requestStatus } = useAppSelector((state) => state.requestsReducer);

  const [status, setStatus] = useState<0 | 1 | 6>(
    requestStatus?.status.ppst_status || 0
  );

  const { getLisProposalRefuses } = new ProposalRefuse();
  const proposalRefuses = getLisProposalRefuses();
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [reason, setReason] = useState(null);

  const [approvedOver, setApprovedOver] = useState(false);
  const [refusedOver, setRefusedOver] = useState(false);
  const [reasonForRefusal, setReasonForRefusal] = useState("");

  const buttonCofirmDisabled =
    (status === 6 && isOtherSelected && reasonForRefusal === "") || status === 0 || (status === 6 && typeof reason !== "number" );

  const buttonConfirmLabel = status !== null ? "Confirmar" : "Fechar";

  function closeRequestFeedback() {
    dispatch(closeRequestStatusAction());
  }

  function approveRequest() {
    setStatus(1);
  }

  function refuseRequest() {
    setStatus(6);
  }

  function approvedMouseOver() {
    setApprovedOver(true);
  }

  function approvedMouseOut() {
    setApprovedOver(false);
  }

  function refusedMouseOver() {
    setRefusedOver(true);
  }

  function refusedMouseOut() {
    setRefusedOver(false);
  }

  function onChangeReason(event: any){
    if(event.isOther === 1){
      setIsOtherSelected(true);
    }else{
      setIsOtherSelected(false);
    }
    setReason(event.value);
  }

  function changeRequestStatus() {
    if (status === 1) {
      dispatch(approveRequestAction(requestStatus?.ppst_id as number));
    } else if(typeof reason === "number" ){
      dispatch(
        refuseRequestAction(requestStatus?.ppst_id as number, reasonForRefusal, reason as number)
      );
    }
  }

  return (
    <Container status={status}>
      <div className="mt-40 d-flex flex-column gap-2">
        <HorizontalSeparator className="mt=5" />
        <p className="text-roboto-fs20-lh29-fw400-blue600 text-justify">
          Empréstimo pendente de aprovação. Ao aprovar essa proposta, um novo
          empréstimo é gerado. Você confirma essa proposta?
        </p>
        <HorizontalSeparator />
        <div className="d-flex flex-row gap-3">
          <ApprovalButton
            isClicked={status === 1}
            onClick={approveRequest}
            onMouseOver={approvedMouseOver}
            onMouseOut={approvedMouseOut}
          >
            <img
              src={approvedOver || status ? LikeWhiteIcon : LikeIcon}
              alt=""
            />
          </ApprovalButton>
          <RefusedButton
            isClicked={status === 6}
            onClick={refuseRequest}
            onMouseOver={refusedMouseOver}
            onMouseOut={refusedMouseOut}
          >
            <img
              src={refusedOver || status === 6 ? DeslikeWhiteIcon : DelikeIcon}
              alt=""
            />
          </RefusedButton>
        </div>
        {status === 6 && (
          <div className="d-flex flex-column gap-3 mt-4">
            <h2 className="text-roboto-fs16-lh18-fw400-blue600 fw-bold">
              Qual o motivo da recusa?
            </h2>
            <ReactSelect heightOptionsContainer={"13.5rem"} options={proposalRefuses} forceChoice isMulti={false} onChange={onChangeReason}/>
            {isOtherSelected ? 
              <TextAreaContestationPanel
                autoFocus
                className="text-roboto-fs14-lh16-fw400-blue700"
                onChange={(event) => setReasonForRefusal(event.target.value)}
              ></TextAreaContestationPanel>
              : 
              null
            }
            
          </div>
        )}
      </div>
      <div className="d-flex flex-row gap-1 mt-5">
        <Button
          variant="blue"
          className="text-montserrat-fs16-fw400"
          disabled={buttonCofirmDisabled}
          onClick={changeRequestStatus}
        >
          {buttonConfirmLabel}
        </Button>
        <Button
          variant="blue"
          isActive={false}
          className="text-montserrat-fs16-fw400"
          onClick={closeRequestFeedback}
        >
          Cancelar
        </Button>
      </div>
    </Container>
  );
}
