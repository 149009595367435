import ISelectOptions from "interfaces/interface.select-options";
import { useState } from "react";
import Http from "services/httpClient";
import { useDebounce } from "./hook.useDebounce";

export const useSearchCollaborator = () => {
  const [data, setData] = useState<ISelectOptions[]>([]);

  const { debounce } = useDebounce();

  function emitEvent(event: any) {
    debounce(mergeData, event);
  }

  function mergeData(event: any) {
    getCollaborator(event).then((result) => {
      const formattedResult = result.map(mapCollaborators);
      setData(formattedResult);
    });
  }

  async function getCollaborator(event: any) {
    const value = event.target?.value;

    const http = new Http();
    const response = await http.httpClientGet({
      url: `empresa/filtrarfuncionario?query=${value}`,
    });

    return response.data || [];
  }

  function mapCollaborators(data: any) {
    return {
      value: data.func_id,
      label: `${data.func_nome} / ${data.func_cpf}`,
    };
  }

  function reset() {
    setData([]);
  }

  return { emitEvent, data, reset };
};
