import styled from "styled-components";

export const BaseHelpButton = styled.button`
  width: fit-content;
  height: fit-content;
  position: fixed;
  bottom: 3.6rem;
  right: 1.6rem;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.5));
  background: linear-gradient(90deg, #2a3b5a 0%, #bb527d 51.56%, #f79463 100%);
`;

export const BaseHelpModalContainer = styled.div`
  width: 35rem;
  height: 100%;
  padding: 4.6rem 4.4rem;
  background: #ffffff;
  border-radius: 30px;
`;

export const BaseHelpTextArea = styled.textarea`
  border: 1px solid #dadada;
  border-radius: 3rem;
  width: 100%;
  min-height: 15rem;
  padding: 1.3rem;
`;
