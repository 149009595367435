import {
  CHANGE_NOTIFY_SETTING_PROFILE,
  CHANGE_PASSWORD_PROFILE,
} from "model/action-types/type.profile-submit";

export function changePasswordProfileAction(
  currentPassword: string,
  newPassword: string,
  confirmationNewPassword: string
) {
  return {
    type: CHANGE_PASSWORD_PROFILE,
    currentPassword,
    newPassword,
    confirmationNewPassword,
  };
}

export function changeNotifySettingProfileAction(rhNotId: number) {
  return { type: CHANGE_NOTIFY_SETTING_PROFILE, rhNotId };
}
