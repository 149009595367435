import { AnyAction } from "redux";
import {
  SEARCH_ADMINISTRATORS,
  SET_ADMINISTRATORS,
} from "model/action-types/type.administrators";
import produce from "immer";
import {
  CLOSE_DETAILS_PANEL,
  OPEN_DETAILS_PANEL,
  SET_COMPANY_DETAILS_PANEL,
} from "model/action-types/type.administrators-details-panel";
import { SET_NOTIFICATIONS } from "model/action-types/type.administrators-notifications";
import { SET_FUNCTIONS } from "model/action-types/type.administrators-functions";
import { CLEAR_STATE } from "model/action-types/type.general";
import { SUBMIT_ADMINISTRATOR } from "model/action-types/type.administrator-submit";
import { PropsAdministratorReducer } from "model/reducers-types/type.administratorReducer";

const INITIAL_STATE: PropsAdministratorReducer = {
  lastPage: 0,
  currentPage: 0,
  total: 0,
  administrators: [],
  detailsPanel: { show: false },
  notifications: null,
  listOfFunctions: null,
  companies: [],
};

export function administratorsReducer(
  state = INITIAL_STATE,
  action: AnyAction
) {
  switch (action.type) {
    case SEARCH_ADMINISTRATORS:
      return state;
    case SET_ADMINISTRATORS:
      return produce(state, (draft) => {
        draft.administrators = action.data.data;
        draft.currentPage = action.data.current_page;
        draft.lastPage = action.data.last_page;
        draft.total = action.data.total;
      });
    case OPEN_DETAILS_PANEL:
      return produce(state, (draft) => {
        draft.detailsPanel.admin = action.administrator;
        draft.detailsPanel.show = true;
      });
    case CLOSE_DETAILS_PANEL:
      return produce(state, (draft) => {
        draft.detailsPanel = INITIAL_STATE.detailsPanel;
      });
    case SET_NOTIFICATIONS:
      return produce(state, (draft) => {
        draft.notifications = action.notifications.data;
      });
    case SET_FUNCTIONS:
      return produce(state, (draft) => {
        draft.listOfFunctions = action.data.data;
      });
    case SET_COMPANY_DETAILS_PANEL:
      return produce(state, (draft) => {
        draft.companies = action.companies;
      });
    case SUBMIT_ADMINISTRATOR:
      return state;
    case CLEAR_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
}
