import "./style.css";

interface IProps {
  stages: number;
  currentStage: number;
}

export default function StageProgress({ currentStage, stages }: IProps) {
  var arr = [];
  for (let index = 0; index < stages; index++) {
    arr[index] = index;
  }

  function setThemeStep(stage: number) {
    if (stage === currentStage) {
      return "collaborator-panel-stageprogress-indicator-active";
    } else if (stage <= currentStage) {
      return "collaborator-panel-stageprogress-indicator-activated";
    } else {
      return "collaborator-panel-stageprogress-indicator-inactive";
    }
  }

  return (
    <div className="d-flex flex-row align-items-center justify-content-center collaborator-panel-stageprogress-container">
      {arr.map((item, key) =>
        item === stages - 1 ? (
          <span
            key={key}
            className={`collaborator-panel-stageprogress-indicator text-roboto-fs14-lh30-fw700 ${setThemeStep(
              item
            )}`}
          >
            {item + 1}
          </span>
        ) : (
          <div key={key} className="d-flex align-items-center">
            <span
              className={`collaborator-panel-stageprogress-indicator text-roboto-fs14-lh30-fw700 ${setThemeStep(
                item
              )}`}
            >
              {item + 1}
            </span>
            <div className={`collaborator-panel-stageprogress-bar`} />
          </div>
        )
      )}
    </div>
  );
}
