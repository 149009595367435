import { useAppDispatch } from "hooks/hooks.redux";

import Animated from "./Animated";
import Button from "components/Button";
import DataCard from "components/DataCard";

import IResumeConciliation from "interfaces/interface.conciliation-resume";
import { conciliationDownloadTicketAction } from "model/actions/action.conciliations-submit";
import CurrencyUtilities from "utils/currencyFormatting";
import { UtilitiesDate } from "utils/utilities.date";

type IProps = {
  data: IResumeConciliation["dados_boleto"];
  isLoading: boolean;
  disableActions: boolean;
};

export default function TicketDataPanel({
  data,
  isLoading,
  disableActions,
}: IProps) {
  const dispatch = useAppDispatch();

  if (isLoading) return <Animated />;

  const creationDate =
    UtilitiesDate.convertFormatDateTimeApiToBrazilianWithouTime(
      data.data_criacao as string
    );

  const dueDate = UtilitiesDate.convertFormatDateApiToBrazilian(
    data.data_vencimento as string
  );

  const ticketValue = CurrencyUtilities.formatCurrencyToBrazilianStandard(
    data.valor as string
  );

  const status = data.status;

  function downloadTicket() {
    dispatch(conciliationDownloadTicketAction());
  }

  return (
    <div className="row conciliations-conciliation-panel-container">
      <div className="col-12 d-flex flex-row align-items-center flex-wrap gap-3">
        <DataCard
          label="Boleto"
          value={ticketValue}
          valueClass="text-roboto-fs20-lh23-fw400-orange500"
        />
        <DataCard
          label="Data de criação"
          value={creationDate}
          valueClass="text-roboto-fs20-lh23-fw400-orange500"
        />
        <DataCard
          label="Data de vencimento"
          value={dueDate}
          valueClass="text-roboto-fs20-lh23-fw400-orange500"
        />
        <DataCard
          label="Status do Boleto"
          value={status.label}
          valueClass="text-roboto-fs20-lh23-fw400-orange500"
        />

        {!disableActions && (
          <Button
            variant="pink"
            className="text-montserrat-fs16-lh19-fw400 ms-auto"
            onClick={downloadTicket}
          >
            Visualizar Boleto
          </Button>
        )}
      </div>
    </div>
  );
}
