import Select, { ActionMeta, GroupBase, OptionsOrGroups } from "react-select";

import "./style.css";

interface IProps {
  options?: any;
  onChange?: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
  forceChoice?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  onInputChange?: (newValue: string) => void;
  inputValue?: string;
  value?: any;
  placeholder?: string;
  borderColor?: string;
  height?: string | number;
  heightOptionsContainer?: string | number;
  width?: string | number;
  isAsync?: boolean;
  loadOptions?: any;
}

export default function ReactSelect({
  options = [],
  onChange,
  forceChoice = false,
  isMulti = false,
  isDisabled = false,
  onInputChange,
  inputValue = "",
  value,
  placeholder,
  borderColor = "#3d5375",
  height,
  heightOptionsContainer,
  width,
}: IProps) {
  const customStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      width: width || "auto",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: "1.4rem",
      color: state.isSelected ? "var(--white)" : "var(--blue-700)",
      backgroundColor: state.isSelected ? "var(--blue-700)" : "var(--white)",
      padding: "0.5rem 1rem",
      fontFamily: "Roboto",
      zIndex: 999,
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      height: heightOptionsContainer,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isDisabled ? "#b9b9b9" : borderColor,
      borderWidth: "1.5px",
      borderStyle: "solid",
      display: "flex",
      backgroundColor: state.isDisabled
        ? "hsl(0, 0%, 95%)"
        : "hsl(0, 0%, 100%)",
      borderRadius: "3rem",
      maxHeight: "fit-content",
      height: height,
      padding: "0.1rem",
      paddingLeft: "1.2rem",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      alignItems: "center",
      display: "flex",
      flex: "1",
      flexFlow: "row wrap",
      padding: "2px 8px",
      overflow: "auto",
      maxHeight: "15rem",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "hsl(0, 0%, 50%)",
      gridArea: " 1/1/2/3",
      marginLeft: "2px",
      marginRight: "2px",
      fontSize: "1.4rem",
    }),
    input: (provided: any) => ({
      ...provided,
      color: "var(--blue-700)",
      padding: "0",
      fontSize: "1.4rem",
      fontFamily: "Roboto",
      flex: "1 1 auto",
      display: "inline-grid",
      gridArea: "1/1/2/3",
      gridTemplateColumns: "0 min-content",
    }),
    loadingMessage: (provided: any) => ({
      ...provided,
      fontFamily: "Roboto",
      fontSize: "1.4rem",
    }),
    noOptionsMessage: (provided: any) => ({
      ...provided,
      fontFamily: "Roboto",
      fontSize: "1.4rem",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      display: "flex",
      padding: "0.5rem",
      borderColor: "var(--blue-600)",
      borderWidth: "0.5px",
      borderStyle: "solid",
      borderRadius: "0.5rem",
      margin: "0.7rem",
      gap: "0.5rem",
      width: "fit-content",
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "var(--blue-600)",
      fontSize: "1.4rem",
      fontFamily: "Roboto",
    }),
    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const fontSize = "1.4rem";
      const color = state.isSelected ? "var(--white)" : "var(--blue-700)";
      const backgroundColor = state.isSelected
        ? "var(--blue-700)"
        : "var(--white)";
      const fontFamily = "Roboto";
      const maxWidth = "80%";

      return {
        ...provided,
        opacity,
        transition,
        fontSize,
        color,
        backgroundColor,
        fontFamily,
        maxWidth,
      };
    },
  };

  const allOptions: OptionsOrGroups<unknown, GroupBase<unknown>> = forceChoice
    ? options
    : [
        {
          label: "Todas as opções",
          value: null,
        },
        ...options,
      ];

  return (
    <>
      {isMulti ? (
        <Select
          onChange={onChange}
          options={allOptions}
          styles={customStyles}
          placeholder={placeholder || "Selecione"}
          isMulti={isMulti}
          isDisabled={isDisabled}
          onInputChange={(event) => onInputChange && onInputChange(event)}
          inputValue={inputValue}
          value={value}
          loadingMessage={() => "Buscando..."}
          noOptionsMessage={() => "Sem resultados"}
        />
      ) : (
        <Select
          onChange={onChange}
          options={allOptions}
          styles={customStyles}
          placeholder={placeholder || "Selecione"}
          isDisabled={isDisabled}
          onInputChange={(event) => onInputChange && onInputChange(event)}
          value={value}
          loadingMessage={() => "Buscando..."}
          noOptionsMessage={() => "Sem resultados"}
        />
      )}
    </>
  );
}
