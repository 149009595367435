import { useState } from "react";
import { useAppDispatch } from "hooks/hooks.redux";

import { getAuthenticationCodeAction } from "model/actions/action.authenticate";

import Box from "pages/Login/components/Box";
import Input from "components/Input";
import Label from "components/Label";
import Button from "components/Button";
import IconRightArrow from "assets/img/right-arrow.svg";

interface IProps {
  resetContentType: () => void;
}

export default function PasswordRecoveryForm({
  resetContentType,
}: IProps) {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");

  function getAuthenticationCode() {
    dispatch(getAuthenticationCodeAction(email));
  }

  return (
    <Box message="Preencha os campos abaixo para recuperar sua senha">
      <Label className="login-form-label text-roboto-fs14-lh16-fw400-gray400">
        E-mail
      </Label>
      <Input
        required
        value={email}
        onChange={(value) => setEmail(value)}
        className="login-form-input"
      />

      <div className="d-flex justify-content-between">
        <Button
          variant="blue"
          className="text-montserrat-fs16-lh19-fw400-white"
          onClick={getAuthenticationCode}
        >
          Recuperar
          <img
            src={IconRightArrow}
            alt="Flecha branca apontada para o lado direito"
            className="ms-3"
          />
        </Button>
        <Button
          variant="blue"
          isActive={false}
          className="border-0 font-weight-bold text-roboto-fs16-lh18-fw700-blue700"
          onClick={resetContentType}
        >
          Cancelar
        </Button>
      </div>
    </Box>
  );
}
