import { useRef } from "react";
import ReactInputMask from "react-input-mask";

export default function InputMask({ mask, value, onChange, ...rest }) {
  const inputRef = useRef(null);
  return (
    <ReactInputMask
      mask={mask}
      maskChar=""
      value={value}
      onChange={(event) => onChange(event.target.value)}
    >
      {(inputProps) => <input {...inputProps} ref={inputRef} type="tel" {...rest} />}
    </ReactInputMask>
  );
}
