export class ProposalRefuse {
  getLisProposalRefuses() {
    let listOfProposalRefuses: any[] = [];

    const data = sessionStorage.getItem("@userData");

    if (!data) return [];

    const dataJson = JSON.parse(data);

    if (!dataJson.motivos_recusa) return [];

    listOfProposalRefuses = dataJson.motivos_recusa.map((item: any) => {
        return {
        value: item.mtv_id,
        label: item.mtv_descricao,
        isOther: item.mtv_outros,
      };
    });

    return listOfProposalRefuses;
  }

  
}
