import styled, { css } from "styled-components";

type FormProfileProps = {
  status: boolean;
};

const InputDefaultState = css`
  border-bottom-color: ${(props) => props.theme.COLORS.GRAY_200};
  color: ${(props) => props.theme.COLORS.BLUE_700};
`;

const InputErrorState = css`
  border-bottom-color: ${(props) => props.theme.COLORS.RED_300};
  color: ${(props) => props.theme.COLORS.RED_300};
`;

export const InputProfile = styled.input<FormProfileProps>`
  border: 0;
  background-color: transparent;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 2rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  ${(props) => (props.status ? InputDefaultState : InputErrorState)}
  padding: 0rem 0rem 1rem 0rem;
`;

const LabelDefaultState = css`
  color: ${(props) => props.theme.COLORS.BLUE_700};
`;

const LabelErrorState = css`
  color: ${(props) => props.theme.COLORS.RED_300};
`;

export const LabelProfile = styled.span<FormProfileProps>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  ${(props) => (props.status ? LabelDefaultState : LabelErrorState)}
`;
