import { all, put, takeLatest } from "redux-saga/effects";
import Http from "services/httpClient";

import { administratorUpdateSchema } from "schemas/schema.administrator-update";
import { administratorCreateSchema } from "schemas/schema.administrator-create";
import GeneralUtilities from "utils/utilities.general";
import { AdministratorsUtilities } from "utils/utilities.administrators";

import {
  CHANGE_ADMINISTRATOR_STATUS,
  SUBMIT_ADMINISTRATOR,
} from "model/action-types/type.administrator-submit";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";
import { searchAdministratorsAction } from "model/actions/action.administrators";
import {
  closeModalLoading,
  openModalLoading,
} from "model/actions/action.modal-loading";

function* submitAdministrators(action: any): any {
  if (!action.adminId) {
    yield createAdministrator(action);
  } else {
    yield updateAdministrator(action);
  }
}

function* createAdministrator(action: any): any {
  yield put(openModalLoading());

  const formCreateIsValid = administratorCreateSchema.isValidSync(action);

  if (!action.adminId && !formCreateIsValid)
    return yield put(
      openFeedbackModalAction(
        "Para criar um administrador é necessário que todos os dados sejam informados corretamente.",
        "error"
      )
    );

  let data: any = {
    eusu_nome: action.name,
    eusu_email: action.email,
    eusu_senha: action.password,
    eusu_status: action.status,
    rhnot_id: action.notify,
    funcoes: GeneralUtilities.getAListFromAnArrayOfNumbers(action.functions),
    emp_id:
      AdministratorsUtilities.prepareCompaniesToSubmit(action.companies) || [],
  };

  const http = new Http();
  const response = yield http.httpClientPost({
    url: "empresa/empresausuario",
    data,
  });

  yield put(closeModalLoading());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(
    openFeedbackModalAction("Administrado cadastrado com sucesso!", "success")
  );

  yield put(searchAdministratorsAction());
}

function* updateAdministrator(action: any): any {
  yield put(openModalLoading());

  const formUpdateIsValid = administratorUpdateSchema.isValidSync(action);

  if (action.adminId && !formUpdateIsValid)
    return yield put(
      openFeedbackModalAction(
        "Para atualizar um administrador é necessário que todos os dados sejam informados corretamente.",
        "error"
      )
    );

  let data: any = {
    eusu_nome: action.name,
    eusu_email: action.email,
    eusu_senha: action.password,
    eusu_status: action.status,
    rhnot_id: action.notify,
    funcoes: GeneralUtilities.getAListFromAnArrayOfNumbers(action.functions),
    emp_id:
      AdministratorsUtilities.prepareCompaniesToSubmit(action.companies) || [],
  };

  const http = new Http();
  const response = yield http.httpClientPut({
    url: `empresa/empresausuario/${action.adminId}`,
    data,
  });

  yield put(closeModalLoading());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(
    openFeedbackModalAction("Administrado atualizado com sucesso!", "success")
  );

  yield put(searchAdministratorsAction());
}

function* changeAdministratorStatus(action: any): any {
  const http = new Http();
  const response = yield http.httpClientPatch({
    url: `empresa/empresausuario/${action.adminId}`,
    data: {
      eusu_status: action.status,
    },
  });

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(openFeedbackModalAction("Status alterado com sucesso!", "success"));

  yield put(searchAdministratorsAction());
}

export default all([
  takeLatest(SUBMIT_ADMINISTRATOR, submitAdministrators),
  takeLatest(CHANGE_ADMINISTRATOR_STATUS, changeAdministratorStatus),
]);
