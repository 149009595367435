export class UtilitiesDate {
  static convertFormatDateApiToBrazilian(dateString: string) {
    const [year, month, day] = dateString.split("-");
    const dateFormatted = `${day}/${month}/${year}`;
    return dateFormatted;
  }

  static convertFormatDateTimeApiToBrazilianWithouTime(dateString: string) {
    const [date, time] = dateString.split(" ");
    const [year, month, day] = date.split("-");
    const dateFormatted = `${day}/${month}/${year}`;
    return dateFormatted;
  }

  static convertFormatDateTimeApiToBrazilianWithTime(dateString: string) {
    const [date, time] = dateString.split(" ");
    const [year, month, day] = date.split("-");
    const dateFormatted = `${day}/${month}/${year} ${time}`;
    return dateFormatted;
  }

  static formatDateAndTimeToBrazilianStandard(value: string) {
    const [date, time] = value.split(" ");
    const [yeaer, month, day] = date.split("-");

    let dateBrazilianFormat = `${day}/${month}/${yeaer}`;
    return [dateBrazilianFormat, time];
  }

  static formatStringDateToDefaultCanadian(dateString: string) {
    const [day, month, year] = dateString.split("/");
    const dateFormatted = `${year}-${month}-${day}`;
    return dateFormatted;
  }

  static formatStringDateToDefaultBrazilian(dateString: string) {
    if (/^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/g.test(dateString))
      return dateString;

    const [year, month, day] = dateString.split("-");
    const dateFormatted = `${day}/${month}/${year}`;
    return dateFormatted;
  }

  static formatListOfStringDateToDefaultBrazilian(listOfDateString: string[]) {
    let listOfDateStringFormatted: string[] = [];
    listOfDateString.forEach((element) => {
      const [year, month, day] = element.split("-");
      listOfDateStringFormatted.push(`${day}/${month}/${year}`);
    });

    return listOfDateStringFormatted;
  }
}
