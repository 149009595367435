import styled, { css } from "styled-components";
import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";

type AccordionTriggerProps = {
  isOpen: boolean;
  disabled?: boolean;
};

const accordionOpen = css`
  height: 4rem;
  border-radius: 3rem 3rem 0rem 0rem;
  padding: 2.2rem 3.3rem 0rem 2.2rem;
`;

const accordionClosed = css`
  height: 6rem;
  border-radius: 3rem;
  padding: 2.2rem 3.3rem;
`;

const accordionDisabled = css`
  background-color: rgb(184, 184, 184);
`;

export const CollapsibleTrigger = styled(
  CollapsiblePrimitive.Trigger
)<AccordionTriggerProps>`
  width: 100%;
  background-color: var(--white);
  text-align: left;

  ${(props) => (props.isOpen ? accordionOpen : accordionClosed)}
  ${(props) => props.disabled && accordionDisabled}
`;
