import { combineComponents } from "./combineContext";

// import { NotificationProvider } from "./Notification/Notification";
import { ProviderMobileSidebar } from "./MobileSiderbar/ContextMobileSidebar";

const providers: any[] = [
    // NotificationProvider,
    ProviderMobileSidebar
]

export const AppContextProvider : any = combineComponents(...providers);