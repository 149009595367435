import ISelectCompanyOptions from "interfaces/interface.select-company-options";
import ISelectEconomicGroupsOptions from "interfaces/interface.select-economic-groups-options";

export class Companies {
  getLisCompanies() {
    let listOfCompanies: any[] = [];

    const data = sessionStorage.getItem("@userData");

    if (!data) return [];

    const dataJson = JSON.parse(data);

    if (!dataJson.empresas) return [];

    dataJson.empresas.map((item: any) => {
      listOfCompanies.push({
        value: item.emp_id,
        label: item.emp_nomefantasia,
        economicGroup: item.gpec_id,
        hasAnEndDateOfTheContract: item.cadastra_func_dtfimcontrato,
        hasMargin: item.cadastra_margem,
      });
    });

    return listOfCompanies;
  }

  getListEconomicGroups() {
    let listOfEconomicGroups: ISelectEconomicGroupsOptions[] = [];

    const data = sessionStorage.getItem("@userData");

    if (!data) return [];

    const dataJson = JSON.parse(data);

    if (!dataJson.grupos) return [];

    dataJson.grupos.map((item: any) => {
      listOfEconomicGroups.push({
        value: item.gpec_id,
        label: item.gpec_nome,
      });
    });

    return listOfEconomicGroups;
  }
}
