import IShutdownRow from "interfaces/interface.shutdown-row";
import Badge from "components/Badge";
import IColorsVariants from "interfaces/interface.colors-variants";

export default function RowShutdownCollaborator({
  portion,
}: {
  portion: IShutdownRow;
}) {
  let badgeVariant: IColorsVariants["colorsVariant"] = portion.status.value? "green" : "red";
  
  if(portion.status){

  }
  return (
    <tr>
      <td className="table-text">{portion.name}</td>
      <td className="table-text">{portion.cpf}</td>
      <td className="table-text">
      <Badge
          variant={badgeVariant}
          className="text-roboto-fs14-lh16-fw400-white"
        >
          {portion.status.label}
        </Badge>
      </td>

    </tr>
  );
}
