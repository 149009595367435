import './style.css'

interface IProps {
  label: string;
  value: string | number;
  labelClass?: string;
  valueClass? : string;
  size?: "sm" | "lg"
}

export default function DataCard({
  label,
  value,
  labelClass,
  valueClass,
  size = "lg"
}: IProps) {
  return (
    <div className={`datacard d-flex flex-column gap-2 pt-5 pb-4`}>
      <span className={labelClass || "text-roboto-fs14-lh16-fw700-blue600 "}>
        {label}
      </span>
      <span className={valueClass || "text-roboto-fs30-lh35-fw400-orange500 datacard-text"}>
        {value}
      </span>
    </div>
  );
}
