import { useAppSelector } from "hooks/hooks.redux";
import Modal from "components/Modal";
import Panel from "./Panel";

import "./style.css";

export default function EndorsementPanel() {
  const { endorsementPanel } = useAppSelector(
    (state) => state.collaboratorsReducer
  );

  return (
    <Modal show={endorsementPanel.show}>
      <Panel />
    </Modal>
  );
}
