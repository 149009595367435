import IConciliation from "interfaces/http-response/interface.conciliation";
import IConciliationHistory from "interfaces/interface.conciliation-history";
import TableConciliationGroup from "./TableConciliationGroup";
import TableConciliationHistory from "./TableConciliationHistory";

export default function Table({ option, data }: { option: 0 | 1, data: IConciliation[] | IConciliationHistory[] }) {
  if (option === 0) {
    return <TableConciliationGroup data={data as IConciliation[]}/>;
  } else {
    return <TableConciliationHistory data={data as IConciliationHistory[]}/>;
  }
}