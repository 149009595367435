import { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

import "./style.css";

interface IProps {
  totalPages: number;
  paginate: (page: number) => void;
  currentPage: number;
}

export default function TablePagination({
  totalPages,
  currentPage,
  paginate,
}: IProps) {
  const [pages, setPages] = useState<number[]>([]);

  useEffect(() => {
    paginationItems();
  }, [currentPage, totalPages]);

  function currentPageHasPassedHalfThePages(halfPages: number) {
    return currentPage - halfPages < 1;
  }

  function nextPageIsTheLastPage(halfPages: number) {
    return currentPage + halfPages > totalPages;
  }

  function paginationItems() {
    const maximumPagesPerDisplay = 4;
    const halfPages = Math.ceil(maximumPagesPerDisplay / 2);

    let firstVisiblePage = 0,
      lastVisiblePage = 0;
    if (currentPageHasPassedHalfThePages(halfPages)) {
      firstVisiblePage = 1;
    } else {
      firstVisiblePage = currentPage - halfPages;
    }

    if (nextPageIsTheLastPage(halfPages)) {
      lastVisiblePage = totalPages;
    } else {
      lastVisiblePage = currentPage + halfPages;
    }

    let paginationItems = [];
    for (var index = firstVisiblePage; index <= lastVisiblePage; index++) {
      paginationItems.push(index);
    }

    setPages(paginationItems);
  }

  function paginationPrev() {
    if (currentPage - 1 < 1) {
      paginate(1);
    } else {
      paginate(currentPage - 1);
    }
  }

  function paginationNext() {
    if (currentPage + 1 > totalPages) {
      paginate(totalPages);
    } else {
      paginate(currentPage + 1);
    }
  }

  function paginationFirst() {
    paginate(1);
  }

  function paginationLast() {
    paginate(totalPages);
  }

  return (
    <>
      {totalPages && (
        <Pagination size="lg" bsPrefix="tablepagination">
          {currentPage !== 1 && <Pagination.First onClick={paginationFirst} />}
          <Pagination.Prev onClick={paginationPrev} />
          {pages.map((index, key) => (
            <Pagination.Item
              key={key}
              active={index === currentPage}
              onClick={() => paginate(index)}
            >
              {index}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={paginationNext} />
          {currentPage !== totalPages && (
            <Pagination.Last onClick={paginationLast} />
          )}
        </Pagination>
      )}
    </>
  );
}
