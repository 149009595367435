import IAdministrators from "interfaces/http-response/interface.administrators";
import ISelectOptions from "interfaces/interface.select-options";
import { Companies } from "./company";

export class AdministratorsUtilities {
  static prepareCompaniesToSubmit(companies: ISelectOptions[]) {
    let newArray: any[] = [];
    companies.map((item) => {
      newArray.push({
        id: item.value,
        habilita_aprovacao: 1,
      });
    });
    return newArray;
  }

  static getCompaniesAsSelectionObject(
    companies: IAdministrators["usuarioempresa"]
  ) {
    const { getLisCompanies } = new Companies();
    const listOfCompanies = getLisCompanies();
    let filteredCompanies: any[] = [];
    companies.map((item) => {
      filteredCompanies.push(
        listOfCompanies.find((element) => element.value === item.emp_id)
      );
    });
    return filteredCompanies;
  }
}
