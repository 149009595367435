import StatusIndicator from "components/StatusIndicator";
import ICollaborators from "interfaces/http-response/interface.collaborators";
import IColorsVariants from "interfaces/interface.colors-variants";
import ArrowIcon from "assets/img/arrow-table.svg";

export default function StatusCollaborator({
  situation,
}: {
  situation: ICollaborators["situacao"];
}) {
  const colorsOfStatus: Array<IColorsVariants["colorsVariant"]> = [
    "orange",
    "green",
    "pink",
    "red",
  ];

  return (
    <div className="d-flex flex-row align-items-center gap-4">
      {situation.func_situacao !== 4 && (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img src={ArrowIcon} alt="" />
        </div>
      )}
      <StatusIndicator variant={colorsOfStatus[situation.func_situacao - 1]} />
      <span className="table-text">{situation.label}</span>
    </div>
  );
}
