import { Table } from "layout/Table/styled";

import RowUploadHistory from "./Rows/RowUploadHistory";

import ICollaboratorUploadHistory from "interfaces/interface.collaborator-upload-history";

type IProps = {
  data: ICollaboratorUploadHistory[];
};

export default function TableUploadHistory({ data }: IProps) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th className="table-text">Nome do arquivo</th>
          <th className="table-text">Tipo</th>
          <th className="table-text">Data de envio</th>
          <th className="table-text">Status</th>
          <th className="table-text">Ações</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, key) => (
          <RowUploadHistory key={key} file={item} />
        ))}
      </tbody>
    </Table>
  );
}
