import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";
// import { Plus } from 'react-bootstrap-icons'

import Body from "layout/DefaultBody";
import Button from "components/Button";
import TableContainer from "layout/TableContainer";
import TableAdministrators from "./components/Table";

import { openDetailsPanelAction } from "model/actions/action.administrators-details-panel";
import { searchAdministratorsAction } from "model/actions/action.administrators";

export default function Administrators() {
  const dispatch = useAppDispatch();
  const { currentPage, lastPage, administrators, total } = useAppSelector(
    (state) => state.administratorsReducer
  );
  const { isLoading } = useAppSelector(state => state.generalReducer)

  useEffect(() => {
    searchAdministrators();
  }, []);

  const buttonIsDisabled = isLoading

  function openDetailsPanelForCrete() {
    dispatch(openDetailsPanelAction());
  }

  function searchAdministrators(page?: number) {
    dispatch(searchAdministratorsAction(page));
  }

  return (
    <Body
      titlePage="Administradores"
      description="Abaixo listamos os usuários cadastrados."
    >
      <Row>
        <Col md={12} className="d-flex justify-content-end">
          <Button
            variant="blue"
            className="text-roboto-fs12-lh14-fw400-white mt-3"
            onClick={openDetailsPanelForCrete}
            disabled={buttonIsDisabled}
          >
            Adicionar administrador 
            {/* <Plus width={28} height={28} color="#fff" /> */}
          </Button>
        </Col>
      </Row>
      <div className="row">
        <div className="col-md-12">
          <TableContainer
            isLoading={isLoading}
            pagination={{
              currentPage,
              paginate: searchAdministrators,
              lastPage,
              total
            }}>
            <TableAdministrators data={administrators} />
          </TableContainer>
        </div>
      </div>
    </Body>
  );
}