import IColorsVariants from "interfaces/interface.colors-variants";
import { useTheme } from "styled-components";
import { Indicator } from "./styled";

import "./styled.ts";

interface IProps {
  variant: IColorsVariants["colorsVariant"];
}

export default function StatusIndicator({ variant }: IProps) {
  const {
    COLORS: {
      RED_300,
      GREEN_300,
      PINK_400,
      ORANGE_500,
      GRAY_700,
      GRAY_200,
      BLUE_700,
      WHITE
    },
  } = useTheme();

  const colors = {
    red: RED_300,
    green: GREEN_300,
    pink: PINK_400,
    orange: ORANGE_500,
    gray: GRAY_700,
    silver: GRAY_200,
    blue: BLUE_700,
    white: WHITE,
  };


  return <Indicator color={colors[variant]} />;
}
