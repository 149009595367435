import produce from "immer";
import { IFeedbackModal } from "interfaces/interface.feedback-modal";
import {
  CLOSE_FEEDBACK_MODAL,
  OPEN_FEEDBACK_MODAL,
} from "model/action-types/type.feedback-modal";
import { AnyAction } from "redux";

const INITIAL_STATE = {
  message: "",
  status: "error" as IFeedbackModal["status"],
  show: false,
};

export function feedbackModalReducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case OPEN_FEEDBACK_MODAL:
      return produce(state, (draft) => {
        draft.message = action.message;
        draft.status = action.status;
        draft.show = true;
      });
    case CLOSE_FEEDBACK_MODAL:
      return produce(state, (draft) => {
        draft.message = "";
        draft.status = "error";
        draft.show = false;
      });
    default:
      return state;
  }
}
