import Http from "services/httpClient";
import { SEARCH_ADMINISTRATORS } from "model/action-types/type.administrators";
import { setFunctionsAction } from "model/actions/action.administrators-functions";
import { all, put, takeLatest } from "redux-saga/effects";

function* searchFunctions(): any {
  const http = new Http();
  const response = yield http.httpClientGet({
    url: "empresa/funcao",
  });
  yield put(setFunctionsAction(response));
}


export default all([takeLatest(SEARCH_ADMINISTRATORS, searchFunctions)])