import { all, put, select, takeLatest } from "redux-saga/effects";
import fileDownload from "js-file-download";
import Http from "services/httpClient";

import {
  APPROVE_A_REQUEST,
  APPROVE_A_REQUEST_LIST,
  EXPORT_REQUESTS,
  REFUSE_A_REQUEST,
} from "model/action-types/type.requests-submit";

import {
  clearListOfSelectedProposalsAction,
  closeRequestStatusAction,
  searchRequestsAction,
} from "model/actions/action.request";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";

import GeneralUtilities from "utils/utilities.general";
import ListById from "utils/utilities.list-by-id";
import {
  closeModalLoading,
  openModalLoading,
} from "model/actions/action.modal-loading";
import { PropsRequestReducer } from "model/reducers-types/type.requestReducers";

function* exportRequests(): any {
  yield put(openModalLoading());

  const store = yield select();
  const listOfSelectedProposals = store.requestsReducer
    .listOfSelectedProposals as number[];
  const status = store.requestsReducer.requestStatusFilter;
  const empId = store.requestsReducer.companyFilter;

  const list = GeneralUtilities.getAListFromAnArrayOfNumbers(
    listOfSelectedProposals
  );

  const http = new Http();

  const response = yield http.httpClientGet({
    url: `empresa/proposta/solicitacoes?export=true${
      status !== null ? "&ppst_status=" + status : ""
    }${empId !== null ? "&emp_id=" + empId : ""}${
      list.length > 0 ? `&ppst_id=${list}` : ""
    }`,
    responseType: "arraybuffer",
  });

  yield put(clearListOfSelectedProposalsAction(list.length > 0 ? false : true));
  yield put(closeModalLoading());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  fileDownload(
    response,
    "Solicitações.xlsx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
}

//Aprovar e Recusar propostas

function* approveARequest(action: any): any {
  yield put(closeRequestStatusAction());
  yield put(openModalLoading());

  let data: any = {
    ppst_status: action.type === APPROVE_A_REQUEST ? 1 : 6,
  };

  if (action.type === REFUSE_A_REQUEST)
    data = { ...data, mtvrh_motivo: action.reasonForRefusal, mtv_id: action.reason };

  const http = new Http();
  const response = yield http.httpClientPut({
    url: `empresa/proposta/solicitacoes/${action.ppstId}`,
    data,
  });

  yield put(closeModalLoading());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(
    openFeedbackModalAction(
      `Solicitação ${
        action.type === APPROVE_A_REQUEST ? "aprovada" : "negada"
      } com sucesso!`,
      "success"
    )
  );

  yield put(searchRequestsAction());
}

function* approveARequestsList(): any {
  yield put(openModalLoading());

  const store = yield select();
  const listOfSelectedProposals = store.requestsReducer
    .listOfSelectedProposals as PropsRequestReducer["listOfSelectedProposals"];

  const list = ListById.getLinearListOfIds(listOfSelectedProposals);

  const http = new Http();
  const response = yield http.httpClientPost({
    url: `empresa/proposta/solicitacoes/aprovarlote?propostas=${list.join()}`,
  });

  yield put(closeModalLoading());

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(openFeedbackModalAction(response.mensagem, "success"));

  yield put(clearListOfSelectedProposalsAction())
  yield put(searchRequestsAction());
}

export default all([
  takeLatest(APPROVE_A_REQUEST, approveARequest),
  takeLatest(REFUSE_A_REQUEST, approveARequest),
  takeLatest(APPROVE_A_REQUEST_LIST, approveARequestsList),
  takeLatest(EXPORT_REQUESTS, exportRequests),
]);
