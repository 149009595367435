import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/hooks.redux";

import Box from "../Box";
import Button from "components/Button";
import Input from "components/Input";
import Label from "components/Label";

import { authenticateAction } from "model/actions/action.authenticate-submit";
import { clearStateAction } from "model/actions/action.general";

import IconRightArrow from "assets/img/right-arrow.svg";

interface IProps {
  changeContentType: (type: "authenticate" | "password-recovery" | "feedback") => void;
}

export default function LoginForm({ changeContentType }: IProps) {
  const dispatch = useAppDispatch();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    dispatch(clearStateAction())
  },[])

  async function authenticate(event: any) {
    event.preventDefault();
    dispatch(authenticateAction(userName, password));
  }

  return (
    <Box title="Olá" message="Informe seus dados para acessar o portal RH.">
      <Label className="login-form-label text-roboto-fs14-lh16-fw400-gray400">
        E-mail
      </Label>
      <Input
        type="email"
        required
        value={userName}
        onChange={(value) => setUserName(value)}
        className="login-form-input"
      />
      <Label className="login-form-label text-roboto-fs14-lh16-fw400-gray400">
        Senha
      </Label>
      <Input
        type="password"
        required
        value={password}
        onChange={(value) => setPassword(value)}
        className="login-form-input"
      />
      <div className="d-flex flex-row justify-content-between flex-wrap">
        <Button
          type="button"
          variant="blue"
          className="d-flex align-items-center gap-3 justify-content-between text-montserrat-fs16-lh19-fw400-white px-5"
          onClick={authenticate}
        >
          Entrar
          <img
            src={IconRightArrow}
            alt="Flecha branca apontada para o lado direito"
          />
        </Button>
        <Button
          variant="blue"
          isActive={false}
          className="border-0 text-roboto-fs16-lh18-fw700-blue700 font-weight-bold"
          onClick={() => changeContentType("password-recovery")}
        >
          Esqueci a senha?
        </Button>
      </div>
    </Box>
  );
}
