import { useContext } from "react";
import { CloseButton, Offcanvas } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import App from "utils/utilities.app";
import { ContexMobileSidebar } from "context/MobileSiderbar/ContextMobileSidebar";

import OverviewIconOrange from "assets/img/overview-icon-orange.svg";
import OverviewIcon from "assets/img/overview-icon.svg";
import CollaboratorsIcon from "assets/img/collaborator-icon.svg";
import CollaboratorsIconOrange from "assets/img/collaborator-icon-orange.svg";
import RequestsIcon from "assets/img/requests-icon.svg";
import RequestsIconOrange from "assets/img/requests-icon-orange.svg";
import ConciliationIcon from "assets/img/conciliation-icon.svg";
import ConciliationIconOrange from "assets/img/conciliation-icon-orange.svg";
import AdministratorsIcon from "assets/img/administrators-icon.svg";
import AdministratorsIconOrange from "assets/img/administrators-icon-orange.svg";
import LogOutIcon from "assets/img/logout-icon.svg";
import InmanoCircleWhiteIcon from "assets/img/inmano-circle-white-icon.svg";

import "./mobile-sidebar-style.css";
import "./style.css";

export default function MobileSidebar() {
  const location = useLocation();

  const { isOpen, onClose } = useContext(ContexMobileSidebar);

  function MenuOption(props: {
    path: string;
    label: string;
    icon: string;
    iconActive: string;
  }) {
    return (
      <Link
        to={props.path}
        className="sidebar-link d-flex flex-row align-items-center gap-4"
        style={{
          color:
            location.pathname === props.path
              ? "var(--orange-500)"
              : "var(--white)",
        }}
      >
        {props.label}
        <img
          src={location.pathname === props.path ? props.iconActive : props.icon}
          alt=""
          className="sidebar-link-selected"
        />
      </Link>
    );
  }

  return (
    <Offcanvas show={isOpen} onHide={onClose}>
      <Offcanvas.Header className="d-flex flex-row justify-content-end py-4">
        <CloseButton variant="white" onClick={onClose} />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <img
          src={InmanoCircleWhiteIcon}
          alt="Icone colorido da InMano com fundo branco."
        />
        <nav className={`sidebar-nav d-flex flex-column`}>
          <MenuOption
            path="/home"
            label="Visão geral"
            icon={OverviewIcon}
            iconActive={OverviewIconOrange}
          />
          <MenuOption
            path="/colaboradores"
            label="Colaboradores"
            icon={CollaboratorsIcon}
            iconActive={CollaboratorsIconOrange}
          />
          <MenuOption
            path="/solicitacoes"
            label="Solicitações"
            icon={RequestsIcon}
            iconActive={RequestsIconOrange}
          />
          <MenuOption
            path="/contratos"
            label="Contratos"
            icon={CollaboratorsIcon}
            iconActive={CollaboratorsIconOrange}
          />
          <MenuOption
            path="/conciliacoes"
            label="Conciliação"
            icon={ConciliationIcon}
            iconActive={ConciliationIconOrange}
          />
          <MenuOption
            path="/administradores"
            label="Administradores"
            icon={AdministratorsIcon}
            iconActive={AdministratorsIconOrange}
          />
          <button
            className="sidebar-link bg-transparent d-flex flex-row align-items-center gap-4"
            onClick={App.exit}
          >
            Sair
            <img src={LogOutIcon} alt="" />
          </button>
        </nav>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
