import { DefaultTheme } from 'styled-components';

const myTheme: DefaultTheme = {
    COLORS: {
        BLUE_600: "#3d5375",
        BLUE_700: "#2a3b5a",
        BLACK_400: "#3a3a3a",
        WHITE: "#fff",
        GREEN_300: "#62d6ba",
        RED_300: "#f15d5a",
        PINK_400: "#c0456e",
        PINK_500: "#bb527d",
        ORANGE_400: "#e38952",
        ORANGE_500: "#f79463",
        GRAY_100: "#f1f1f1",
        GRAY_200: "#dadada",
        GRAY_300: "#f3f3f3",
        GRAY_400: "#b9b9b9",
        GRAY_500: "#c0c0c0",
        GRAY_600: "#d9d9d9",
        GRAY_700: "#707070",
      }
};

export { myTheme };