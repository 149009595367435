import { ReactNode } from "react";
import * as Collapsible from "@radix-ui/react-collapsible";

import ArrowRight from "assets/img/rigth-chevron-arrow.svg";
import ArrowDown from "assets/img/arrow-down.svg";

import { CollapsibleTrigger } from "./styled";

interface IProps {
  disabled?: boolean;
  children: ReactNode;
  label: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export default ({ label, isOpen, setIsOpen, children, disabled }: IProps) => {
  return (
    <Collapsible.Root open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <CollapsibleTrigger
        disabled={disabled}
        isOpen={isOpen}
        className="d-flex justify-content-between text-roboto-fs14-lh16-fw700-blue700"
      >
        {label}
        <img
          src={isOpen ? ArrowDown : ArrowRight}
          alt=""
          className="collaborators-collapse-icon-arrow"
        />
      </CollapsibleTrigger>
      <Collapsible.Content>
        <div className="container-fluid collaborator-actions-container flex-grow-1">
          {children}
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};