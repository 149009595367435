import {
  CLEAR_STATE_MODAL_PROFILE,
  OPEN_MODAL_PROFILE,
  SET_ERROR_PASSWORD_PROFILE,
} from "model/action-types/type.profile";

export function openModalProfileAction() {
  return { type: OPEN_MODAL_PROFILE };
}

export function clearSatateModalProfileAction() {
  return { type: CLEAR_STATE_MODAL_PROFILE };
}

export function setErrorPasswordProfileAction(state: boolean) {
  return { type: SET_ERROR_PASSWORD_PROFILE, state };
}
