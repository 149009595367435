import {
  CLOSE_MODAL_NOTIFICATION,
  OPEN_MODAL_NOTIFICATION,
} from "model/action-types/type.modal-notification";

export function openModalNotificationAction() {
  return { type: OPEN_MODAL_NOTIFICATION };
}

export function closeModalNotificationAction() {
  return { type: CLOSE_MODAL_NOTIFICATION };
}
