import styled, { css } from "styled-components";

const containerSuccess = css`
  border-color: ${(props) => props.theme.COLORS.GREEN_300};
`;

const containerError = css`
  border-color: ${(props) => props.theme.COLORS.RED_300};
`;

interface ContainerProps {
    error?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 42rem;
  max-width: 95%;
  max-height: 90%;
  padding: 4.2rem 5rem 5rem 4rem;
  background-color: #fff;
  border-radius: 3.5rem;
  position: absolute;
  right: 17.4rem;
  overflow-y: auto;
  border: 0.4rem solid #fff;

  ${props => props.error && containerError}
  ${props => props.error === false && containerSuccess}

  @media (max-width: 1024px) {
    & {
      position: relative;
      right: 0;
    }
  }
`;
