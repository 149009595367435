import Button from "components/Button";

export default function ListItem({
  collaborator,
  removeItem,
}: {
  collaborator: any;
  removeItem: (value: number) => void;
}) {
  return (
    <div className="d-flex flex-row flex-wrap align-items-center gap-2">
      <div className="d-flex flex-grow-1 gap-2">
        <div className="collaborator-shutdownlist-index text-nunito-fs12-lh37-fw700-white">
          {collaborator.key + 1}
        </div>
        <input
          disabled
          className="collaborator-shutdownlist-value flex-grow-1 text-roboto-fs14-lh16-fw400-blue700"
          value={collaborator.label}
        />
      </div>
      <Button
        variant="gray"
        onClick={() => removeItem(collaborator.value)}
        className="collaborator-shutdownlist-listitem-button text-roboto-fs12-lh14-fw400-white"
      >
        Remover
      </Button>
    </div>
  );
}
