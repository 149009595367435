import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { ReactNode, useState } from "react";

import { DropdownMenuItem, DropsownMenuTrigger } from "./styled";

type IProps = {
  trigger: ReactNode;
  items: Array<{
    label: string | ReactNode;
    disabled?: boolean;
    action: () => void;
  }>;
};

export default ({ trigger, items }: IProps) => {
  const [open, setOpen] = useState(false);
  return (
    <DropdownMenu.Root onOpenChange={() => setOpen(!open)} open={open}>
      <DropsownMenuTrigger>{trigger}</DropsownMenuTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          {items.map((content, key) => (
            <DropdownMenuItem
              key={key}
              disabled={content.disabled}
              onClick={() => {
                content.action();
                setOpen(false);
              }}
            >
              {content.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
