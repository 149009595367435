import { useAppDispatch } from "hooks/hooks.redux";

import AdministratorStatus from "./AdministratorStatus";
import StatusIndicator from "components/StatusIndicator";
import DropdownTable from "layout/DropdownTable";

import IAdministrators from "interfaces/http-response/interface.administrators";
import { UserUtilities } from "utils/utilities.user";
import { openDetailsPanelAction } from "model/actions/action.administrators-details-panel";
import { changeAdministratorStatusAction } from "model/actions/action.administrators-submit";

import ActionsIcon from "assets/img/actions-icon.svg";

export default function TableRowAdministrator({
  administrator,
}: {
  administrator: IAdministrators;
}) {
  const dispatch = useAppDispatch();

  const isAuthorized = UserUtilities.isAuthorized(1);

  const occupation = administrator.funcoes.length ? administrator.funcoes[0].label : "Master"

  function openDetailsPanelForEdit() {
    dispatch(openDetailsPanelAction(administrator));
  }

  function activeAdministrator() {
    dispatch(changeAdministratorStatusAction(administrator.eusu_id, 1));
  }

  function disableAdministrator() {
    dispatch(changeAdministratorStatusAction(administrator.eusu_id, 2));
  }

  return (
    <tr>
      <td>
        <span className="table-text">{administrator?.eusu_nome}</span>
      </td>
      <td>
        <span className="table-text">{administrator?.eusu_email}</span>
      </td>
      <td>
        <span className="table-text">{occupation}</span>
      </td>
      {/* <td>
        <span className="table-text">
          {administrator.notificacao.rhnot_descricao}
        </span>
      </td> */}
      <td>
        <div className="d-flex gap-5">
          {isAuthorized ? (
            <DropdownTable
              trigger={<AdministratorStatus status={administrator.status} />}
              items={[
                {
                  label: <>
                    <StatusIndicator variant="red" />
                    Inativo
                  </>,
                  action: disableAdministrator
                },
                {
                  label: <>
                    <StatusIndicator variant="green" />
                    Ativo
                  </>,
                  action: activeAdministrator
                }
              ]} />
          ) : (
            <AdministratorStatus status={administrator.status} />
          )}
        </div>
      </td>
      {isAuthorized &&
        <td>
          <DropdownTable
            trigger={<img src={ActionsIcon} alt="" />}
            items={[
              { label: "Editar", action: openDetailsPanelForEdit },
            ]} />
        </td>
      }
    </tr>
  );
}
