import {
  AUTHENTICATE,
  AUTHENTICATE_CHANGE_PASSWORD,
  SUBMIT_AUTHENTICATE_CODE,
} from "model/action-types/type.authenticate-submit";

export function authenticateAction(userName: string, password: string) {
  return { type: AUTHENTICATE, userName, password };
}

export function submitAuthenticateCodeAction(code: string) {
  return { type: SUBMIT_AUTHENTICATE_CODE, code };
}

export function authenticateChangePasswordAction(password: string) {
  return { type: AUTHENTICATE_CHANGE_PASSWORD, password };
}
  