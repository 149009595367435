export const SEARCH_CONTRACTS = "SEARCH_CONTRACTS";
export const SET_TABLE_DATA_CONTRACTS = "SET_TABLE_DATA_CONTRACTS";

export const FILTER_CONTRACTS_BY_COLLABORATOR =
  "FILTER_CONTRACTS_BY_COLLABORATOR";
export const FILTER_CONTRACTS_BY_COMPANY = "FILTER_CONTRACTS_BY_COMPANY";
export const FILTER_CONTRACTS_BY_STATUS = "FILTER_CONTRACTS_BY_STATUS";

export const OPEN_DISPLAY_CONTRACT = "OPEN_DISPLAY_CONTRACT" 
export const CLOSE_DISPLAY_CONTRACT = "CLOSE_DISPLAY_CONTRACT" 

export const DOWNLOAD_CCB_CONTRACT = "DOWNLOAD_CCB_CONTRACT" 