import ArrowIcon from "assets/img/arrow-table.svg";
import StatusIndicator from "components/StatusIndicator";
import IAdministrators from "interfaces/http-response/interface.administrators";
import { UserUtilities } from "utils/utilities.user";

type IProps = {
  status: IAdministrators["status"];
};

export default function AdministratorStatus({ status }: IProps) {
  const isAuthorized = UserUtilities.isAuthorized(1);

  return (
    <div className="d-flex flex-row align-items-center gap-4">
      {isAuthorized && (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img src={ArrowIcon} alt="" />
        </div>
      )}
      <StatusIndicator variant={status.eusu_status === 1 ? "green" : "red"} />
      <span className="table-text">{status.label}</span>
    </div>
  );
}
