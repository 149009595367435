import IConciliationShutdownInstallments from "interfaces/interface.conciliation-shutdown-installments";
import CurrencyUtilities from "utils/currencyFormatting";

export default function RowConciliationShutdownCollaborator({
  portion,
}: {
  portion: IConciliationShutdownInstallments;
}) {
  const debitBalance = CurrencyUtilities.formatCurrencyToBrazilianStandard(portion.saldo_devedor);
  const retainedBalance = CurrencyUtilities.formatCurrencyToBrazilianStandard(portion.saldo_retido);

  return (
    <tr>
      <td className="table-text">{portion.ccb}</td>
      <td className="table-text">{portion.nome}</td>
      <td className="table-text">{portion.cpf}</td>
      <td className="table-text">R$ {debitBalance}</td>
      <td className="table-text">R$ {retainedBalance}</td>
    </tr>
  );
}
