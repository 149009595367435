import { number, object, string } from "yup";

export const internalDataSchema = object({
  func_dtadmissao: string().required(),
  func_salariobruto: string().required(),
  func_salarioliquido: string().required(),
  emp_id: number().required().positive().integer(),
});

export function schemaValidate(
  form: any,
  rules: { hasAnEndDateOfTheContract: number; hasMargin: number }
) {
  const schema = object({
    func_dtadmissao: string().required(),
    func_salariobruto: string().required(),
    func_salarioliquido: string().required(),
    emp_id: number().required().positive().integer(),
    func_margem: Boolean(rules.hasMargin)
      ? string().required()
      : string().notRequired(),
    func_dtfimcontrato: Boolean(rules.hasAnEndDateOfTheContract)
      ? string().required()
      : string().notRequired(),
  });

  return schema.isValidSync(form);
}
