
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import Badge from "components/Badge";

import CurrencyUtilities from "utils/currencyFormatting";
import { UserUtilities } from "utils/utilities.user";
import { UtilitiesDate } from "utils/utilities.date";  
import ListById from "utils/utilities.list-by-id";
import IRequests from "interfaces/http-response/interface.requests";
import IColorsVariants from "interfaces/interface.colors-variants";
import {
  changelistOfSelectedProposalsRequestsAction,
  openRequestStatusAction,
} from "model/actions/action.request";
import { PropsRequestReducer } from "model/reducers-types/type.requestReducers";

import { RequestTypeIndicator } from "./styled";

export default function TableRowRequests(props: any) {
  const { item }: { item: IRequests } = props;

  const dispatch = useAppDispatch();
  const { listOfSelectedProposals, currentPage } = useAppSelector(
    (state) => state.requestsReducer
  );

  var badgeVariant: IColorsVariants["colorsVariant"] = "orange";

  if (item.status.ppst_status === 1) badgeVariant = "green";
  else if (item.status.ppst_status === 6) badgeVariant = "red";

  const vlr_solicitado = CurrencyUtilities.formatCurrencyToBrazilianStandard(item.vlr_solicitado);
  const ppst_taxajuros = CurrencyUtilities.formatCurrencyToBrazilianStandard(item.ppst_taxajuros);
  const ppst_valorparcela = CurrencyUtilities.formatCurrencyToBrazilianStandard(item.ppst_valorparcela);
  const ppst_dthrsolicitacao = UtilitiesDate.convertFormatDateTimeApiToBrazilianWithouTime(item.ppst_dthrsolicitacao)

  let listRequests: PropsRequestReducer["listOfSelectedProposals"] = ListById.unfreeze(listOfSelectedProposals)

  const isChecked = listRequests[currentPage] ? listRequests[currentPage].includes(item.ppst_id) : false;

  function changelistOfSelectedProposals(id: number) {
    dispatch(changelistOfSelectedProposalsRequestsAction(id));
  }

  function openRequestFeedback() {
    const isAuthorizedToApprove = UserUtilities.isAuthorized([1, 2]);
    if (!isAuthorizedToApprove && item.status.ppst_status === 0) return;
    dispatch(openRequestStatusAction(item.ppst_id));
  }

  return (
    <tr>
      <td>
        <div className="d-flex flex-row align-items-center gap-3">
          <input
            type="checkbox"
            onChange={() => changelistOfSelectedProposals(item.ppst_id)}
            checked={listOfSelectedProposals.length === 20 || isChecked}
          />
          <RequestTypeIndicator typeId={item.ppst_tipo} />
          <span className="table-text">{item.emp_nomefantasia}</span>
        </div>
      </td>
      <td className="table-text">{item.func_nome}</td>
      <td className="table-text">{item.func_cpf}</td>
      <td className="table-text">{ppst_dthrsolicitacao}</td>
      <td className="text-center">
        <span className="table-text">{item.tempo_de_casa}</span>
      </td>
      <td>
        <span className="table-text">
          R$ {vlr_solicitado} / {item.prazo}x
        </span>
      </td>
      <td>
        <span className="table-text">
          R$ {ppst_valorparcela}
        </span>
      </td>
      <td className="text-center">
        <span className="table-text">{ppst_taxajuros}%</span>
      </td>
      <td className="text-center">
        <span className="table-text">{item.dias_para_aprovacao > -1 ? item.dias_para_aprovacao : <strong className="text-roboto-fs14-fw400-red300">Atrasada</strong>}</span>
      </td>
      <td>
        <Badge
          variant={badgeVariant}
          className="text-roboto-fs14-lh16-fw400-white"
          onClick={openRequestFeedback}
        >
          {item.status.label}
        </Badge>
      </td>
    </tr>
  );
}
