import styled from "styled-components";
import { Table as BootstrapTable } from "react-bootstrap";

export const Table = styled(BootstrapTable)`
  min-width: max-content;
  min-height: max-content;

  thead {
    border-bottom: 1px solid var(--gray-200);
  }

  tbody tr:last-child {
    border-color: transparent;
  }

  th {
    font-weight: 700;
    padding: 0rem 1rem;
    padding-bottom: 1rem;
  }

  tr {
    height: 7rem;
    vertical-align: middle;
  }

  thead tr th , tbody tr td {
    font-family: "Roboto";
    font-style: normal;
    font-size: 1.4rem;
    color: var(--blue-700);
  }
`;
