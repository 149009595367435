import { useState } from "react";
import { Collapse as BootstrapCollapse } from "react-bootstrap";

import IFaq from "interfaces/http-response/interface.faq";

import Animated from './Animated'

import ArrowRight from "assets/img/rigth-chevron-arrow.svg";
import ArrowDown from "assets/img/arrow-down.svg";
import "./style.css";

type ICollapseType = {
  data: IFaq[];
  isLoading: boolean
};

export default function CollapseFaq({ data, isLoading }: ICollapseType) {
  const [ref, setRef] = useState<number | null>(0);

  if (isLoading) return (
    <>
      {[0, 1, 2].map((_, key) => <Animated key={key} />)}
    </>
  )

  function changeFaqCollapse(key: number) {
    if (ref === key) setRef(null)
    else setRef(key)
  }

  return (
    <div className="w-100 d-flex flex-column gap-3">
      {data.map((item, key) => (
        <div key={key}>
          <button
            className="row dashboard-collapse-faq w-100"
            onClick={() => changeFaqCollapse(key)}
          >
            <div className="col-10 d-flex">
              <span className="text-roboto-fs16-lh18-fw700-blue700">
                {item.faqrh_pergunta}
              </span>
            </div>
            <div className="col-2 d-flex justify-content-end align-items-center">
              <img
                src={ref === key ? ArrowDown : ArrowRight}
                className="dashboard-collapse-faq-arrow"
                alt="Icone de seta apontada para direita na cor cinza"
              />
            </div>
          </button>
          <BootstrapCollapse
            in={ref === key}
            className="dashboard-collapse-faq-container"
          >
            <p className="text-roboto-fs14-lh16-fw400-blue700">{item.faqrh_resposta}</p>
          </BootstrapCollapse>
        </div>
      ))}
    </div>
  );
}
