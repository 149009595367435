export default function Footer() {
  const year = new Date().getFullYear()
  return (
    <footer className="row mt-5 pb-4">
      <div className="col-12 d-flex flex-row flex-wrap align-items-center justify-content-between gap-2">
        <span className="text-roboto-fs12-lh14-fw400-blue700">
          &copy; {year} Inmano. Todos os direitos reservados
        </span>
        <nav className="d-flex flex-row flex-wrap align-items-center gap-5">
          <a href="https://inmano.com.br/termos-e-condicoes-de-uso-inmano/" target="blank" className="text-roboto-fs12-lh14-fw400-blue700">
            Termos de uso
          </a>
          <a href="https://inmano.com.br/politica-de-privacidade-inmano/" target="blank" className="text-roboto-fs12-lh14-fw400-blue700">
            Politica de privacidade
          </a>
        </nav>
      </div>
    </footer>
  );
}
