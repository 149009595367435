import { all, put, select, takeLatest } from "redux-saga/effects";
import fileDownload from "js-file-download";

import Http from "services/httpClient";

import {
  DOWNLOAD_CCB_CONTRACT,
  FILTER_CONTRACTS_BY_COLLABORATOR,
  FILTER_CONTRACTS_BY_COMPANY,
  FILTER_CONTRACTS_BY_STATUS,
  SEARCH_CONTRACTS,
} from "model/action-types/type.contracts";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";
import {
  finishLoadingAction,
  startLoadingAction,
} from "model/actions/action.general";
import { PropsContractsReducer } from "model/reducers-types/type.contracts";
import UtilitiesMaskValidations from "utils/utilities.mask-validation";
import { setTableDataContractsAction } from "model/actions/action.contracts";

function* searchContracts(action: any): any {
  yield put(startLoadingAction());

  const store = yield select();
  const { filterByCollaborator, filterByCompany, filterByStatus } =
    store.contractsReducer as PropsContractsReducer;

  const queryStandard = `page=${
    action.page || 1
  }&ppst_status=${filterByStatus}`;
  const queryByCompany = `${
    filterByCompany ? `&emp_id=${filterByCompany}` : ""
  }`;
  const queryByCollaborator = `${
    filterByCollaborator
      ? UtilitiesMaskValidations.cpfValidation(filterByCollaborator)
        ? `&func_cpf=${filterByCollaborator}`
        : `&func_nome=${filterByCollaborator}`
      : ""
  }`;

  let query = `${queryStandard}${queryByCompany}${queryByCollaborator}`;

  const http = new Http();

  const response = yield http.httpClientGet({
    url: `empresa/proposta?${query}`,
  });

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(setTableDataContractsAction(response));
  yield put(finishLoadingAction());
}

function* downloadCcbContract(action: any): any {
  
  //Extraido id da url do arquivo
  const fileId = [...action.url.matchAll(/\d+/g)][0][0];
  
  const http = new Http();
  const response = yield http.httpClientGet({
    url: `empresa/proposta/ccb/${fileId}/baixar`,
    responseType: "blob"
  });

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  const timestamp = new Date().getTime();

  fileDownload(response, `${timestamp}.pdf`, "application/pdf");
}

export default all([
  takeLatest(SEARCH_CONTRACTS, searchContracts),
  takeLatest(FILTER_CONTRACTS_BY_COLLABORATOR, searchContracts),
  takeLatest(FILTER_CONTRACTS_BY_COMPANY, searchContracts),
  takeLatest(FILTER_CONTRACTS_BY_STATUS, searchContracts),
  takeLatest(DOWNLOAD_CCB_CONTRACT, downloadCcbContract),
]);
