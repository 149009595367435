export const SEARCH_REQUESTS = "SEARCH REQUESTS";
export const SET_REQUESTS = "SET_REQUESTS";
export const SET_REQUESTS_TO_DISPLAY = "SET_REQUESTS_TO_DISPLAY";
export const REQUEST_FILTER_BY_STATUS = "REQUEST_FILTER_BY_STATUS";
export const REQUEST_FILTER_BY_COMPANY = "REQUEST_FILTER_BY_COMPANY";

export const CHANGE_LIST_OF_SELECTED_PROPOSALS =
  "CHANGE_LIST_OF_SELECTED_PROPOSALS";
export const CLEAR_LIST_OF_SELECTED_PROPOSALS =
  "CLEAR_LIST_OF_SELECTED_PROPOSALS";
export const CHANGE_EXPORT_ALL_REQUESTS = "CHANGE_EXPORT_ALL_REQUESTS";
export const OPEN_REQUEST_STATUS = "OPEN_REQUEST_STATUS";
export const CLOSE_REQUEST_STATUS = "CLOSE_REQUEST_STATUS";
// export const CHANGE_REQUEST_STATUS = "CHANGE_REQUEST_STATUS";

export const MOVE_ALL_REQUESTS_TO_SELECTED_LIST =
  "MOVE_ALL_REQUESTS_TO_SELECTED_LIST";
export const REMOVE_ALL_REQUESTS_FROM_THE_SELECTED_LIST =
  "REMOVE_ALL_REQUESTS_FROM_THE_SELECTED_LIST";
