import { object, string } from "yup";

export const personalDataSchema = object({
  func_nome: string()
    .required("erro nome")
    .matches(/^[a-záàâãéèêíïóôõöúçñ ]+$/i, {
      message: "Informe um nome válido!",
    }),
  func_cpf: string().required("erro cpf").length(14),
  func_dtnascimento: string().required("erro dt nascimento").length(10),
});
