import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import HorizontalSeparator from "layout/HorizontalSeparator/styled";
import Button from "components/Button";
import Input from "components/Input";
import Text from "components/new/Text";

import CurrencyUtilities from "utils/currencyFormatting";
import ICollaboratorEndorsement from "interfaces/interface.collaborator-endorsement";
import { closeEndorsementPanelAction } from "model/actions/action.collaborators";
import { submitEndorsementAction } from "model/actions/action.collaborators-submit";

import "./style.css";

export default function Panel() {
  const dispatch = useAppDispatch();
  const { endorsementPanel } = useAppSelector(
    (state) => state.collaboratorsReducer
  );

  const data = useAppSelector((state) => state.collaboratorsReducer)
    .data as ICollaboratorEndorsement[];

  const [amount, setAmount] = useState("");

  const collaborator = data.find(
    (item) => item.func_id === endorsementPanel.id
  );

  const debitBalance = CurrencyUtilities.formatCurrencyToBrazilianStandard(
    collaborator?.saldo_devedor as number
  );

  function submitEndorsement() {
    dispatch(submitEndorsementAction(amount));
  }

  function onClose() {
    dispatch(closeEndorsementPanelAction());
  }

  return (
    <Container fluid className="container-modal-md d-block p-5">
      <Row>
        <Col md={12}>
          <h1 className="text-roboto-fs30-lh30-fw400-blue700 mb-2">
            Averbação
          </h1>
        </Col>
      </Row>
      <HorizontalSeparator />
      <Row>
        <Col md={12} className="gy-3 d-flex flex-column">
          <div className="d-flex flex-column gap-4">
            <span className="text-roboto-fs15-lh16-fw400-blue700">
              Funcionário: {collaborator?.func_nome}
            </span>
            <span className="text-roboto-fs15-lh16-fw400-blue700">
              CPF: {collaborator?.func_cpf}
            </span>
            <span className="text-roboto-fs15-lh16-fw400-blue700">
              Saldo Devedor:{" "}
              <span className="text-roboto-fs16-lh16-fw400-red300">
                <strong>R$ {debitBalance}</strong>
              </span>
            </span>
          </div>
          <span className="text-justify text-roboto-fs16-lh18-fw700-blue700 mt-4">
            Informe o valor retido
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="d-flex flex-column  gy-4">
          <Input
            className="endorsement-input text-roboto-fs16-lh18-fw400-blue700"
            placeholder="0,00"
            maskType="currency"
            value={amount}
            onChange={setAmount}
          />
          <Text>
            * Favor, realizar a retenção de até 35% da verba rescisória do
            colaborador para cobrir o saldo apresentado.
          </Text>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="gy-5">
          <Button
            variant="green"
            className="w-100 text-montserrat-fs16-lh19-fw400-white"
            onClick={submitEndorsement}
          >
            Confimar
          </Button>
        </Col>
        <Col md={6} className="gy-5">
          <Button
            variant="red"
            className="w-100 text-montserrat-fs16-lh19-fw400-white"
            onClick={onClose}
          >
            Cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
