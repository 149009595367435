import { useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import Button from "components/Button";
import Modal from "components/Modal";

import { submitConciliationContestationAction } from "model/actions/action.conciliations-submit";
import { closeConciliationContestationPanelAction } from "model/actions/action.conciliations";

import "./style.css";

export default function ContestationPanel() {
  const dispatch = useAppDispatch();
  const { showConstestationPanel } = useAppSelector(
    (state) => state.conciliationsReducer
  );

  const [message, setMessage] = useState("");

  function write(event: any) {
    setMessage(event?.target.value);
  }

  function onClose() {
    dispatch(closeConciliationContestationPanelAction());
  }

  function submitContestation() {
    dispatch(submitConciliationContestationAction(message))
  }

  return (
    <Modal show={showConstestationPanel}>
      <div className="container-modal-md">
        <div className="conciliations-contestation-panel">
          <h1 className="text-roboto-fs16-lh18-fw700-blue600">
            Escreva o motivo da sua solicitação de alteração
          </h1>
          <textarea
            className="conciliations-contestation-panel-textarea mt-4 text-roboto-fs14-lh16-fw400-blue700"
            onChange={write}
          ></textarea>
          <div className="d-flex flex-row gap-3 mt-4">
            <Button
              variant="blue"
              className="w-100 text-montserrat-fs16-lh19-fw400"
              disabled={!message}
              onClick={submitContestation}
            >
              Enviar
            </Button>
            <Button
              variant="blue"
              isActive={false}
              className="w-100 text-montserrat-fs16-lh19-fw400"
              onClick={onClose}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
