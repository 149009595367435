import { AnyAction } from "redux";
import produce from "immer";
import {
  SEARCH_DASHBOARD,
  SET_DASHBOARD,
  SET_FAQ,
  SUBMIT_FAQ,
} from "model/action-types/type.dashboard";
import { PropsDashboardReducer } from "model/reducers-types/type.dashboard";

const INITIAL_STATE: PropsDashboardReducer = {
  indicators: {
    active_collaborators: 0,
    active_requests: 0,
  },
  pendencies: [
    {
      label: "Sem informação",
      link: "",
      quantidade: 0,
    }
  ],
  faq: [],
};

export function dashboardReducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case SEARCH_DASHBOARD:
      return state;
    case SET_DASHBOARD:
      return produce(state, (draft) => {
        draft.indicators = {
          active_collaborators: action.data.indicadores.colaboradores_ativos,
          active_requests: action.data.indicadores.solicitacoes_ativas,
        };

        draft.pendencies = action.data.pendencias;
      });
    case SET_FAQ:
      return produce(state, (draft) => {
        draft.faq = action.data.data;
      });
    case SUBMIT_FAQ:
      return state;
    default:
      return state;
  }
}
