export const SEARCH_CURRENT_CONCILIATIONS = "SEARCH_CURRENT_CONCILIATIONS";
export const SET_CONCILIATIONS = "SET_CONCILIATIONS";

export const SEARCH_RESUME_CONCILIATION = "SEARCH_RESUME_CONCILIATION";
export const SET_RESUME_CONCILIATION = "SET_RESUME_CONCILIATION";

export const SEARCH_CONCILIATION_PORTION = "SEARCH_CONCILIATION_PORTION";
export const SET_CONCILIATION_PORTION = "SET_CONCILIATION_PORTION";

export const SEARCH_CONCILIATION_HISTORY = "SEARCH_CONCILIATION_HISTORY";

export const SET_SEARCH_TYPE_CONCILIATION = "SET_SEARCH_TYPE_CONCILIATION";

export const OPEN_CONCILIATION_CONTESTATION_PANEL = "OPEN_CONCILIATION_CONTESTATION_PANEL";
export const CLOSE_CONCILIATION_CONTESTATION_PANEL = "CLOSE_CONTESTATCONCILIATION_ION_PANEL";