import Badge from "components/Badge";
import IContract from "interfaces/http-response/interface.contracts";
import DropdownTable from "layout/DropdownTable";

import ActionsIcon from "assets/img/actions-icon.svg";
import { useAppDispatch } from "hooks/hooks.redux";
import {
  downloadCbbContractAction,
  openDisplayContractAction,
} from "model/actions/action.contracts";
import { UtilitiesDate } from "utils/utilities.date";

type IProps = {
  data: IContract;
};

export default function TableRowActiveContracts({ data }: IProps) {
  const dispatch = useAppDispatch();
  const requestValue = new Intl.NumberFormat().format(data.vlr_solicitado);

  const DropdownItems = [
    {
      label: "Detalhes",
      action: () => dispatch(openDisplayContractAction(data)),
    },
    {
      label: "Baixar CCB",
      disabled: data.ppst_linkcontrato === null,
      action: () => dispatch(downloadCbbContractAction(data.ppst_linkcontrato)),
    },
  ];

  return (
    <tr>
      <td>{data.func_nome}</td>
      <td>{data.func_cpf}</td>
      <td>{data.emp_cnpj}</td>
      <td>{data.ppst_numeroccb}</td>
      <td>{data.tipo.label}</td>
      <td>R$ {requestValue}</td>
      <td>
        {UtilitiesDate.convertFormatDateTimeApiToBrazilianWithouTime(
          data.ppst_dthrsolicitacao
        )}
      </td>
      <td>
        <Badge variant={data.status.ppst_status === 5 ? "gray" : "green"}>
          {data.status.label}
        </Badge>
      </td>
      <td>
        <DropdownTable trigger={<img src={ActionsIcon} alt="" />} items={DropdownItems} />
      </td>
    </tr>
  );
}
