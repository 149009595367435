import styled, { css } from "styled-components";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";

export const DropsownMenuTrigger = styled(DropdownMenuPrimitive.Trigger)`
  background-color: transparent;
  min-width: 2rem;
  cursor: pointer;
`;

type DropdownMenuItem = {
  disabled?: boolean;
};

const disabledDropdownMenuItem = css`
  background-color: ${({ theme }) => theme.COLORS.GRAY_300};
  color: ${({ theme }) => theme.COLORS.GRAY_400};
  border: 0;
  cursor: auto;
`;

const hoverDropdownMenuItem = css`
  &:hover {
    color: var(--white);
    background-color: ${({ theme }) => theme.COLORS.BLUE_700};
    border: 1.5px solid ${({ theme }) => theme.COLORS.BLUE_700};
    cursor: pointer;
  }
`;

export const DropdownMenuItem = styled.button<DropdownMenuItem>`
  height: 4rem;
  border-radius: 3rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--blue-700);
  background-color: var(--white);
  border: 1.5px solid var(--blue-600);
  margin-bottom: 0.5rem;

  padding: 1.2rem 1.5rem;
  border-radius: 3rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;

  ${({ disabled }) => disabled && disabledDropdownMenuItem}
  ${({ disabled }) => !disabled && hoverDropdownMenuItem}
`;
