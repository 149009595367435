import IColorsVariants from "interfaces/interface.colors-variants";
import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";

import "./style.css";

interface IProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
  variant: IColorsVariants["colorsVariant"];
  isActive?: boolean;
  textSize?: "sm" | "md";
}

export default function Button({
  className,
  children,
  variant,
  isActive = true,
  textSize = "sm",
  disabled,
  ...rest
}: IProps) {
  const colors = {
    red: "--red-300",
    green: "--green-300",
    pink: "--pink-400",
    orange: "--orange-500",
    gray: "--gray-700",
    silver: "--gray-200",
    blue: "--blue-700",
    white: "--white"
  };

  const active = {
    color: "var(--white)",
    backgroundColor: `var(${colors[variant]})`,
  };

  const inactive = {
    color: `var(--blue-700)`,
    backgroundColor: "transparent",
    border: `0.1rem solid var(--blue-700)`,
  };

  const disable = {
    backgroundColor: "var(--gray-600)",
    color: "var(--gray-500)",
    border: 0
  };

  var defaultStyle = {
    borderRadius: "3rem",
    padding: "1rem 1.5rem",
    fontSize: "",
  };

  if (isActive) {
    defaultStyle = { ...defaultStyle, ...active };
  } else {
    defaultStyle = { ...defaultStyle, ...inactive };
  }

  if (disabled) {
    defaultStyle = { ...defaultStyle, ...disable };
  }

  return (
    <button
      className={`text-roboto-fs12-lh14-fw400-blue700 d-flex flex-row align-items-center justify-content-center ${className} `}
      style={defaultStyle}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}
