import ICollaboratorEndorsement from "interfaces/interface.collaborator-endorsement";
import ICollaborators from "interfaces/http-response/interface.collaborators";
import {
  CHANGE_DATA_FORMCOLLABORATOR,
  SET_FILTER_BY_COMPANY_COLLABORATORS_ACTION,
  DOWNLOAD_HISTORY_WORKSHEET_COLLABORATORS,
  GET_WORKSHEET_SHUTDOWN_MODEL,
  GET_WORKSHEET_UPDATE_MODEL,
  SEARCH_COLLABORATORS,
  SEARCH_COLLABORATORS_ENDORSEMENT,
  SEARCH_COLLABORATORS_UPLOAD_HISTORY,
  REFRESH_COLLABORATORS_DATA,
  OPEN_DETAILS_PANEL_COLLABORATOR,
  CLOSE_DETAILS_PANEL_COLLABORATOR,
  OPEN_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET,
  CLOSE_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET,
  OPEN_COLLABORATOR_DATA_PANEL,
  CLOSE_COLLABORATOR_DATA_PANEL,
  FILTER_COLLABORATORS_BY_COLLABORATOR,
  CHANGE_SELECTED_COLLABORATORS,
  SET_TABLE_DATA_COLLABORATORS,
  EXPORT_COLLABORATORS,
} from "model/action-types/type.collaborators";
import {
  CLOSE_ENDORSEMENT_PANEL,
  OPEN_ENDORSEMENT_PANEL,
} from "model/action-types/type.collaborators-endorsement";

export function searchCollaboratorsAction(page?: number) {
  return { type: SEARCH_COLLABORATORS, page };
}

export function searchCollaboratorsUploadoHistoryAction(
  page?: number,
  empId?: number
) {
  return { type: SEARCH_COLLABORATORS_UPLOAD_HISTORY, page, empId };
}

export function searchCollaboratorsEndorsementAction(
  page?: number,
  empId?: number
) {
  return { type: SEARCH_COLLABORATORS_ENDORSEMENT, page, empId };
}

export function getWorksheetShutdownModelAction() {
  return { type: GET_WORKSHEET_SHUTDOWN_MODEL };
}

export function getWorksheetUpdateModelAction() {
  return { type: GET_WORKSHEET_UPDATE_MODEL };
}

export function changeDataFormCollaboratorAction(value: any) {
  return { type: CHANGE_DATA_FORMCOLLABORATOR, value };
}

export function setFilterByCompanyCollaboratorsAction(empId: number | null) {
  return { type: SET_FILTER_BY_COMPANY_COLLABORATORS_ACTION, empId };
}

export function filterCollaboratorsByCollaboratorAction(value: string) {
  return { type: FILTER_COLLABORATORS_BY_COLLABORATOR, value };
}

export function downloadHistoryWorksheetCollaboratorsAction(id: number) {
  return { type: DOWNLOAD_HISTORY_WORKSHEET_COLLABORATORS, id };
}

export function refreshCollaboratorsDataAction() {
  return { type: REFRESH_COLLABORATORS_DATA };
}

export function openEndorsementPanelAction(collaboratorId: number) {
  return { type: OPEN_ENDORSEMENT_PANEL, collaboratorId };
}

export function closeEndorsementPanelAction() {
  return { type: CLOSE_ENDORSEMENT_PANEL };
}

export function openCollaboratorsDisplayPanelActionAction(
  collaborator: ICollaborators | ICollaboratorEndorsement
) {
  return { type: OPEN_DETAILS_PANEL_COLLABORATOR, collaborator };
}
export function closeCollaboratorsDisplayPanelAction() {
  return { type: CLOSE_DETAILS_PANEL_COLLABORATOR };
}

export function openCollaboratorModalShutdowWorksheet() {
  return { type: OPEN_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET };
}

export function closeCollaboratorModalShutdowWorksheet() {
  return { type: CLOSE_COLLABORATOR_MODAL_SHUTDOWN_WORKSHEET };
}

export function openCollaboratorDataPanelAction(data?: any) {
  return { type: OPEN_COLLABORATOR_DATA_PANEL, data };
}

export function closeCollaboratorDataPanelAction() {
  return { type: CLOSE_COLLABORATOR_DATA_PANEL };
}

export function changeSelectedCollaboratorsAction(collaboratorId: number[]) {
  return { type: CHANGE_SELECTED_COLLABORATORS, collaboratorId };
}

export function setTableDataCollaboratorsAction(data: any) {
  return { type: SET_TABLE_DATA_COLLABORATORS, data };
}

export function exportCollaboratorsAction() {
  return { type: EXPORT_COLLABORATORS };
}
