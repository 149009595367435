import styled from "styled-components";

export const LabelAmount = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  color: ${({ theme }) => theme.COLORS.BLUE_700};
`;

export const TextAmount = styled.strong`
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  color: ${({ theme }) => theme.COLORS.GREEN_300};
`;