import styled from "styled-components"
import {BaseTextProps} from "./interface.text"

export const BaseText = styled.p<BaseTextProps>`
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: ${props => props.color ? props.theme.COLORS[props.color] : props.theme.COLORS.GRAY_400}
`;

