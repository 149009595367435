import { ReactNode, useEffect, useState } from "react";
import { useAppSelector } from "hooks/hooks.redux";
import Badge from "components/Badge";
import Modal from "components/Modal";

import Presentation from "./Presentation";
import Dialog from "./Dialog";
import { ContainerRequestStatus } from "./styled";
import IRequests from "interfaces/http-response/interface.requests";

type IProps = {
  children: ReactNode;
  status: 0 | 1 | 6;
};

export default function Container({ children, status = 0 }: IProps) {
  const { requestStatus } = useAppSelector((state) => state.requestsReducer);

  const isVisible = requestStatus === null ? false : true;

  // const {propostastatus, status } = requestStatus as IRequests

  // const [proposalStatus, setStatus] = useState<0 | 1 | 6>(
  //   requestStatus?.status.ppst_status || 0
  // );
  // const [badgeMessage, setBadgeMessage] = useState("Pendente");
  // const [badgeVariant, setBadgeVariant] = useState<"red" | "green" | "orange">(
  //   "orange"
  // );

  // function defineStatus(value: any) {
  //   setStatus(value);
  // }

  // useEffect(() => {
  //   defineStatus(data?.ppst_status.id);
  // }, [show]);

  // useEffect(() => {
  //   setBadgeMessage(defineMessage());
  //   setBadgeVariant(defineVariant());
  // }, [proposalStatus]);

  function defineMessage() {
    if (status === 1) return "Aprovado";
    else if (status === 6) return "Recusado";
    else return "Pendente";
  }

  function defineVariant() {
    if (status === 6) return "red";
    else if (status === 1) return "green";
    else return "orange";
  }

  const badgeMessage = defineMessage();
  const badgeVariant = defineVariant();

  return (
    <Modal show={isVisible}>
      <ContainerRequestStatus status={status}>
        <h1 className="text-roboto-fs30-lh30-fw400-blue700 mb-2">
          Detalhe de Status
        </h1>
        <Badge variant={badgeVariant}>{badgeMessage}</Badge>
        {children}
      </ContainerRequestStatus>
    </Modal>
  );
}
