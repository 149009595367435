import { all, put, takeLatest } from "redux-saga/effects";
import Http from "services/httpClient";
import { SEARCH_ADMINISTRATORS } from "model/action-types/type.administrators";
import { setAdministratorsAction } from "model/actions/action.administrators";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";
import { finishLoadingAction, startLoadingAction } from "model/actions/action.general";

function* searchAdministrators(action: any): any {
  yield put(startLoadingAction())

  const http = new Http();
  const response = yield http.httpClientGet({
    url: `empresa/empresausuario?page=${action.page || 1}`,
  });

  yield put(finishLoadingAction())

  if (response.error)
    return yield put(openFeedbackModalAction(response.mensagem, "error"));

  yield put(setAdministratorsAction(response));
}

export default all([takeLatest(SEARCH_ADMINISTRATORS, searchAdministrators)]);
