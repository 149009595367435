import produce from "immer";
import {
  OPEN_DIALOG_MODAL,
  CLOSE_DIALOG_MODAL,
} from "model/action-types/type.dialog-modal";
import { AnyAction } from "redux";

const INITIAL_STATE = {
  message: "",
  show: false,
};

export function dialogModalReducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case OPEN_DIALOG_MODAL:
      return produce(state, (draft) => {
        draft.message = action.message;
        draft.show = true;
      });
    case CLOSE_DIALOG_MODAL:
      return produce(state, (draft) => {
        draft.message = "";
        draft.show = false;
      });
    default:
      return state;
  }
}
