import { ReactNode } from "react";
import StageProgress from "./StageProgress";

import CloseIcon from "assets/img/close-icon.svg";
import { useDispatch } from "react-redux";
import { closeCollaboratorDataPanelAction } from "model/actions/action.collaborators";
import { Col, Container, Row } from "react-bootstrap";

interface IProps {
  buttonContainer: ReactNode;
  formContainer: ReactNode;
  title: string;
  stage: number;
}

export default function FormContainer({
  buttonContainer,
  formContainer,
  title,
  stage,
}: IProps) {
  const dispatch = useDispatch();

  function onClose() {
    dispatch(closeCollaboratorDataPanelAction());
  }

  return (
    <Container fluid className="collaborator-panel">
      <Row className="collaborator-panel-header">
        <Col md={10} className="d-flex flex-column">
          <h1 className="text-roboto-fs30-lh30-fw400-blue700">
            Novo Colaborador
          </h1>
          <span className="text-roboto-fs12-lh14-fw400-blue700">
            Preencha abaixo os dados do novo colaborador que deseja incluir.
          </span>
        </Col>
        <Col md={2} className="d-flex flex-column align-items-end">
          <button className=" bg-transparent" onClick={onClose}>
            <img src={CloseIcon} alt="" />
          </button>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="d-flex align-items-start">
          <StageProgress currentStage={stage} stages={2} />
        </Col>
      </Row>

      <Row className="row">
        <Col md={12}>
          <div className="container-fluid collaborator-panel-form py-24 px-32">
            <Row>
              <Col md={12} >
                <h1 className="text-roboto-fs20-lh30-fw700-blue700">{title}</h1>
                <span className="text-roboto-fs12-lh14-fw400-blue700">
                  Informe os dados pessoais do colaborador.
                </span>
              </Col>
            </Row>
            <div className="d-flex flex-column collaborator-panel-input-container">
              {formContainer}
            </div>
          </div>
          <div className="d-flex flex-row gap-3 collaborator-panel-button-container">
            {buttonContainer}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
