import { Col, Container, Row } from "react-bootstrap";
import { useTheme } from "styled-components";
import {ExclamationCircle} from 'react-bootstrap-icons'
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import Modal from "components/Modal";
import FeedbackContainer from "components/FeedbackContainer";

import { closeFeedbackModal } from "model/actions/action.feedback-modal";

import SuccessIcon from "assets/img/icon-success.svg";
import ErrorIcon from "assets/img/icon-error.png";


import { ButtonFeedbackModal, ContianerIconFeedbackModal, ParagraphyFeedbackModal } from "./styled";
import "./style.css";

export function FeedbackModal() {
  const dispatch = useAppDispatch();
  const { message, status, show } = useAppSelector(
    (state) => state.feedbackModalReducer
  );

  const theme = useTheme();

  function onClose() {
    dispatch(closeFeedbackModal());
  }

  function IconState() {
    switch (status) {
      case "success":
        return <img src={SuccessIcon} />;
      case "warning":
        return <ExclamationCircle color={theme.COLORS.ORANGE_500} size={90} />;
      default:
        return <img src={ErrorIcon} />;
    }
  }

  return (
    <Modal show={show}>
      <FeedbackContainer state={status} className="container-modal-md">
        <Container fluid={true} >
          <Row>
            <Col md={12} className="d-flex flex-column align-items-center gap-4 gy-3">
              <ContianerIconFeedbackModal>
                <IconState />
              </ContianerIconFeedbackModal>
              <ParagraphyFeedbackModal>
                {message ||
                  "Um erro enesperado ocorreu, tente novamente mais tarde."}
              </ParagraphyFeedbackModal>
            </Col>
            <ButtonFeedbackModal
              variant="blue"
              className="w-100 text-montserrat-fs16-fw400"
              onClick={onClose}
            >
              Fechar
            </ButtonFeedbackModal>
          </Row>
        </Container>
      </FeedbackContainer>
    </Modal>
  );
}
