import IConciliationDueInstallments from "interfaces/interface.conciliation-due-installments";
import CurrencyUtilities from "utils/currencyFormatting";

export default function RowConciliationDueCollaborator({
  portion,
}: {
  portion: IConciliationDueInstallments;
}) {
  const amount = CurrencyUtilities.formatCurrencyToBrazilianStandard(portion.pparc_valor);

  return (
    <tr>
      <td className="table-text">{portion.ccb}</td>
      <td className="table-text">{portion.nome}</td>
      <td className="table-text">{portion.cpf}</td>
      <td className="table-text">{portion.parcela}</td>
      <td className="table-text">{portion.prazo}</td>
      <td className="table-text">R$ {amount}</td>
    </tr>
  );
}
