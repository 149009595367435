import TableUploadHistory from "./TableUploadHistory";
import TableCollaborator from "./TableCollaborator";
import TableEndorsement from "./TableEndorsement";

import ICollaborators from "interfaces/http-response/interface.collaborators";
import ICollaboratorEndorsement from "interfaces/interface.collaborator-endorsement";
import ICollaboratorUploadHistory from "interfaces/interface.collaborator-upload-history";

import "./style.css";

type IProps = {
  option: number;
  data:
    | ICollaborators[]
    | ICollaboratorEndorsement[]
    | ICollaboratorUploadHistory[];
};

export default function Table({ option, data }: IProps) {
  const Tables = [
    <TableCollaborator data={data as ICollaborators[]} />,
    <TableEndorsement data={data as ICollaboratorEndorsement[]} />,
    <TableUploadHistory data={data as ICollaboratorUploadHistory[]} />,
  ];
  return <>{Tables[option]}</>;
}
