import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import DefaultBody from "layout/DefaultBody";
import ReactSelect from "components/ReactSelect";
import TableContainer from "layout/TableContainer";
import Button from "components/Button";
import Input from "components/Input";
import TableContracts from "./components/table";

import {
  filterContractsByCollaboratorAction,
  filterContractsByCompanyAction,
  filterContractsByStatusAction,
  searchContractsAction,
} from "model/actions/action.contracts";
import { Companies } from "utils/company";
import { PropsContractsReducer } from "model/reducers-types/type.contracts";

export default function Contracts() {
  const dispatch = useAppDispatch();

  const { filterByStatus, currentPage, data, lastPage, total  } = useAppSelector((state) => state.contractsReducer);

  const { isLoading } = useAppSelector((state) => state.generalReducer);

  const { getLisCompanies } = new Companies();
  const options = getLisCompanies();

  const theme = useTheme();

  const buttonDisabled = isLoading;

  const [collboratorInputValue, setCollaboratorInputValue] = useState("");

  useEffect(() => {
    dispatch(searchContractsAction());
  }, []);

  function isThisTableOptionSelected(
    option: PropsContractsReducer["filterByStatus"]
  ) {
    return filterByStatus === option;
  }

  function paginate(page?: number) {
    dispatch(searchContractsAction(page));
  }

  return (
    <DefaultBody
      titlePage="Contratos"
      description="Abaixo listamos os contratos dos colaboradores da empresa."
    >
      <Row className="mt-5">
        <Col xl={3} className="gy-3 d-flex flex-row">
          <div className="container-input w-100">
            <Input
              value={collboratorInputValue}
              maskType={"name-cpf"}
              onChange={(value) => setCollaboratorInputValue(value)}
              className="collaborators-input"
              placeholder="Nome ou CPF"
            />
            <button
              onClick={() =>
                dispatch(
                  filterContractsByCollaboratorAction(collboratorInputValue)
                )
              }
            >
              <Search size={20} color={theme.COLORS.WHITE} />
            </button>
          </div>
        </Col>

        <Col xl={2} className="gy-3 d-flex flex-row">
          <ReactSelect
            width="100%"
            options={options}
            onChange={(company: any) =>
              dispatch(filterContractsByCompanyAction(company.value))
            }
          />
        </Col>
        <Col
          xl={7}
          className="gy-3 d-flex gap-3 collaborators-buttonfilter-container"
        >
          <Button
            variant="green"
            isActive={isThisTableOptionSelected("4")}
            className="text-roboto-fs12-lh14-fw400"
            onClick={() => dispatch(filterContractsByStatusAction("4"))}
            disabled={buttonDisabled}
          >
            Ativos
          </Button>
          <Button
            variant="gray"
            isActive={isThisTableOptionSelected("5")}
            className="text-roboto-fs12-lh14-fw400"
            onClick={() => dispatch(filterContractsByStatusAction("5"))}
            disabled={buttonDisabled}
          >
            Finalizados
          </Button>
          <Button
            variant="blue"
            isActive={isThisTableOptionSelected("4,5")}
            className="text-roboto-fs12-lh14-fw400"
            onClick={() => dispatch(filterContractsByStatusAction("4,5"))}
            disabled={buttonDisabled}
          >
            Todos
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TableContainer
            isLoading={isLoading}
            pagination={{
              currentPage,
              lastPage,
              paginate,
              total,
            }}
          >
            <TableContracts data={data} />
          </TableContainer>
        </Col>
      </Row>
    </DefaultBody>
  );
}
