import { Col, Container, Row } from "react-bootstrap";
import { useTheme } from "styled-components";
import { BsQuestionLg } from "react-icons/bs";

import Button from "components/Button";
import Modal from "components/Modal";


import "./style.css";

type IProps = {
  message: string,
  action: () => void,
  close: () => void;
  show: boolean;
}

export function DialogModal({ action, message, show, close }: IProps) {
  const theme = useTheme();
  return (
    <Modal show={show}>
      <div className="container-modal-md">
        <Container fluid={true}  className="px-5">
          <Row>
            <Col md={12} className="d-flex flex-column align-items-center gap-4 gy-3">
              <div className="dialog-modal-container-icon">
                <BsQuestionLg color={theme.COLORS.ORANGE_500} fontSize={100} />
              </div>
              <p className="dialog-modal-paragraphy">
                {message}
              </p>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6} 
            >
              <Button
                variant="blue"
                className="w-100 text-montserrat-fs16-fw400 dialog-modal-button"
                onClick={action}
              >
                Confirmar
              </Button>
            </Col>
            <Col md={6} 
            >
              <Button
                isActive={false}
                variant="blue"
                className="w-100 text-montserrat-fs16-fw400 dialog-modal-button"
                onClick={close}
              >
                Fechar
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </Modal>
  );
}
