import { useCallback, useEffect, useState } from "react";
import { useDropzone, DropzoneProps } from "react-dropzone";
import ListFiles from "./ListFiles";
import UploadPresentation from "./UploadPresentation";

import IDiv from "interfaces/html/interface.div";

import "./style.css";

interface IProps extends DropzoneProps {
  onChange: (files: File[]) => void;
  dragDropAreaProps?: IDiv;
  fileListingAreaProps?: IDiv;
}

export default function Upload(props: IProps) {
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    files && props.onChange(files);
  }, [files]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/xlsx": [".xlsx"],
    },
    onDrop,
    preventDropOnDocument: true,
    maxFiles: 1,
    noClick: files.length > 0,
  });

  function removeFile() {
    setFiles([]);
  }

  return (
    <div {...getRootProps({ className: `upload` })}>
      <input {...getInputProps()} />
      {files && files.length > 0 ? (
        <ListFiles files={files} onRemove={removeFile} {...props.fileListingAreaProps} />
      ) : (
        <UploadPresentation {...props.dragDropAreaProps} />
      )}
    </div>
  );
}
