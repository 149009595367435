import { Row } from "react-bootstrap";
import styled from "styled-components";

export const DisplayItemContainerItem = styled(Row)`
  width: 95%;
  padding-bottom: 0.8rem;
  border-bottom: 0.1rem solid var(--gray-200);

  &:last-child {
    border-bottom: 0;
  }
`;
