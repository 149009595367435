import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import Box from "../Box";
import Button from "components/Button";
import IconRightArrow from "assets/img/right-arrow.svg";
import { setAuthenticateResponseAction } from "model/actions/action.authenticate";

interface IProps {
  resetContentType: () => void;
}

export default function LoginFeedback({ resetContentType }: IProps) {
  const dispatch = useAppDispatch()
  const { requisitionResponse } = useAppSelector(
    (state) => state.authenticateReducer
  );

  function reset() {
    dispatch(setAuthenticateResponseAction(null))
    resetContentType()
  }

  return (
    <Box
      message={requisitionResponse?.message || "Um erro inesperado ocorreu."}
      error={requisitionResponse?.error}
    >
      <Button
        type="button"
        variant="blue"
        className={
          "d-flex gap-3 align-items-center text-montserrat-fs16-lh19-fw400-white"
        }
        onClick={reset}
      >
        Fechar
        <img
          src={IconRightArrow}
          alt="Flecha branca apontada para o lado direito"
        />
      </Button>
    </Box>
  );
}
