import { all, put, select, takeLatest } from "redux-saga/effects";
import fileDownload from "js-file-download";
import Http from "services/httpClient";

import ListById from "utils/utilities.list-by-id";
import UtilitiesMaskValidations from "utils/utilities.mask-validation";
import {
  SET_FILTER_BY_COMPANY_COLLABORATORS_ACTION,
  DOWNLOAD_HISTORY_WORKSHEET_COLLABORATORS,
  GET_WORKSHEET_SHUTDOWN_MODEL,
  GET_WORKSHEET_UPDATE_MODEL,
  REFRESH_COLLABORATORS_DATA,
  SEARCH_COLLABORATORS,
  SEARCH_COLLABORATORS_ENDORSEMENT,
  SEARCH_COLLABORATORS_UPLOAD_HISTORY,
  FILTER_COLLABORATORS_BY_COLLABORATOR,
  EXPORT_COLLABORATORS,
} from "model/action-types/type.collaborators";
import { openFeedbackModalAction } from "model/actions/action.feedback-modal";
import {
  finishLoadingAction,
  startLoadingAction,
} from "model/actions/action.general";
import { PropsCollaboratorsReducer } from "model/reducers-types/type.collaboratorsReducer";
import {
  changeSelectedCollaboratorsAction,
  setTableDataCollaboratorsAction,
} from "model/actions/action.collaborators";
import CollaboratorHelper from "pages/Collaborators/helper.collaborators";

function* searchCollaborators(action: any): any {
  yield put(startLoadingAction());

  const http = new Http();
  const store = yield select();

  const { filterByCollaborator, filterByCompany } =
    store.collaboratorsReducer as PropsCollaboratorsReducer;

  const queryStandard = `page=${action.page || 1}`;

  const queryByCompany = `${
    filterByCompany ? `&emp_id=${filterByCompany}` : ""
  }`;

  const queryByCollaborator = `${
    filterByCollaborator
      ? UtilitiesMaskValidations.cpfValidation(filterByCollaborator)
        ? `&func_cpf=${filterByCollaborator}`
        : `&func_nome=${filterByCollaborator}`
      : ""
  }`;

  let query = `${queryStandard}${queryByCompany}${queryByCollaborator}`;

  const response = yield http.httpClientGet({
    url: `empresa/funcionario?${query}`,
  });

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield yield put(finishLoadingAction());

  yield put(setTableDataCollaboratorsAction(response));
}

function* searchCollaboratorsUploadHistory(action: any): any {
  yield put(startLoadingAction());

  const http = new Http();
  const store = yield select();
  const empId = store.collaboratorsReducer.filterByCompany;

  const response = yield http.httpClientGet({
    url: `empresa/historicouploads?page=${action.page || 1}${
      empId ? "&emp_id=" + empId : ""
    }`,
  });
  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(finishLoadingAction());

  yield put(setTableDataCollaboratorsAction(response));
}

function* searchCollaboratorsEndorsement(action: any): any {
  yield put(startLoadingAction());

  const http = new Http();
  const store = yield select();

  const { filterByCollaborator, filterByCompany } =
    store.collaboratorsReducer as PropsCollaboratorsReducer;

  const queryStandard = `page=${action.page || 1}`;

  const queryByCompany = `${
    filterByCompany ? `&emp_id=${filterByCompany}` : ""
  }`;

  const queryByCollaborator = `${
    filterByCollaborator
      ? UtilitiesMaskValidations.cpfValidation(filterByCollaborator)
        ? `&func_cpf=${filterByCollaborator}`
        : `&func_nome=${filterByCollaborator}`
      : ""
  }`;

  let query = `${queryStandard}${queryByCompany}${queryByCollaborator}`;

  const response = yield http.httpClientGet({
    url: `empresa/funcionario/listaraverbacao?${query}`,
  });
  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  yield put(finishLoadingAction());

  yield put(setTableDataCollaboratorsAction(response));
}

function* refreshCollaboratorsData(action: any): any {
  const tableOption = CollaboratorHelper.getTableOption();

  switch (tableOption) {
    case 1:
      yield searchCollaboratorsEndorsement(action);
      break;
    case 2:
      yield searchCollaboratorsUploadHistory(action);
      break;
    default:
      yield searchCollaborators(action);
      break;
  }
}

function* downloadHistoryWorksheetCollaborator(action: any): any {
  const http = new Http();
  const response = yield http.httpClientGet({
    url: `empresa/historicouploads/download/${action.id}`,
    responseType: "arraybuffer",
  });
  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  const timestamp = new Date().getTime();

  fileDownload(
    response,
    `${timestamp}.xlsx`,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
}

function* getWorksheetShutdownModel(): any {
  const http = new Http();
  const response = yield http.httpClientGet({
    url: "empresa/modelo/desligamento/download",
    responseType: "arraybuffer",
  });
  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  fileDownload(
    response,
    "Modelo de Desligamento de Colaboradores.xlsx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
}

function* getWorksheetUpdateModel(): any {
  const http = new Http();
  const response = yield http.httpClientGet({
    url: "empresa/modelo/atualizacaofuncionario/download",
    responseType: "arraybuffer",
  });

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  fileDownload(
    response,
    "Modelo de Atualização de Colaboradores.xlsx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
}

function* filterCollaboratorsByCollaborator(action: any): any {
  const tableOption = CollaboratorHelper.getTableOption();

  if (tableOption === 0) yield searchCollaborators(action);
  else yield searchCollaboratorsEndorsement(action);
}

function* exportCollaborators(action: any): any {
  yield put(startLoadingAction());

  const store = yield select();

  const { selectedCollaborators } =
    store.collaboratorsReducer as PropsCollaboratorsReducer;

  const list = ListById.getLinearListOfIds(selectedCollaborators);

  const http = new Http();

  const response = yield http.httpClientGet({
    url: `empresa/funcionario/listaraverbacao?export=true&func_id=${list}`,
    responseType: "blob",
  });

  if (response.error)
    return yield put(openFeedbackModalAction(response.message, "error"));

  const timestamp = new Date().getTime();

  fileDownload(
    response,
    `Averbação - ${timestamp}.xlsx`,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );

  yield put(changeSelectedCollaboratorsAction([]));
  yield put(finishLoadingAction());
}

export default all([
  takeLatest(SEARCH_COLLABORATORS, searchCollaborators),
  takeLatest(
    SEARCH_COLLABORATORS_UPLOAD_HISTORY,
    searchCollaboratorsUploadHistory
  ),
  takeLatest(
    DOWNLOAD_HISTORY_WORKSHEET_COLLABORATORS,
    downloadHistoryWorksheetCollaborator
  ),
  takeLatest(SEARCH_COLLABORATORS_ENDORSEMENT, searchCollaboratorsEndorsement),
  takeLatest(GET_WORKSHEET_SHUTDOWN_MODEL, getWorksheetShutdownModel),
  takeLatest(GET_WORKSHEET_UPDATE_MODEL, getWorksheetUpdateModel),
  takeLatest(REFRESH_COLLABORATORS_DATA, refreshCollaboratorsData),
  takeLatest(
    SET_FILTER_BY_COMPANY_COLLABORATORS_ACTION,
    refreshCollaboratorsData
  ),
  takeLatest(
    FILTER_COLLABORATORS_BY_COLLABORATOR,
    filterCollaboratorsByCollaborator
  ),
  takeLatest(EXPORT_COLLABORATORS, exportCollaborators),
  // takeLatest(SET_TABLE_OPTION_COLLABORATOR, refreshCollaboratorsData),
]);
