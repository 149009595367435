import { Container } from "react-bootstrap";
import ContentBox from "./components/ContentBox";
import "./style.css";

export default function Login() {
  return (
    <Container fluid className="container-fluid vh-100 py-3">
      <div className="login-background-image h-100 d-flex align-items-center position-relative">
        <ContentBox />
      </div>
    </Container>
  );
}
