import produce from "immer";
import {
  CLOSE_DISPLAY_CONTRACT,
  DOWNLOAD_CCB_CONTRACT,
  FILTER_CONTRACTS_BY_COLLABORATOR,
  FILTER_CONTRACTS_BY_COMPANY,
  FILTER_CONTRACTS_BY_STATUS,
  OPEN_DISPLAY_CONTRACT,
  SEARCH_CONTRACTS,
  SET_TABLE_DATA_CONTRACTS,
} from "model/action-types/type.contracts";
import { CLEAR_STATE } from "model/action-types/type.general";
import { PropsContractsReducer } from "model/reducers-types/type.contracts";
import { AnyAction } from "redux";

const INITIAL_STATE: PropsContractsReducer = {
  data: [],
  currentPage: 0,
  lastPage: 0,
  total: 0,
  filterByCollaborator: "",
  filterByCompany: 0,
  filterByStatus: "4",
  displayContract: { show: false, data: null },
};

export function contractsReducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case SEARCH_CONTRACTS:
      return state;
    case SET_TABLE_DATA_CONTRACTS:
      return produce(state, (draft) => {
        draft.data = action.data.data;
        draft.lastPage = action.data.last_page;
        draft.currentPage = action.data.current_page;
        draft.total = action.data.total;
      });
    case FILTER_CONTRACTS_BY_COLLABORATOR:
      return produce(state, (draft) => {
        draft.filterByCollaborator = action.identification;
      });
    case FILTER_CONTRACTS_BY_COMPANY:
      return produce(state, (draft) => {
        draft.filterByCompany = action.empId;
      });
    case FILTER_CONTRACTS_BY_STATUS:
      return produce(state, (draft) => {
        draft.filterByStatus = action.status;
      });
    case OPEN_DISPLAY_CONTRACT:
      return produce(state, (draft) => {
        draft.displayContract = { show: true, data: action.data };
      });
    case CLOSE_DISPLAY_CONTRACT:
      return produce(state, (draft) => {
        draft.displayContract = { show: false, data: null };
      });
    case DOWNLOAD_CCB_CONTRACT:
      return state;
    case CLEAR_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
}
