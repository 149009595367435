import ISelectCompanyOptions from "interfaces/interface.select-company-options";
import {
  CHANGE_ADMINISTRATOR_STATUS,
  SUBMIT_ADMINISTRATOR,
} from "model/action-types/type.administrator-submit";

export function submitAdministratorAction(form: {
  name: string;
  email: string;
  password: string;
  status: number;
  // notify: number;
  functions: any[];
  companies?: Array<ISelectCompanyOptions>;
  adminId?: number;
}) {
  return {
    type: SUBMIT_ADMINISTRATOR,
    ...form,
  };
}

export function changeAdministratorStatusAction(
  adminId: number,
  status: 1 | 2
) {
  return {
    type: CHANGE_ADMINISTRATOR_STATUS,
    adminId,
    status,
  };
}
