import IColorsVariants from "interfaces/interface.colors-variants";
import { HTMLAttributes, ReactNode } from "react";
import "./style.css";

interface IProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
  variant: IColorsVariants["colorsVariant"]
}

export default function Badge({
  children,
  className,
  variant,
  ...rest
}: IProps) {
  const colors = {
    red: "--red-300",
    green: "--green-300",
    pink: "--pink-400",
    orange: "--orange-500",
    blue: "--blue-700",
    white: "--white",
    gray: "--gray-700",
    silver: "--gray-200",
  };

  const defaultStyle = {
    color: variant === "white" ? "var(--gray-200)" : "var(--white)",
    backgroundColor: `var(${colors[variant]})`,
  };

  return (
    <button className={`badge-table ${className}`} style={defaultStyle} {...rest}>
      {children}
    </button>
  );
}
