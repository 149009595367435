import { useAppDispatch } from "hooks/hooks.redux";
import {
  moveAllRequestsToSelectedListAction,
  removeAllRequestsFromTheSelectedListAction,
} from "model/actions/action.request";
import IRequests from "interfaces/http-response/interface.requests";
import TableRowRequests from "./TableRowRequests";
import { Table } from "layout/Table/styled";

type IProps = {
  data: IRequests[] | null;
  isAllChecked: boolean;
};

export default function TableRequests({ data, isAllChecked }: IProps) {
  const dispatch = useAppDispatch();

  function changeListOfSelectedRequest() {
    if (isAllChecked) dispatch(removeAllRequestsFromTheSelectedListAction());
    else dispatch(moveAllRequestsToSelectedListAction());
  }

  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>
            <div className="d-flex flex-row align-items-center gap-3">
              <input
                type="checkbox"
                onChange={changeListOfSelectedRequest}
                checked={isAllChecked}
              />
              <span className="table-text">Empresa</span>
            </div>
          </th>
          <th className="table-text">Nome do Colaborador(a)</th>
          <th className="table-text">CPF</th>
          <th className="table-text">Data da Solicitação</th>
          <th className="table-text">Tempo de Casa (mês)</th>
          <th className="table-text">Valor Solicitado / Parcelas</th>
          <th className="table-text">Valor da Parcela</th>
          <th className="table-text">Taxa a.m</th>
          <th className="table-text">Dias para Aprovação</th>
          <th className="table-text ">Status</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, key) => (
          <TableRowRequests key={key} item={item} />
        ))}
      </tbody>
    </Table>
  );
}
