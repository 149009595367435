import Modal from "components/Modal"
import { useAppSelector } from "hooks/hooks.redux"
import Spinner from "layout/Spinner"

export default () => {
    const { isOpen } = useAppSelector(state => state.modalLoadingReducer)
    return <Modal show={isOpen}>
        <div className="container-fluid container-modal-md">
            <Spinner />
        </div>
    </Modal>
}