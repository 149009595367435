import * as HoverCard from "@radix-ui/react-hover-card";
import Calendar from "react-calendar";
import { BsCalendar3 } from "react-icons/bs";

type IProps = {
  onChage: (period: Date) => void;
  value: Date;
};

export default ({ onChage, value }: IProps) => {
  const periodFormatted = value.toLocaleDateString("pt-BR").substring(3);

  const minimumPeriod = new Date();
  minimumPeriod.setDate(0);

  const maximumPeriord = new Date("2020-06-01")

  return (
    <HoverCard.Root>
      <HoverCard.Trigger>
        <span className="conciliations-period">
          <BsCalendar3 color="#bb527d" fontSize={20} />
          {periodFormatted}
        </span>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content>
          <Calendar
            value={value}
            onClickYear={onChage}
            onClickMonth={onChage}
            onClickDay={onChage}
            maxDate={minimumPeriod}
            minDate={maximumPeriord}
            view="year"
          />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};
