import IInputMask from "interfaces/interface.input-mask";
import Input from "components/Input";

type IProps = Omit<IInputMask, "onChange"> & {
  onChange?: (event: string) => void;
  label: string;
};

export default function InputLabel({
  value,
  onChange,
  className,
  label,
  ...rest
}: IProps) {
  return (
    <div className="d-flex flex-column justify-content-center collaborator-panel-inputlabel">
      {value && <span className="text-nunito-fs8-fw400-gray500">{label}</span>}
      <Input
        value={value}
        onChange={onChange}
        className="text-nunito-fs14-lh16-fw400-blue700"
        {...rest}
      />
    </div>
  );
}
