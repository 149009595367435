import React, { ReactNode } from 'react'
import { Container } from 'react-bootstrap'

import Spinner from 'layout/Spinner'
import TablePagination from 'components/TablePagination'

import IconNoResultsFound from 'assets/img/empty.svg'
import './style.css'

type IProps = {
    children: ReactNode,
    pagination: {
        currentPage: number,
        paginate: (page: number) => void,
        lastPage: number,
        total: number
    },
    isLoading: boolean
}

export default function TableContainer({ children, pagination, isLoading }: IProps) {
    if (isLoading) {
        return (
            <Container fluid className='container-loading-table w-100 d-flex align-items-center justify-content-center'>
                <Spinner />
            </Container>
        )
    }

    function getDataOfChildren() {
        let dataOfChildren: Array<any> = []
        React.Children.forEach(children, element => {
            if (!React.isValidElement(element)) return

            dataOfChildren = element.props.data
        })

        return dataOfChildren
    }

    const data = getDataOfChildren()

    if (!data.length) {
        return (
            <Container fluid className='container-loading-table w-100 d-flex flex-column align-items-center justify-content-center'>
                <img src={IconNoResultsFound} alt="" />
                <span className='label-no-results-found mt-5'>Nenhum registro encontrado</span>
            </Container>
        )
    }

    return (
        <div>
            <div className='table-container'>
                {children}
            </div>
            <div className='d-flex flex-row align-items-center justify-content-between flex-wrap'>
                <TablePagination
                    currentPage={pagination.currentPage}
                    paginate={pagination.paginate}
                    totalPages={pagination.lastPage}
                />
                <span className='text-roboto-fs16-lh18-fw400-blue600'>Exibindo <strong>{data.length}</strong> {data.length > 1 ? "registros" : "registro"} de um total de <strong>{pagination.total}</strong></span>
            </div>
        </div>
    )
}
