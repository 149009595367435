export default class CurrencyUtilities {
  static formatCurrencyToBrazilianStandard(value: number | string): string {
    if (/^\d{1,3}(?:\.\d{3})*,\d{2}$/g.test(`${value}`)) return `${value}`;

    var formattedValue = "";
    if (value || value === 0) {
      formattedValue =
        parseFloat(value as string)
          .toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") || "";
    }
    return formattedValue;
  }

  addDefaultCurrencyMultiMask(value: number[] | string[]) {
    var formattedValues: string[] = [];
    if (value.length !== 0) {
      value.forEach((value) => {
        const formattedValue =
          parseFloat(value as string)
            .toFixed(2)
            .replace(".", ",")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") || "";
        formattedValues.push(formattedValue);
      });
    }
    return formattedValues;
  }

  addRealtimeCurrencyMask(value: number | string) {
    if (value || value === 0) {
      if (typeof value === "number") {
        value = value.toString();
      }
      let valueWithSubstituitions = value.replaceAll(".", "");
      valueWithSubstituitions = valueWithSubstituitions.replace(/\D/g, "");
      valueWithSubstituitions = valueWithSubstituitions.replace(
        /(\d{1})(\d{11})$/,
        "$1.$2"
      );
      valueWithSubstituitions = valueWithSubstituitions.replace(
        /(\d{1})(\d{8})$/,
        "$1.$2"
      );
      valueWithSubstituitions = valueWithSubstituitions.replace(
        /(\d{1})(\d{5})$/,
        "$1.$2"
      );
      valueWithSubstituitions = valueWithSubstituitions.replace(
        /(\d{1})(\d{1,2})$/,
        "$1,$2"
      );
      value = valueWithSubstituitions;
      return value;
    }
  }

  static removeCurrencyFormatting(value: string) {
    let val = value
    val = val.replaceAll(".","")
    val = val.replaceAll(",",".")
    return parseFloat(val)
  }
}
