import { useState } from "react";
import { useAppDispatch } from "hooks/hooks.redux";

import { authenticateChangePasswordAction } from "model/actions/action.authenticate-submit";

import Box from "pages/Login/components/Box";
import Input from "components/Input";
import Label from "components/Label";
import Button from "components/Button";

import IconRightArrow from "assets/img/right-arrow.svg";
import { userPasswordUpdateSchema } from "schemas/schema.user-password-update";

interface IProps {
  resetContentType: () => void;
}

export default function ChangePassword({ resetContentType }: IProps) {
  const dispatch = useAppDispatch();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  
  const buttonDisabled = validateForm();

  function validateForm() {
    const isValid = userPasswordUpdateSchema.isValidSync({
      password,
      confirmPassword,
    });

    if (isValid && password === confirmPassword) return !true;
    else return !false;
  }

  function authenticateChangePassword() {
    dispatch(authenticateChangePasswordAction(password));
  }

  return (
    <Box message="Crie sua nova senha de acesso ao Portal RH">
      <Label className="login-form-label text-roboto-fs14-lh16-fw400-gray400">
        Senha
      </Label>
      <Input
        type="password"
        required
        value={password}
        onChange={(value) => setPassword(value)}
        className="login-form-input"
      />

      <Label className="login-form-label text-roboto-fs14-lh16-fw400-gray400">
        Confirmar a Senha
      </Label>
      <Input
        type="password"
        required
        value={confirmPassword}
        onChange={(value) => setConfirmPassword(value)}
        className="login-form-input"
      />

      <div className="d-flex justify-content-between">
        <Button
          variant="blue"
          className="text-montserrat-fs16-lh19-fw400-white"
          disabled={buttonDisabled}
          onClick={authenticateChangePassword}
        >
          Confirmar
          <img
            src={IconRightArrow}
            alt="Flecha branca apontada para o lado direito"
            className="ms-3"
          />
        </Button>
        <Button
          variant="blue"
          isActive={false}
          className="font-weight-bold border-0 text-roboto-fs16-lh18-fw700-blue700"
          onClick={resetContentType}
        >
          Cancelar
        </Button>
      </div>
    </Box>
  );
}
