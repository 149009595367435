import { useAppDispatch, useAppSelector } from "hooks/hooks.redux";

import DropdownTable from "layout/DropdownTable";
import NegativeBalance from "./NegativeBalance";
import Badge from "components/Badge";

import ListById from "utils/utilities.list-by-id";
import ICollaboratorEndorsement from "interfaces/interface.collaborator-endorsement";
import {
  changeSelectedCollaboratorsAction,
  openCollaboratorsDisplayPanelActionAction,
  openEndorsementPanelAction,
} from "model/actions/action.collaborators";

import ActionsIcon from "assets/img/actions-icon.svg";

type IProps = {
  collaborator: ICollaboratorEndorsement;
};

export default function RowEndorsement({ collaborator }: IProps) {
  const dispatch = useAppDispatch();

  const { currentPage, selectedCollaborators } = useAppSelector(
    (state) => state.collaboratorsReducer
  );

  const isCheckedRow = selectedCollaborators[currentPage]
    ? selectedCollaborators[currentPage].includes(collaborator.func_id)
    : false;

  function changeList(id: number) {
    const newList = ListById.changeList(id, currentPage, selectedCollaborators);

    dispatch(changeSelectedCollaboratorsAction(newList));
  }

  function openCollaboratorsDisplayPanelAction() {
    dispatch(openCollaboratorsDisplayPanelActionAction(collaborator));
  }

  function openEndorsementPanel() {
    dispatch(openEndorsementPanelAction(collaborator.func_id));
  }

  return (
    <tr>
      <td>
        <div className="d-flex flex-row align-items-center gap-3">
          <input
            type="checkbox"
            onChange={() => changeList(collaborator.func_id)}
            checked={isCheckedRow}
          />
          <span className="table-text">{collaborator.func_nome}</span>
        </div>
      </td>
      <td className="table-text">{collaborator.func_cpf}</td>
      <td className="table-text">{collaborator.emp_cnpj}</td>
      <td>
        <div className="d-flex flex-row justify-content-between align-items-center gap-5">
          <Badge
            variant="orange"
            className="text-roboto-fs12-lh14-fw400"
            onClick={openEndorsementPanel}
          >
            Informar averbação
          </Badge>

          <NegativeBalance debitBalance={collaborator.saldo_devedor} />

          <DropdownTable
            trigger={<img src={ActionsIcon} alt="" />}
            items={[
              {
                label: "Detalhes",
                action: openCollaboratorsDisplayPanelAction,
              },
            ]}
          />
        </div>
      </td>
    </tr>
  );
}
