import Skeleton from 'react-loading-skeleton'

const conciliationDataPaneltyle = {
  dataCard: {
    height: "12rem",
    width: "37rem",
    borderRadius: "3rem"
  },
  button: {
    height: "4.5rem",
    width: "12rem",
    borderRadius: "3rem"
  }
}

export default function ConciliationDataPanel() {
  return (
    <div className="row conciliations-conciliation-panel-container">
      <div className="col-12 d-flex flex-row align-items-center flex-wrap gap-3">
        <Skeleton style={conciliationDataPaneltyle.dataCard} />
        <Skeleton style={conciliationDataPaneltyle.dataCard} />
        <Skeleton style={conciliationDataPaneltyle.dataCard} />
        <Skeleton style={conciliationDataPaneltyle.dataCard} />
        <Skeleton style={conciliationDataPaneltyle.button} />
      </div>
    </div>
  );
}
