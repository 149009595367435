import { ReactNode } from "react";
import { Content, Overflow } from "./styled";

interface IProps {
  children: ReactNode;
  show: boolean;
}

export default function Modal({ children, show }: IProps) {
  return (
    <>
      {show &&
        <Overflow>
          <Content>{children}</Content>
        </Overflow>
      }
    </>
  );
}
