import { useAppDispatch } from "hooks/hooks.redux";

import StatusCollaborator from "./StatusCollaborators";
import DropdownTable from "layout/DropdownTable";
import StatusIndicator from "components/StatusIndicator";

import ICollaborators from "interfaces/http-response/interface.collaborators";
import { changeEmployeeStatusAction } from "model/actions/action.collaborators-submit";
import { openCollaboratorDataPanelAction, openCollaboratorsDisplayPanelActionAction } from "model/actions/action.collaborators";

import ActionsIcon from "assets/img/actions-icon.svg";

export default function RowCollaborator({
  collaborator,
}: {
  collaborator: ICollaborators;
}) {
  const dispatch = useAppDispatch();

  function openCollaboratorsDisplayPanelAction() {
    dispatch(openCollaboratorsDisplayPanelActionAction(collaborator));
  }

  function changeStatusToRegistered() {
    dispatch(changeEmployeeStatusAction(1, collaborator.func_id));
  }
  function changeStatusToFit() {
    dispatch(changeEmployeeStatusAction(2, collaborator.func_id));
  }
  function changeStatusToUnfit() {
    dispatch(changeEmployeeStatusAction(3, collaborator.func_id));
  }

  function openDataPanel() {
    dispatch(openCollaboratorDataPanelAction(collaborator));
  }

  return (
    <tr>
      <td className="table-text">{collaborator.func_nome}</td>
      <td className="table-text">{collaborator.func_cpf}</td>
      <td className="table-text">{collaborator.emp_cnpj}</td>
      <td>
        {collaborator.situacao.func_situacao === 4 ? (
          <StatusCollaborator situation={collaborator.situacao} />
        ) : (
          <DropdownTable
            trigger={<StatusCollaborator situation={collaborator.situacao} />}
            items={[
              {
                label: <>
                  <StatusIndicator variant="orange" />
                  Cadastrado
                </>,
                action: changeStatusToRegistered
              },
              {
                label: <>
                  <StatusIndicator variant="green" />
                  Apto
                </>,
                action: changeStatusToFit
              },
              {
                label: <>
                  <StatusIndicator variant="pink" />
                  Inapto
                </>,
                action: changeStatusToUnfit
              },
            ]} />
        )}
      </td>
      <td className="table-text">{collaborator.possui_emprestimo}</td>
      <td>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <DropdownTable
            trigger={<img src={ActionsIcon} alt="" />}
            items={[
              { label: "Detalhes", action: openCollaboratorsDisplayPanelAction },
              { label: "Editar", action: openDataPanel },
            ]} />
        </div>
      </td>
    </tr>
  );
}
