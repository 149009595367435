import {
  CONCILIATION_DOWNLOAD_TICKET,
  EXPORT_INSTALLMENTS_WORKSHEET_CONCILIATION,
  SUBMIT_CONCILIATION,
  SUBMIT_CONCILIATION_CONSTETATION,
} from "model/action-types/type.conciliations-submit";

export function submitConciliationAction() {
  return { type: SUBMIT_CONCILIATION };
}

export function exportInstallmentsWorksheetConciliationAction() {
  return { type: EXPORT_INSTALLMENTS_WORKSHEET_CONCILIATION };
}

export function submitConciliationContestationAction(message: string) {
  return { type: SUBMIT_CONCILIATION_CONSTETATION, message };
}

export function conciliationDownloadTicketAction() {
  return { type: CONCILIATION_DOWNLOAD_TICKET };
}
