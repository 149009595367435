import { useState } from "react";
import { useAppDispatch } from "hooks/hooks.redux";

import ListItem from "./ListItem";
import CollaboratorsSelect from "./CollaboratorsSelect";
import Button from "components/Button";

import ISelectOptions from "interfaces/interface.select-options";
import { submitShutdownListAction } from "model/actions/action.collaborators-submit";
import { openCollaboratorModalShutdowWorksheet } from "model/actions/action.collaborators";

import "./style.css";

export default function CreateShutdownList() {
  const dispatch = useAppDispatch();

  const [shutdownList, setShutdownList] = useState<ISelectOptions[]>([]);

  function addCollaboratorToShutdownList(element: ISelectOptions) {
    if (shutdownList.length === 10) return;

    if (shutdownList.find((item) => item.value === element.value)) return;

    setShutdownList([...shutdownList, element]);
  }

  function removeCollaboratorToShutdownList(value: number) {
    setShutdownList(shutdownList.filter((item) => item.value !== value));
  }

  function openShutdownModal() {
    dispatch(openCollaboratorModalShutdowWorksheet());
  }

  function submitShutdownList() {
    dispatch(submitShutdownListAction(shutdownList));
    setShutdownList([]);
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex flex-column gap-3 mt-4">
            {shutdownList.map((item, key) => (
              <ListItem
                key={key}
                collaborator={{ ...item, key }}
                removeItem={removeCollaboratorToShutdownList}
              />
            ))}
            {shutdownList.length !== 10 && (
              <div className="d-flex flex-row flex-wrap align-items-center gap-2">
                <div className="d-flex flex-grow-1 gap-2">
                  <div className="collaborator-shutdownlist-index text-nunito-fs12-lh37-fw700-white">
                    {shutdownList.length + 1}
                  </div>
                  <CollaboratorsSelect
                    onChange={addCollaboratorToShutdownList}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-3 mb-2">
        <div className="col-12 d-flex flex-row flex-wrap gap-3 justify-content-between">
          <Button
            variant="red"
            className="text-roboto-fs12-lh14-fw400-white w-100"
            onClick={submitShutdownList}
            disabled={shutdownList.length === 0}
          >
            Desligar
          </Button>
        </div>
      </div>
      <hr />
      <div className="row mt-1">
        <div className="col-md-12 d-flex gap-3">
          <Button
            variant="orange"
            className="text-roboto-fs12-lh14-fw400-white w-100"
            onClick={openShutdownModal}
          >
            Desligar em lote
          </Button>
        </div>
      </div>
    </div>
  );
}
