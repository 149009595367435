import IContract from "interfaces/http-response/interface.contracts";
import { Table } from "layout/Table/styled";
import TableRowActiveContracts from "./Rows/TableRowActiveContracts";

type IProps = {
  data: IContract[];
};

export default function TableActiveContracts({ data }: IProps) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Nome</th>
          <th>CPF</th>
          <th>CNPJ</th>
          <th>CCB</th>
          <th>Tipo de contrato</th>
          <th>Valor emprestado</th>
          <th>Data de solicitação</th>
          <th>Status</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, key) => (
          <TableRowActiveContracts key={key} data={item} />
        ))}
      </tbody>
    </Table>
  );
}
